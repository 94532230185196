/**
 * Base Services class
 */
export default class Services {
  constructor(apiDomain, endpointPrefix, accessToken) {
    this.apiDomain = apiDomain;
    this.endpointPrefix = endpointPrefix;
    this.accessToken = accessToken;
  }
}
