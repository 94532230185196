import React from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

import "../inputSelect/inputSelect.scss";
import { Checkbox } from "@material-ui/core";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const InputSelect = ({
  defaultValue,
  data,
  label,
  isMulti,
  isSearchable,
  onChange,
  isCheckInput,
  value,
  placeholder,
  ...otherProps
}) => {
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data && data.label}</span>
      <span style={groupBadgeStyles}>{data && data.options && data.options.length}</span>
    </div>
  );

  const Option = (props) => {
    const {
      data: { label, value, isSelected },
    } = props;
    return (
      <components.Option {...props}>
        <div>
          {isCheckInput && (
            <Checkbox label="" value={value} checked={isSelected} />
          )}
          <span className="input-option-label">{label}</span>
        </div>
      </components.Option>
    );
  };

  const getComponent = () => {
    if (isCheckInput) {
      return { Option };
    }
  };
  return (
    <>
      <Select
        className={
          isCheckInput ? "custom-select-container" : "input-select-container"
        }
        classNamePrefix={isCheckInput ? "custom-select" : "input-select"}
        defaultValue={defaultValue}
        value={value}
        options={data}
        formatGroupLabel={formatGroupLabel}
        label={label}
        isMulti={isMulti}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
        isSearchable={isSearchable}
        onChange={onChange}
        components={getComponent()}
        controlShouldRenderValue={!isCheckInput}
        placeholder={placeholder}
        {...otherProps}
      />
    </>
  );
};

InputSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
};

InputSelect.defaultProps = {
  placeholder: "",
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  option: [],
};

export default InputSelect;
