import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";

import InputSelect from "../../../../components/input/inputSelect/inputSelect";
import { AccessManagementService } from "../../../../services";
import "../../accessmanagemnt.scss";
import NavigateModel from "../../../../components/model/navigateModel/navigateModel";
import DialogModel from "../../../../components/model/dialogModel";
import InputSelectMulti from "../../../../components/input/inputSelect/inputSelectMulti";

const RolesMapping = ["Super Admin", "Admin User", "General User"];

const AllUserTab = ({
  setEmailValid,
  currentName,
  setCurrentName,
  currentID,
  setCurrentId,
  usersList,
  setUsersList,
  setCurrentRole,
  selectedTabIndex,
  formReset,
  totalUser,
  setTotalUser,
  page,
  setPage,
  existingCustomer,
  setExistingCustomer,
  AllRoles,
  getCustomerdata,
  userIDEdit,
  setUserIDEdit,
}) => {
  const [userFIlterByName, setuserFIlterByName] = useState("");
  const [userFIlterById, setuserFIlterById] = useState("");
  const [userFIlterByRole, setuserFIlterByRole] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElrdrop, setAnchorEldrop] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
  const [deleteButton, setDeleteButton] = useState(false);
  const [showMoreCustomer, SetShowMoreCustomer] = useState(false);
  const [allowAccessSuperAdmin, SetAllowAccessSuperAdmin] = useState(false);
  const [lastAdmin, setLastAdmin] = useState(false);
  const [customerListPopUp, SetCustomerListPopUp] = useState({
    customerList: "",
    userDetails: "",
  });
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const handleCustomer = (value) => {
    SetShowMoreCustomer(!showMoreCustomer);
    SetCustomerListPopUp(value);
  };
  const handleClosenwCustomer = () => {
    SetShowMoreCustomer(!showMoreCustomer);
  };
  const userdetails = useSelector((state) => state.userdetails);
  const itemsPerPage = 5;
  const noOfPages = Math.ceil(totalUser / itemsPerPage);

  useEffect(() => {
    applyFilter();
  }, []);

  const handleClose = () => {
    setAnchorEldrop(null);
  };
  const applyFilter = () => {
    const param = {
      email: userFIlterById !== undefined ? userFIlterById : "",
      fullName: userFIlterByName !== undefined ? userFIlterByName : "",
      role: userFIlterByRole,
      customers: selectedCustomer,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
    };
    setIsLoading(true);
    AccessManagementService.filterUser(param)
      .then((res) => {
        setUsersList(res.data.users);
        setTotalUser(res.data.totalUser);
        setIsLoading(false);
        setPage(1);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleClickdrop = (event, index) => {
    setAnchorEldrop(event && event.currentTarget);
    setCurrentSelectedIndex(index);
  };
  const EditModel = (currentIDinput = "", currentRoleId = 0) => {
    if (
      userdetails.account &&
      userdetails.account.username.toLowerCase() ===
        currentIDinput.toLowerCase()
    ) {
      setAllowEdit(true);
      SetAllowAccessSuperAdmin(false);
    } else if (
      userdetails.secondLevelAuth &&
      userdetails.secondLevelAuth.roleId === currentRoleId
    ) {
      SetAllowAccessSuperAdmin(true);
    } else {
      SetAllowAccessSuperAdmin(false);
      setAnchorEldrop(null);
      selectedTabIndex(1);
      setEmailValid(true);
      setAllowEdit(false);
    }
  };
  const DeleteModel = (currentIDvalue = "") => {
    if (
      userdetails.account &&
      userdetails.account.username.toLowerCase() ===
        currentIDvalue.toLowerCase()
    ) {
      setAllowDelete(true);
    } else {
      setDeleteButton(true);
      setAnchorEldrop(null);
    }
  };
  const DeleteUser = () => {
    setIsLoading(true);
    AccessManagementService.deleteUser(
      currentID,
      userdetails && userdetails.account && userdetails.account.username
    )
      .then((res) => {
        if (res.data === "This is last admin user for the customer") {
          setLastAdmin(true);
        } else {
          setLastAdmin(false);
          setuserFIlterByName("");
          setuserFIlterById("");
          setIsLoading(false);
          setDeleteButton(false);
          setDeleteStatus(true);
          setPage(page);
          setIsLoading(true);
        }
        const param = {
          PageNumber: 1,
          PageSize: 5,
          loggedInUser:
            userdetails && userdetails.account && userdetails.account.username,
        };
        AccessManagementService.getUsersList(
          param.PageNumber,
          param.PageSize,
          param.loggedInUser
        )
          .then((response) => {
            setIsLoading(false);
            setUsersList(response.data.users);
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const setInfoEdit = (e, item) => {
    setCurrentName(item.fullName);
    setCurrentId(item.email);
    setCurrentRole(item.roleId);
    setExistingCustomer(item.customers);
    setUserIDEdit(item.userId);
  };
  const setInfo = (e, item) => {
    setCurrentName(item.fullName);
    setCurrentId(item.email);
    setCurrentRole(item.roleId);
  };
  const handleChange = (e, value) => {
    const param = {
      email: userFIlterById !== undefined ? userFIlterById : "",
      fullName: userFIlterByName !== undefined ? userFIlterByName : "",
      role: userFIlterByRole,
      customers: selectedCustomer,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
    };
    setPage(value);
    setIsLoading(true);
    AccessManagementService.filterUser(param, Number(value), 5)
      .then((res) => {
        setUsersList(res.data.users);
        setTotalUser(res.data.totalUser);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleClosenw = () => {
    formReset();
    setDeleteButton(false);
  };
  const handleConfirm = () => {
    setDeleteStatus(false);
    setAllowEdit(false);
    setAllowDelete(false);
    formReset();
    setDeleteButton(false);
    SetAllowAccessSuperAdmin(false);
    setAnchorEldrop(null);
    setLastAdmin(false);
  };
  return (
    <>
      <div className="create-user-loader">
        {isLoading && <CircularProgress disableShrink />}
      </div>
      <div className="alluser_details">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            User Id
            <Input
              onChange={(r) => {
                setuserFIlterById(r && r.currentTarget.value);
              }}
              placeholder="Enter here…"
            />
          </Grid>
          <Grid item xs={3}>
            Name
            <Input
              onChange={(e) => {
                setuserFIlterByName(e && e.target.value);
              }}
              value={userFIlterByName}
              placeholder="Enter Here…"
            />
          </Grid>
          <Grid item xs={2}>
            Role
            <InputSelect
              data={AllRoles}
              placeholder="Select..."
              isSearchable
              onChange={(r) => {
                setuserFIlterByRole(r && r.value);
              }}
            />
          </Grid>
          <Grid className="customerAlign" item xs={2}>
            Customer
            <InputSelectMulti
              data={getCustomerdata}
              onMenuOpen={true}
              isMulti
              data-test-id="Customers"
              isCheckInput={true}
              isSearchable
              value={existingCustomer}
              isLoading={getCustomerdata ? getCustomerdata : []}
              placeholder={
                selectedCustomer && selectedCustomer.length
                  ? `${selectedCustomer.length} Selected`
                  : existingCustomer && existingCustomer.length
                  ? `${existingCustomer.length} Selected`
                  : "Select..."
              }
              onChange={(metrics) => {
                const metricsGraph =
                  metrics &&
                  metrics.map((ele) => ({
                    value: ele.value,
                    label: ele.label,
                  }));
                setExistingCustomer(metricsGraph);
                const customervalue =
                  metrics &&
                  metrics.map((ele) => {
                    return ele.value;
                  });
                setSelectedCustomer(customervalue);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <button onClick={applyFilter} className="user_btn">
              Apply
            </button>
          </Grid>
        </Grid>
      </div>
      <div className="user_details">
        <div className="user_details_heading">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              User Id
            </Grid>
            <Grid item xs={2}>
              User Name
            </Grid>
            <Grid item xs={2}>
              Role
            </Grid>
            <Grid item xs={2}>
              Customer
            </Grid>
            <Grid item xs={3}>
              Last Accessed
            </Grid>
          </Grid>
        </div>
        <div className="user_details_cnt">
          {usersList && usersList.length > 0 ? (
            usersList.map((userList, index) => (
              <Grid key={index} container spacing={3}>
                <Grid item xs={3}>
                  {userList.email}
                </Grid>
                <Grid item xs={2}>
                  {userList.fullName}
                </Grid>
                <Grid item xs={2}>
                  {RolesMapping[userList.roleId - 1]}
                </Grid>
                <Grid item xs={2}>
                  {userList &&
                  userList.customers &&
                  userList.customers.length >= 1
                    ? userList.customers &&
                      userList.customers[0] &&
                      userList.customers[0].label
                    : "NA"}
                  {userList &&
                  userList.customers &&
                  userList.customers.length > 1 ? (
                    <span
                      className="custLink"
                      onClick={() =>
                        handleCustomer({
                          customerList: userList.customers,
                          userDetails: userList,
                        })
                      }
                    >
                      {" "}
                      {`+${userList.customers.length - 1}  more`}
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  {userList.lastLoggedIn
                    ? new Date(userList.lastLoggedIn).toLocaleString("en-EN", {
                        timeZone: "Australia/Sydney",
                      })
                    : "NA"}
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClickdrop(e, index)}
                    className="user-btn"
                    disabled={
                      (userdetails.secondLevelAuth &&
                        userdetails.secondLevelAuth.roleId === 1 &&
                        userList.roleId === 3) ||
                      (userdetails.secondLevelAuth &&
                        userdetails.secondLevelAuth.roleId === 2 &&
                        userList.roleId === 2)
                    }
                    className={
                      (userdetails.secondLevelAuth &&
                        userdetails.secondLevelAuth.roleId === 1 &&
                        userList.roleId === 3) ||
                      (userdetails.secondLevelAuth &&
                        userdetails.secondLevelAuth.roleId === 2 &&
                        userList.roleId === 2)
                        ? "disableThreeDots user-btn"
                        : "user-btn"
                    }
                  >
                    ...
                  </Button>
                </Grid>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElrdrop}
                  keepMounted
                  open={Boolean(anchorElrdrop)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={(e) => {
                      setInfoEdit(e, usersList[currentSelectedIndex]);
                      EditModel(
                        usersList[currentSelectedIndex].email,
                        usersList[currentSelectedIndex].roleId
                      );
                    }}
                  >
                    Edit User
                  </MenuItem>
                  <div className="menu-delete-user">
                    <MenuItem
                      onClick={(e) => {
                        setInfo(e, usersList[currentSelectedIndex]);
                        DeleteModel(usersList[currentSelectedIndex].email);
                      }}
                    >
                      Delete User
                    </MenuItem>
                  </div>
                </Menu>
              </Grid>
            ))
          ) : (
            <div className="create-user-loader">
              {isLoading && <CircularProgress disableShrink />}
            </div>
          )}
          {usersList && usersList.length ? (
            <Pagination
              count={noOfPages}
              page={page}
              onChange={handleChange}
              defaultPage={1}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />
          ) : (
            !isLoading &&
            usersList &&
            usersList.length === 0 && (
              <div className="no-users">No Users Found</div>
            )
          )}
        </div>
        {deleteStatus ? (
          <NavigateModel
            state={deleteStatus}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="User Deleted Successfully."
          />
        ) : null}
        {allowEdit ? (
          <NavigateModel
            state={allowEdit}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="You cannot edit your own account."
          />
        ) : null}
        {allowDelete ? (
          <NavigateModel
            state={allowDelete}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="You cannot delete your own account."
          />
        ) : null}
        {lastAdmin ? (
          <NavigateModel
            state={lastAdmin}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="This is the last admin user for this customer. Please add another admin user before deleting."
          />
        ) : null}
        {allowAccessSuperAdmin ? (
          <NavigateModel
            state={allowAccessSuperAdmin}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="You cannot Edit Super Admin User."
          />
        ) : null}
        {deleteButton ? (
          <DialogModel
            dialogOpen={deleteButton}
            buttonText="Delete"
            handleClose={handleClosenw}
            heading="Delete User"
            handleSubmit={() => {
              DeleteUser();
            }}
          >
            <form>
              <p>
                {" "}
                Are you sure you want to delete the selected User:{" "}
                <b>{currentName}</b>
              </p>
            </form>
          </DialogModel>
        ) : null}
        {showMoreCustomer ? (
          <DialogModel
            dialogOpen={showMoreCustomer}
            buttonText="OK"
            handleClose={handleClosenwCustomer}
            heading="User Details"
            handleSubmit={handleClosenwCustomer}
            showCancel={false}
          >
            <div className="userDetailsWrapper">
              <div className="userDetailsTable">
                <div>User Name</div>
                <div>{customerListPopUp.userDetails.fullName}</div>
              </div>
              <div className="userDetailsTable">
                <div>Role</div>
                <div>
                  {RolesMapping[customerListPopUp.userDetails.roleId - 1]}
                </div>
              </div>
            </div>
            <div className="allCustomerWrapper">
              <div>Customers</div>
              <div>
                {customerListPopUp &&
                  customerListPopUp.userDetails &&
                  customerListPopUp.userDetails.customers
                    .map((customers) => {
                      return customers.label;
                    })
                    .join(",")}
              </div>
            </div>
          </DialogModel>
        ) : null}
      </div>
    </>
  );
};
export default AllUserTab;
