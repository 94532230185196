import React from "react";
import propTypes from "prop-types";
import { Report } from "powerbi-report-component";
import "./powerbi.scss";

class PowerBI extends React.Component {
  constructor(props) {
    super(props);
    this.report = null; // to store the loaded report's object to perform operations like print, full screen etc..
    this.state = {
      filters: [],
      reportId: props.reportId,
      datasetId: props.datasetId,
      embedId: props.embedId,
      groupId: props.groupId,
      reportBaseURL: props.reportBaseURL,
      pageName: props.pageName,
    };
  }
  handleDataSelected = (data) => {
    // will be called when some chart or data element in your report clicked
  };

  handleReportLoad = (report) => {
    // will be called when report loads:
    // - scripts and data received from server, visuals are rendered on the browser
    // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    this.report = report; // get the report object from callback and store it.(optional)
    this.setFilters(this.props.selectedFilters);
  };

  handleReportRender = (report) => {
    // will be called when report renders:
    // - visuals finish rendering
    // - report is fully visible and ready for consumption

    this.report = report; // get the report object from callback and store it.(optional)
  };

  handlePageChange = (data) => {
    // will be called when pages in your report changes
  };

  handleTileClicked = (data) => {
    // will be called
  };

  createEmbedURL() {
    const { reportId, groupId, reportBaseURL } = this.state;

    return `${reportBaseURL}?reportId=${reportId}&groupID=${groupId}`;
  }

  setFilters(filter) {
    this.report && this.report.setFilters(filter).catch(function (errors) {
      // for catching errors
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedFilters !== nextProps.selectedFilters) {
      this.setFilters(nextProps.selectedFilters);
    }
    if (this.props.reportId !== nextProps.reportId) {
      this.setState({
        reportId: nextProps.reportId,
        groupId: nextProps.groupId,
        embedId: nextProps.embedId,
        datasetId: nextProps.datasetId,
      });
    }
    if (this.props.pageName !== nextProps.pageName) {
      this.setState({ pageName: nextProps.pageName });
    }
  }

  render() {
    const reportStyle = {
      // style object for report component
    };
    const extraSettings = {
      filterPaneEnabled: false, //true
      navContentPaneEnabled: false, //true
      hideErrors: false, // Use this *only* when you want to override error experience i.e, use onError
      // ... more custom settings
    };
    let embedUrl = this.createEmbedURL();

    // this.setFilters(this.props.selectedFilters)

    const { tokenType, accessToken, reportMode } = this.props;
    const { embedId, datasetId, groupId, pageName } = this.state;

    return (
      <div className="report">
        <Report
          embedType="report"
          tokenType={tokenType}
          datasetId={datasetId}
          accessToken={accessToken}
          embedUrl={embedUrl}
          embedId={embedId}
          reportMode={reportMode}
          groupId={groupId}
          extraSettings={extraSettings}
          permissions="All" // View, For "Edit" mode permissions should be "All"
          style={reportStyle}
          onLoad={this.handleReportLoad}
          onRender={this.handleReportRender} // not allowed in "Create" mode
          onSelectData={this.handleDataSelected}
          onPageChange={this.handlePageChange}
          onTileClicked={this.handleTileClicked}
          onSave={this.handleReportSave} // works for "Edit" and "Create"
          pageName={pageName}
        />
      </div>
    );
  }
}

PowerBI.propTypes = {
  datasetId: propTypes.string,
  groupId: propTypes.string.isRequired,
  embedId: propTypes.string.isRequired,
  accessToken: propTypes.string.isRequired,
  reportId: propTypes.string.isRequired,
  selectedFilters: propTypes.array,
  pageName: propTypes.string,
};
PowerBI.defaultProps = {
  tokenType: "Embed",
  reportMode: "View",
  reportBaseURL: "https://app.powerbi.com/reportEmbed",
};

export default PowerBI;
