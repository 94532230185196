import Services from "./services";
import Axios from "./axios";

/**
 * DashboardService
 */
export default class DashboardService extends Services {
  axiosInstance = new Axios(`${this.apiDomain}${this.endpointPrefix}/`);

  /**
   * Get user data
   *
   * @returns {*}
   */

  getUser = () => {
    return this.axiosInstance.get({
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  getPowerBiToken = (datasetId, reportId) => {
    return this.axiosInstance.get(
      `/GetPowerBIAuthentication/GetPowerBIAuthentication?datasetId=${datasetId}&reportId=${reportId}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  addNewGraph = (graphData) => {
    return this.axiosInstance.post("/GraphReports/PostGraphReport", graphData, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };
  getSavedGraph = (email) => {
    return this.axiosInstance.get(
      `/GraphReports/GetGraphReport?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  getSavedPreference = (id) => {
    return this.axiosInstance.post(
      `/GraphReports/SavedUserPreference?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  renameGraph = (reportId) => {
    return this.axiosInstance.put("/GraphReports/PutGraphReport", reportId, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  deleteGraph = (id) => {
    return this.axiosInstance.delete(`/GraphReports/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  getRightSideFilterData = (graphData = {}) => {
    return this.axiosInstance.post(
      "GetMachineFilterDashBoard/GetMachineFilterDashboard",
      graphData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  postRightSideFilterData = (graphData) => {
    return this.axiosInstance.post(
      "GetMachineFilterDashBoard/GetMachineFilterDashboard",
      graphData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  postRightSideFilterKparData = (graphData) => {
    return this.axiosInstance.post(
      "GetMachineFilterDashBoard/GetMachineFilterDashboardForKPARCustomers",
      graphData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  getLeftSideMenu = () => {
    return this.axiosInstance.get("GetMachModuleMetric/GetMachineMetric", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  getfilterComment = (reportData) => {
    return this.axiosInstance.post(
      "/GetReportFilter/CreateFilterComment",
      reportData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  getfilterCommentPut = (reportData) => {
    return this.axiosInstance.put(
      "/GetReportFilter/UpdateFilterComment",
      reportData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  getfilterCommentDelete = (reportData) => {
    return this.axiosInstance.delete("/GetReportFilter/DeleteFilterComment", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
      data: {
        ...reportData,
      },
    });
  };

  getLeftMachineMetricForKPARCustomer = () => {
    return this.axiosInstance.get(
      "GetMachModuleMetric/GetLeftMachineMetricForKPARCustomer",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
}
