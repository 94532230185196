import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import "./watchlist.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WatchlistCard from "../watchlistCard/watchlistCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const Watchlist = ({ watchlistData }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid>
        {watchlistData.length >= 3 ? (
          <Slider {...settings}>
            {watchlistData.map((value, index) => {
              return (
                <Grid item xs key={index}>
                  <Paper className={classes.paper}>
                    <WatchlistCard value={value} />
                  </Paper>
                </Grid>
              );
            })}
          </Slider>
        ) : (
          watchlistData.map((value) => {
            return (
              <Grid item xs>
                <Paper className={classes.paper}>
                  <WatchlistCard value={value} />
                </Paper>
              </Grid>
            );
          })
        )}
      </Grid>
    </div>
  );
};

export default Watchlist;
