import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

import PowerBi from "./../../components/PowerBI";
import LeftNavbar from "../../components/LeftNavbar/";
import Config from "./../../config";
import Watchlist from "../../components/watchlist";
import SideBar from "../../components/sidebar";
import "./Focusedarea.scss";
import Utils from "./../../utils";
import { DashboardService, WatchListService } from "../../services";
import RenameModel from "../../components/model/renameModel";
import DeleteModel from "../../components/model/deleteModel";
import emptygraphicon from "./../../images/icon_emptygraph.svg";
import { availableReportViews, defaultReportView } from "./../../config/config";
import InputSelect from "../../components/input/inputSelect/inputSelect";
import Navigate from "./Navigate";

let LeftNavBarFilter = [
  {
    menuName: "machineMetrics",
    icon: "iconmetrics",
    headingName: "Machine Metrics",
  },
  {
    menuName: "reliability",
    icon: "iconShield",
    headingName: "Reliability",
  },
];

const FocusedArea = ({
  rightSideFilterData,
  leftSideMenuData,
  dropDownGraphs,
  setdropDownGraphs,
  savedGraphData,
  switchToCustom,
  selectedProductivity,
  seletedReliability,
  parentCustomers,
  getLeftMachineMetricForKPARCustomer,
  rightSideFilterKparData,
}) => {
  const [defaultGraphValue, setdefaultGraphValue] = useState(
    savedGraphData && savedGraphData.name
  );
  const [allFilters, setAllFilters] = useState(rightSideFilterData);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [accessToken, setAccessToken] = useState("");

  const [clicknw, setClicknw] = useState(null);
  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(savedGraphData && savedGraphData.filters)
  );
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [rename, updateRename] = useState(false);
  const [deleteModal, updateDeleteModal] = useState(false);
  const [alignment, setAlignment] = useState("left");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentGraphID, setCurrentgraphID] = useState(savedGraphData.id);
  const [reportView, setReportView] = useState(defaultReportView);
  const [isLoading, setIsLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState(
    savedGraphData.reportType
  );
  const [dropdownSelect, setDropDownSelect] = useState({
    label: savedGraphData.name,
    value: savedGraphData.id,
  });
  const [watchListData, SetWatchListData] = useState([]);
  const [dashboardLoader, setDashboardLoader] = useState(false);
  const userdetails = useSelector((state) => state.userdetails);
  const [productivityFilters, SetProductivityFilters] = useState(
    selectedProductivity
  );
  const [reliabilityFilters, SetReliabilityFilters] = useState(
    seletedReliability
  );
  const [machineMetricEnable, SetMachineMetricEnable] = useState(false);
  const [reliabilityEnable, SetReliabilityEnable] = useState(false);
  const [productivityEnable, SetProductivityEnable] = useState(false);
  const [resetFlag] = useState(false);

  const [leftSideFilterMenu, seleftSideFilterMenu] = useState(LeftNavBarFilter);
  const [kparButtonEnable, SetkparButtonEnable] = useState(false);

  const handleClickednw = (event) => {
    setClicknw(event && event.currentTarget);
  };
  const handleClosed = () => {
    setClicknw(null);
  };

  const inspectorRole =
    userdetails.usercustomerData &&
    userdetails.usercustomerData.customers &&
    userdetails.usercustomerData.customers.length > 0
      ? false
      : true;

  useEffect(() => {
    if (inspectorRole) {
      setAllFilters(rightSideFilterKparData);
      SetkparButtonEnable(true);
    } else {
      setAllFilters(rightSideFilterData);
    }
  }, [rightSideFilterData, rightSideFilterKparData]);

  const EmailID = userdetails.idTokenClaims.preferred_username;
  useEffect(() => {
    if (inspectorRole) {
      setAllFilters(rightSideFilterKparData);
      SetkparButtonEnable(true);
      seleftSideFilterMenu([
        {
          menuName: "reliability",
          icon: "iconShield",
          headingName: "Reliability",
        },
        {
          menuName: "productivity",
          icon: "iconProductivity",
          headingName: "Productivity",
        },
      ]);
    } else {
      setAllFilters(rightSideFilterData);
    }
    DashboardService.getSavedGraph(EmailID)
      .then((res) => {
        const firstSavedPreFerence =
          res.data &&
          res.data.filter((preference) => preference.lastSavedPreference);
        setdropDownGraphs(res.data);
        setDropdownLoading(false);
        handleSelectedGraphOnpageLoad(firstSavedPreFerence);
        if (typeof savedGraphData.id !== "string") {
          const { reportType, name, filters } = savedGraphData;
          fetchNSetToken(reportType);
          setdefaultGraphValue(name);
          setSelectedFilters(JSON.parse(filters));
          setSelectedMenu(reportType);
        }
      })
      .catch((err) => {
        SetMachineMetricEnable(true);
        SetReliabilityEnable(true);
        SetProductivityEnable(true);
      });

    setDashboardLoader(true);
    WatchListService.getWatchList({
      email: EmailID,
    })
      .then((res) => {
        SetWatchListData(res.data);
        setDashboardLoader(false);
      })
      .catch((err) => {
        setDashboardLoader(false);
      });
    SetProductivityFilters({});
    SetReliabilityFilters({});
  }, []);

  const handleEnableOrDisableLeftSide = (reportType) => {
    if (reportType === "machineMetrics") {
      SetMachineMetricEnable(true);
      SetReliabilityEnable(false);
      SetProductivityEnable(false);
      seleftSideFilterMenu([
        {
          menuName: "machineMetrics",
          icon: "iconmetrics",
          headingName: "Machine Metrics",
        },
      ]);
    }

    if (reportType === "Productivity Metrics") {
      SetMachineMetricEnable(false);
      SetReliabilityEnable(false);
      SetProductivityEnable(true);
      seleftSideFilterMenu([
        {
          menuName: "productivity",
          icon: "iconProductivity",
          headingName: "Productivity",
        },
      ]);
    }

    if (
      reportType === "Downtime Analysis" ||
      reportType === "Faults" ||
      reportType === "Alarms"
    ) {
      SetMachineMetricEnable(false);
      SetReliabilityEnable(true);
      SetProductivityEnable(false);
      seleftSideFilterMenu([
        {
          menuName: "reliability",
          icon: "iconShield",
          headingName: "Reliability",
        },
      ]);
    }
  };

  const handleSelectedGraphOnpageLoad = async (savedGraphUser) => {
    if (savedGraphUser.length) {
      const {
        id,
        name,
        filters,
        reportType,
        isKPARCustomer,
      } = savedGraphUser[0];
      const FiltersOnLoad = JSON.parse(filters);
      handleEnableOrDisableLeftSide(reportType);
      SetkparButtonEnable(isKPARCustomer);
      setFilterLoading(true);

      SetProductivityFilters(
        FiltersOnLoad &&
          FiltersOnLoad[reportType] &&
          FiltersOnLoad[reportType][0]
      );
      SetReliabilityFilters(
        FiltersOnLoad &&
          FiltersOnLoad[reportType] &&
          FiltersOnLoad[reportType][0]
      );

      let savedGraphInFocused = JSON.parse(filters);

      const newApiFilters = {};
      for (const iterator in savedGraphInFocused) {
        newApiFilters[iterator] =
          savedGraphInFocused &&
          savedGraphInFocused[iterator] &&
          savedGraphInFocused[iterator].map((ele) => ele.value);
      }

      if (isKPARCustomer) {
        DashboardService.postRightSideFilterKparData({
          loggedInUser: EmailID,
          ...newApiFilters,
        }).then((filteredData) => {
          setFilterLoading(false);
          setAllFilters(filteredData.data);
        });
      } else {
        DashboardService.postRightSideFilterData({
          loggedInUser: EmailID,
          ...newApiFilters,
        }).then((filteredData) => {
          setFilterLoading(false);
          setAllFilters(filteredData.data);
        });
      }

      setAccessToken("");
      fetchNSetToken(reportType);
      setCurrentgraphID(id);
      setdefaultGraphValue(name);
      setSelectedReportType(reportType);
      setSelectedMenu(reportType);
      setDropDownSelect({ label: name, value: id });
      setSelectedFilters(JSON.parse(filters));
      setUpdateDisabled(true);
      return true;
    }
    SetMachineMetricEnable(true);
    SetReliabilityEnable(true);
    SetProductivityEnable(true);
    return false;
  };

  const rightSideFilterSelected = Object.keys(selectedFilters).some((r) => {
    return Object.keys(rightSideFilterData).includes(r) && r.length > 0;
  });

  const fetchNSetToken = async (
    selectedMenuData,
    newReportView = reportView
  ) => {
    const { reportId, datasetId } = getReportDetails(
      selectedMenuData,
      newReportView
    );
    DashboardService.getPowerBiToken(datasetId, reportId).then((res) => {
      setAccessToken(res.data.token);
    });
  };

  const setFilters = (selectedMenuChange, filterData, value) => {
    let allReportTypes = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
    ];

    let appliedFilters = { ...selectedFilters };
    SetProductivityFilters({});
    SetReliabilityFilters({});

    allReportTypes.forEach((report) => {
      if (report !== selectedMenuChange) {
        delete appliedFilters[report];
      }
    });

    let existingFilters = {
      ...appliedFilters,
      ...{ [selectedMenuChange]: filterData },
    };
    let newFilteredResult = {
      ...appliedFilters,
      ...{ [selectedMenuChange]: [value] },
    };
    const deepEqualValue = deepEqual(
      JSON.parse(savedGraphData.filters),
      newFilteredResult
    );
    setUpdateDisabled(deepEqualValue);
    setSelectedFilters(existingFilters);
    setSelectedMenu(selectedMenuChange);
  };

  const handleReliabilityFilters = async (value) => {
    let allReportTypes = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
    ];
    SetReliabilityFilters(value);
    SetProductivityFilters({});
    let appliedFilters = { ...selectedFilters };
    allReportTypes.forEach((report) => {
      if (report !== value.filtername) {
        delete appliedFilters[report];
      }
    });

    setAccessToken("");
    await fetchNSetToken(value.filtername);

    setSelectedFilters({ ...appliedFilters, [value.filtername]: [value] });
    setSelectedMenu(value.filtername);
    const deepEqualValue = deepEqual(reliabilityFilters, value);
    setUpdateDisabled(deepEqualValue);
  };

  const handleProductivityFilters = async (value) => {
    let allReportTypes = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
    ];
    SetProductivityFilters(value);
    SetReliabilityFilters({});
    let appliedFilters = { ...selectedFilters };
    allReportTypes.forEach((report) => {
      if (report !== value.filtername) {
        delete appliedFilters[report];
      }
    });

    setAccessToken("");
    await fetchNSetToken(value.filtername);

    setSelectedFilters({ ...appliedFilters, [value.filtername]: [value] });
    setSelectedMenu(value.filtername);
    const deepEqualValue = deepEqual(productivityFilters, value);
    setUpdateDisabled(deepEqualValue);
  };

  const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };

  const isObject = (object) => {
    return object != null && typeof object === "object";
  };
  const handleRename = (graphName) => {
    const payload = {
      id: currentGraphID ? currentGraphID : dropDownGraphs[0].id,
      name: graphName,
    };

    setClicknw(null);
    setIsLoading(true);
    if (validate(graphName)) {
      DashboardService.renameGraph(payload).then(() => {
        DashboardService.getSavedGraph(EmailID).then((res) => {
          setdropDownGraphs(res.data);
          setCurrentgraphID(payload.id);
          setdefaultGraphValue(graphName);
          setIsLoading(false);
          setDropDownSelect({ label: graphName, value: payload.id });
        });
      });
    }
  };

  const validate = (values) => {
    const graphValue = defaultGraphValue
      ? defaultGraphValue
      : dropDownGraphs[0].name;

    if (values === graphValue) {
      setErrorMessage("GraphName name already exist.");
      updateRename(true);
      return false;
    } else if (values && values.trim() !== "") {
      setErrorMessage("");
      updateRename(false);
      return true;
    } else {
      setErrorMessage("Please edit the name for the selected dashboard.");
      return false;
    }
  };
  const handleUpdate = () => {
    const payload = {
      id: currentGraphID
        ? currentGraphID
        : dropDownGraphs[0] && dropDownGraphs[0].id,
      name: defaultGraphValue,
      filters: JSON.stringify(selectedFilters),
      reportType: selectedMenu,
    };
    setClicknw(null);
    setIsLoading(true);
    DashboardService.renameGraph(payload).then(() => {
      const UserEmailID = userdetails.idTokenClaims.preferred_username;
      DashboardService.getSavedGraph(UserEmailID).then((res) => {
        setdropDownGraphs(res.data);
        setUpdateDisabled(true);
        setIsLoading(false);
      });
    });
  };
  const handleDelete = () => {
    const id = currentGraphID ? currentGraphID : dropDownGraphs[0].id;

    setClicknw(null);
    setIsLoading(true);
    DashboardService.deleteGraph(id).then(() => {
      SetMachineMetricEnable(true);
      SetReliabilityEnable(true);
      SetProductivityEnable(true);
      if (inspectorRole) {
        SetkparButtonEnable(true);
        seleftSideFilterMenu([
          {
            menuName: "reliability",
            icon: "iconShield",
            headingName: "Reliability",
          },
          {
            menuName: "productivity",
            icon: "iconProductivity",
            headingName: "Productivity",
          },
        ]);
        DashboardService.postRightSideFilterKparData({
          parentCustomers: [""],
          loggedInUser: EmailID,
        }).then((filteredData) => {
          setFilterLoading(false);
          setAllFilters(filteredData.data);
        });
      } else {
        seleftSideFilterMenu(LeftNavBarFilter);
        SetkparButtonEnable(false);
        DashboardService.postRightSideFilterData({
          parentCustomers: [""],
          loggedInUser: EmailID,
        }).then((filteredData) => {
          setFilterLoading(false);
          setAllFilters(filteredData.data);
        });
      }
      SetProductivityFilters({});
      SetReliabilityFilters({});
      setSelectedFilters({});

      DashboardService.getSavedGraph(EmailID)
        .then((res) => {
          setdropDownGraphs(res.data);
          setUpdateDisabled(true);
          setCurrentgraphID(id);
          setDropDownSelect({ label: "Select...", value: "select" });
          setIsLoading(false);
          setAccessToken("");
          setSelectedMenu(null);
          setCurrentgraphID("");
        })
        .catch((err) => {
          setIsLoading(false);
        });
      updateDeleteModal(false);
    });
  };

  const handleClickOpen = () => {
    handleUpdate();
  };
  const handleClickOpennw = () => {
    updateRename(true);
  };

  const deleteHandler = () => {
    updateDeleteModal(true);
  };
  const handleClose = () => {
    updateRename(false);
    updateDeleteModal(false);
    setErrorMessage("");
  };

  const handleSelectedGraph = async (e) => {
    const result = dropDownGraphs.filter((graph) => {
      return graph.id === e.value;
    });
    const { id, name, filters, reportType, isKPARCustomer } = result[0];
    handleEnableOrDisableLeftSide(reportType);

    const FiltersOnLoad = JSON.parse(filters);
    let savedGraphInFocused = JSON.parse(filters);

    const newApiFilters = {};
    for (const iterator in savedGraphInFocused) {
      newApiFilters[iterator] =
        savedGraphInFocused &&
        savedGraphInFocused[iterator] &&
        savedGraphInFocused[iterator].map((ele) => ele.value);
    }
    setFilterLoading(true);
    SetkparButtonEnable(isKPARCustomer);
    if (isKPARCustomer) {
      DashboardService.postRightSideFilterKparData({
        loggedInUser: EmailID,
        ...newApiFilters,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
    } else {
      DashboardService.postRightSideFilterData({
        loggedInUser: EmailID,
        ...newApiFilters,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
    }

    SetProductivityFilters(
      FiltersOnLoad && FiltersOnLoad[reportType] && FiltersOnLoad[reportType][0]
    );
    SetReliabilityFilters(
      FiltersOnLoad && FiltersOnLoad[reportType] && FiltersOnLoad[reportType][0]
    );

    const payload = {
      id: id,
      name: name,
    };
    DashboardService.getSavedPreference(payload.id).then((res) => {});
    setAccessToken("");
    fetchNSetToken(reportType);
    setCurrentgraphID(id);
    setdefaultGraphValue(name);
    setSelectedReportType(reportType);
    setSelectedMenu(reportType);
    setDropDownSelect({ label: name, value: id });
    setSelectedFilters(JSON.parse(filters));
    setUpdateDisabled(true);
  };

  const handleAlignment = async (event, newAlignment) => {
    let newView =
      reportView === availableReportViews.HORIZONTAL
        ? availableReportViews.VERTICAL
        : availableReportViews.HORIZONTAL;
    setAlignment(newAlignment);
    setAccessToken("");
    await fetchNSetToken(selectedMenu, newView);
    setReportView(newView);
  };

  const getReportDetails = (
    selectedMenuDetails,
    newReportView = reportView
  ) => {
    const { reportDetails } = Config;
    return reportDetails[selectedMenuDetails][newReportView];
  };

  const renderMasterReport = () => {
    const { reportId, groupId, embedId, datasetId } = getReportDetails(
      selectedMenu
    );

    const pushAllCustomers = { parentCustomers: parentCustomers };

    const whenNocustomerSelected = {
      ...selectedFilters,
      ...pushAllCustomers,
    };
    const whencustomerSelected = {
      ...pushAllCustomers,
      ...selectedFilters,
    };

    const finalPowerBiInput =
      selectedFilters &&
      selectedFilters.parentCustomers &&
      selectedFilters.parentCustomers.length === 0
        ? whenNocustomerSelected
        : whencustomerSelected;

    const lastPowerBiInput =
      (selectedFilters &&
        selectedFilters.Faults &&
        selectedFilters.Faults.length > 0) ||
      (selectedFilters &&
        selectedFilters.Alarms &&
        selectedFilters.Alarms.length > 0) ||
      (selectedFilters["Productivity Metrics"] &&
        selectedFilters["Productivity Metrics"].length > 0)
        ? selectedFilters
        : finalPowerBiInput;

    const filters = Utils.createReportFilters(lastPowerBiInput);

    return (
      <PowerBi
        reportId={reportId}
        groupId={groupId}
        embedId={embedId}
        selectedFilters={filters}
        datasetId={datasetId}
        tokenType="Embed"
        accessToken={accessToken}
      />
    );
  };

  const handleMenuSelection = (selectedData, filterType) => {
    let existingFilters = {
      ...selectedFilters,
      ...{ [filterType]: selectedData },
    };
    setSelectedFilters(existingFilters);
    const newApiFilters = {};
    for (const iterator in existingFilters) {
      newApiFilters[iterator] =
        existingFilters &&
        existingFilters[iterator] &&
        existingFilters[iterator].map((ele) => ele.value);
    }

    if (kparButtonEnable) {
      DashboardService.postRightSideFilterKparData({
        loggedInUser: EmailID,
        ...newApiFilters,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
    } else {
      DashboardService.postRightSideFilterData({
        loggedInUser: EmailID,
        ...newApiFilters,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
    }
    setFilterLoading(true);
  };

  const checkFilterEmpty =
    selectedMenu === null &&
    (!selectedFilters[selectedMenu] ||
      selectedFilters[selectedMenu].length === 0);

  const generateFocusAreaReport = () => {
    if (
      !checkFilterEmpty &&
      selectedMenu !== null &&
      rightSideFilterSelected === true
    ) {
      if (accessToken) {
        return renderMasterReport();
      } else {
        return <CircularProgress disableShrink />;
      }
    } else {
      return (
        <div className="custom-report">
          <img src={emptygraphicon} alt="Empty Graph Icon" width="600" />
          <h3>
            There is nothing in your focussed area dashboard for now. <br />
            Start building a graph to add it to your focussed area.
          </h3>
        </div>
      );
    }
  };

  let uniqueFilters = allFilters;

  const generateWatchlist = () => {
    const checkWatchlistData = watchListData.length !== 0;
    /* istanbul ignore else */
    if (checkWatchlistData) {
      return <Watchlist watchlistData={watchListData} />;
    } else {
      return <Navigate dashboardLoader={dashboardLoader} />;
    }
  };
  const watchlistCls =
    watchListData.length > 0 ? "content" : "content content_empty_watchlist";

  if (
    rightSideFilterSelected === true &&
    checkFilterEmpty === false &&
    currentGraphID === ""
  ) {
    switchToCustom(
      {
        reportType: selectedMenu,
        filters: JSON.stringify(selectedFilters),
        id: 1,
      },
      productivityFilters,
      reliabilityFilters
    );
  }

  return (
    <>
      {/* Added left and right sidebars in focused area */}
      <div className="focused_cnt">
        <LeftNavbar
          leftSideFilterMenu={leftSideFilterMenu}
          machineMetricEnable={machineMetricEnable}
          reliabilityEnable={reliabilityEnable}
          productivityEnable={productivityEnable}
          setProductivity={productivityFilters}
          setReliability={reliabilityFilters}
          data={
            kparButtonEnable
              ? getLeftMachineMetricForKPARCustomer
              : leftSideMenuData
          }
          handleSetFilters={setFilters}
          selectedFilterForPopover={selectedFilters}
          handleReliabilityFilters={handleReliabilityFilters}
          handleProductivityFilters={handleProductivityFilters}
          selectedMenu={selectedMenu}
          reportType={
            selectedReportType === "" ? selectedMenu : selectedReportType
          }
        />
        <div className={watchlistCls}>
          {isLoading && <CircularProgress disableShrink />}
          {/* Dropdown for select the dashboard */}
          <div className="focused_btn">
            <InputSelect
              defaultValue={dropdownSelect}
              value={dropdownSelect}
              isSearchable
              isLoading={dropdownLoading}
              onChange={handleSelectedGraph}
              data={
                dropDownGraphs &&
                dropDownGraphs.map((graph) => {
                  return { label: graph.name, value: graph.id };
                })
              }
            />
            <div className="focused_btn_right">
              {/* update popup model */}
              <Button
                variant="outlined"
                className="update_popup"
                color="primary"
                onClick={handleClickOpen}
                disabled={
                  updateDisabled || currentGraphID === "" ? true : false
                }
                style={updateDisabled ? { opacity: 1 } : {}}
              >
                Update
              </Button>

              {/* Dropdown for select the dashboard */}
              <Button
                aria-controls="select-menu"
                aria-haspopup="true"
                className="select_right_dropdown"
                disabled={currentGraphID === "" ? true : false}
                onClick={handleClickednw}
              >
                ...
              </Button>
              <Menu
                id="select-menu"
                anchorEl={clicknw}
                keepMounted
                open={Boolean(clicknw)}
                onClose={handleClosed}
              >
                <MenuItem onClick={handleClickOpennw}>
                  Rename Dashboard
                </MenuItem>
                <MenuItem onClick={deleteHandler}>Delete Dashboard</MenuItem>
              </Menu>

              {rename ? (
                <RenameModel
                  state={rename}
                  closeHandler={handleClose}
                  handleSubmit={handleRename}
                  errorMessage={errorMessage}
                  graphName={defaultGraphValue || dropDownGraphs[0].name}
                />
              ) : null}

              {deleteModal ? (
                <DeleteModel
                  state={deleteModal}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  graphName={defaultGraphValue || dropDownGraphs[0].name}
                />
              ) : null}

              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                disabled={currentGraphID === "" ? true : false}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  disabled={
                    selectedMenu === "Downtime Analysis" ||
                    selectedMenu === "Faults" ||
                    selectedMenu === "Alarms" ||
                    currentGraphID === ""
                      ? true
                      : false
                  }
                >
                  Toggle View
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className="powerbi_cnt">{generateFocusAreaReport()}</div>
          <div className="watchlist_slider">{generateWatchlist()}</div>
          {dashboardLoader ? <CircularProgress disableShrink /> : null}
        </div>
        <SideBar
          selectedFilters={selectedFilters}
          handleMenuSelection={handleMenuSelection}
          filterOptions={uniqueFilters}
          selectedFilterForPopover={selectedFilters}
          isLoading={filterLoading}
          resetFlag={resetFlag}
        />
      </div>
    </>
  );
};

export default FocusedArea;
