import React, { useState } from "react";
import { Input } from "@material-ui/core";

import DialogModel from "../dialogModel/dialogModel";

const RenameModel = ({
  state,
  closeHandler,
  handleSubmit,
  errorMessage,
  graphName,
}) => {
  const [value, setValue] = useState(graphName);

  return (
      <DialogModel
        dialogOpen={state}
        buttonText="Rename"
        handleClose={() => closeHandler()}
        heading="Rename Dashboard"
        handleSubmit={() => handleSubmit(value)}
      >
        <form>
          <label>Name:</label>
          <Input
            autoFocus
            value={value}
            placeholder="<Dashboard Name ABC>"
            inputProps={{ "aria-label": "description" }}
            onChange={(e) => {
              setValue(e && e.target.value);
            }}
          />
          {errorMessage && (
            <div>
              <span className="error-message">{errorMessage}</span>
            </div>
          )}
        </form>
      </DialogModel>
  );
};

export default RenameModel;
