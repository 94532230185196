import React from "react";

import DialogModel from "../dialogModel/dialogModel";

const DeleteModel = ({ state, handleClose, handleDelete, graphName }) => {
  return (
    <div>
      <DialogModel
        dialogOpen={state}
        buttonText="Delete"
        handleClose={handleClose}
        heading="Delete Dashboard"
        handleSubmit={() => handleDelete()}
      >
        <form>
          <p>
            Are you sure you want to Delete the selected dashboard:
            <br /> <b>{graphName}</b>
          </p>
        </form>
      </DialogModel>
    </div>
  );
};

export default DeleteModel;
