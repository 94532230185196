import React from "react";

import Header from "../header/";

class Layout extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const { children } = this.props;
    return (
      <>
        <Header />
        <div className="container">{children}</div>
      </>
    );
  }
}

export default Layout;
