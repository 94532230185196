import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";

import "./sidebar.scss";
import allFiltersData from "./filterprops.json";
import AccordionFilter from "../accordianFilter/accordianFilter";
import { ClickAwayListener } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Sidebar = ({
  handleMenuSelection,
  selectedFilterForPopover,
  filterOptions,
  isLoading,
  ResetRightSideFilters,
  resetFlag,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [allFilters] = useState(allFiltersData);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderFilters = () => {
    return Object.keys(allFilters).map((filter) => {
      const { label } = allFilters[filter];
      const dropDownData = filterOptions && filterOptions[filter];

      return (
        <AccordionFilter
          filterType={filter}
          heading={label}
          data={dropDownData}
          isLoading={isLoading}
          selectedFilterForPopover={selectedFilterForPopover}
          onMenuSeletion={(options, filterType, selectedMenu) => {
            handleMenuSelection(options, filterType, selectedMenu);
          }}
        />
      );
    });
  };

  return (
    <div
      className={
        isLoading ? "right_sidebar  disableclickforright" : "right_sidebar"
      }
    >
      <div className="right_side-cnt">
        <ClickAwayListener onClickAway={handleDrawerClose}>
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide)}
                >
                  <p className="filter_vertical_txt"> Master Data Filters</p>
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              <div className={classes.drawerHeader} />
            </main>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="right"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </div>
              <Typography variant="h6" className="filter_heading">
                <span>Master Data Filters</span>
                {resetFlag ? (
                  <Button
                    variant="contained"
                    onClick={() => ResetRightSideFilters()}
                    className="reset_button"
                  >
                    Reset
                  </Button>
                ) : null}
              </Typography>

              <Divider className="heading_border" />
              {renderFilters()}
            </Drawer>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};
export default Sidebar;
