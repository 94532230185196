export const availableReportViews = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const defaultReportView = availableReportViews.HORIZONTAL;

const Config = {
  reactVersion: process.env.REACT_APP_VERSION,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN, //'https://im-wa-api-dev.azurewebsites.net',
  ENDPOINT_PREFIX_DASHBOARD: process.env.REACT_APP_ENDPOINT_PREFIX_DASHBOARD,
  reportDetails: {
    machineMetrics: {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_MACHINEMETRICS_HORIZONTAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_MACHINEMETRICS_HORIZONTAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_MACHINEMETRICS_VERTICAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_MACHINEMETRICS_VERTICAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "MachineMetricToggle",
          column: "Name",
        },
        operator: "In",
      },
    },
    "Productivity Metrics": {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_PRODUCTIVITY_HORIZONTAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_PRODUCTIVITY_HORIZONTAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_PRODUCTIVITY_VERTICAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_PRODUCTIVITY_VERTICAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },
    "Downtime Analysis": {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_DOWNTIMEANALYSIS_HORIZONTAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_DOWNTIMEANALYSIS_HORIZONTAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_DOWNTIMEANALYSIS_HORIZONTAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_DOWNTIMEANALYSIS_HORIZONTAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },
    Faults: {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_FAULTS_HORIZONTAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_FAULTS_HORIZONTAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_FAULTS_VERTICAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_FAULTS_VERTICAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },
    Alarms: {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_ALARMS_HORIZONTAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_ALARMS_HORIZONTAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_ALARMS_VERTICAL_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_ALARMS_VERTICAL_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },
    reportid: {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_REPORTS_MONTHLY_REPORT_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_REPORTS_MONTHLY_REPORT_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_REPORTS_MONTHLY_REPORT_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_REPORTS_MONTHLY_REPORT_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },
    benchmarkid: {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_REPORTS_BENCHMARK_PAGE_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_REPORTS_BENCHMARK_PAGE_REPORTID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_REPORTS_BENCHMARK_PAGE_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_REPORTS_BENCHMARK_PAGE_REPORTID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },
    RMcare: {
      [availableReportViews.HORIZONTAL]: {
        reportId: process.env.REACT_APP_REPORTS_CONTRACT_REPORT_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_REPORTS_CONTRACT_REPORT_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
      [availableReportViews.VERTICAL]: {
        reportId: process.env.REACT_APP_REPORTS_CONTRACT_REPORT_REPORTID,
        groupId: process.env.REACT_APP_POWERBI_GROUPID,
        embedId: process.env.REACT_APP_REPORTS_CONTRACT_REPORT_EMBEDID,
        datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      },
    },

    downtime: {
      reportId: "3b0248ad-fc89-4348-a6cc-183a88f8dd54",
      groupId: "1b543c9a-b28c-4aad-873e-b6c6e59cc6d9",
      embedId: "3b0248ad-fc89-4348-a6cc-183a88f8dd54",
      datasetId: "3136196d-22cf-47d8-b1fd-024915fc4328",
    },
    inspectionReport: {
      reportId: process.env.REACT_APP_REPORTS_INSPECTION_REPORTID,
      groupId: process.env.REACT_APP_POWERBI_GROUPID,
      embedId: process.env.REACT_APP_REPORTS_INSPECTION_EMBEDID,
      datasetId: process.env.REACT_APP_POWERBI_DATASETID,
      pageDetails: {
        conditionOverview:
          process.env.REACT_APP_REPORTS_INSPECTION_PAGE_OVERVIEW,
        tracks: process.env.REACT_APP_REPORTS_INSPECTION_PAGE_TRACKS,
        sprockets: process.env.REACT_APP_REPORTS_INSPECTION_PAGE_SPROCKETS,
        rollers: process.env.REACT_APP_REPORTS_INSPECTION_PAGE_ROLLERS,
        componentLife:
          process.env.REACT_APP_REPORTS_INSPECTION_PAGE_COMPONENTLIFE,
        tracksImages:
          process.env.REACT_APP_REPORTS_INSPECTION_PAGE_TRACKS_IMAGES,
        rollersImages:
          process.env.REACT_APP_REPORTS_INSPECTION_PAGE_ROLLER_IMAGES,
        sprocketsImages:
          process.env.REACT_APP_REPORTS_INSPECTION_PAGE_SPROCKETS_IMAGES,
      },
    },
    parentCustomers: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimCustomer",
          column: "PARENTID",
        },
        operator: "In",
      },
    },
    customerSite: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimSite",
          column: "SITE",
        },
        operator: "In",
      },
    },
    inspectionCustomerSite: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimSite",
          column: "SITEID",
        },
        operator: "In",
      },
    },
    inspectionDate: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimDate",
          column: "DATE_PKEY",
        },
        operator: "In",
      },
    },
    productType: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "PRODUCTTYPE",
        },
        operator: "In",
      },
    },
    productModel: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "BASEMODEL",
        },
        operator: "In",
      },
    },
    inspectionProductModel: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "ICTMODEL",
        },
        operator: "In",
      },
    },
    serialNumber: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "SERIALNUMBER",
        },
        operator: "In",
      },
    },
    customerIds: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "CUSTOMERUNITNUMBER",
        },
        operator: "In",
      },
    },
    komatsuRegion: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "KOMATSUREGION",
        },
        operator: "In",
      },
    },
    commodity: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "COMMODITY",
        },
        operator: "In",
      },
    },
    payload: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "OPTIMALPAYLOAD",
        },
        operator: "In",
      },
    },
    machineIds: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "SERIALNUMBER",
        },
        operator: "In",
      },
    },
    months: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimDate",
          column: "YEARMONTH_ID",
        },
        operator: "In",
      },
    },
    productModels: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachine",
          column: "BASEMODEL",
        },
        operator: "In",
      },
    },

    site: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimSite",
          column: "SITE",
        },
        operator: "In",
      },
    },
    parentId: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimCustomer",
          column: "PARENTID",
        },
        operator: "In",
      },
    },
    contractParentId: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimCustomerContract",
          column: "CUSTOMERID",
        },
        operator: "In",
      },
    },

    contractFleetId: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimFleetContract",
          column: "FLEETID",
        },
        operator: "In",
      },
    },
    contractSerialNumber: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimEquipmentContract",
          column: "SERIALNUMBER",
        },
        operator: "In",
      },
    },
    contractDateFrom: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimSelectionDate",
          column: "DATEID",
        },
        operator: "In",
      },
    },
    contractModel: {
      filterObj: {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "DimMachineContract",
          column: "MODELID",
        },
        operator: "In",
      },
    },
  },
};

export default Config;
