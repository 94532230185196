import React, { useState, forwardRef, useEffect } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const CommentsEditable = ({
  handleTextEditor,
  data,
  previousComments,
  historicalData,
  enablePreviousComments,
  noDataPresentError,
}) => {
  const [columns, setColumns] = useState([
    {
      title: "Section",
      field: "section",
      width: "20%",
      validate: (rowData) =>
        rowData.section ? true : "Section can not be empty",
      editComponent: (props) => {
        return (
          <TextField
            value={props.value}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => {
              props.onChange(event.target.value);
            }}
          />
        );
      },
    },
    {
      title: "Sub Section",
      field: "subSection",
      width: "20%",
      validate: (rowData) =>
        rowData.subSection ? true : "Sub Section can not be empty",
      editComponent: (props) => {
        return (
          <TextField
            value={props.value}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => {
              props.onChange(event.target.value);
            }}
          />
        );
      },
    },
    {
      title: "Comments",
      field: "comments",
      width: "60%",
      editComponent: (props) => {
        return (
          <TextField
            value={props.value}
            inputProps={{ maxLength: 200 }}
            onChange={(event) => {
              props.onChange(event.target.value);
            }}
          />
        );
      },
    },
  ]);

  const [displayDate, SetDisplayDate] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const dateDisplay = new Date();
      var minute = dateDisplay.getMinutes();
      SetDisplayDate(minute);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="MaxRowAdded">
        {data.length > 2000 ? "Max Comments Added" : null}
      </div>
      <div>
        <Tooltip
          title={<h3>Load last month best practice as default comments.</h3>}
        >
          <div
            onClick={() => {
              previousComments();
            }}
            className={
              data.length > 0
                ? "previousComments disable"
                : "previousComments enable"
            }
          >
            Default Comments
          </div>
        </Tooltip>
        <div className="historicalData">{historicalData}</div>
        {noDataPresentError ? (
          <div className="historicalData">Data not available</div>
        ) : null}
      </div>
      <MaterialTable
        options={{
          paging: false,
          search: false,
          tableLayout: "fixed",
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return {
                backgroundColor: "#f2f2f2",
                fontSize: "14px",
              };
            }
            return { fontSize: "14px" };
          },
          headerStyle: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Noto Sans",
          },
        }}
        icons={tableIcons}
        title="Comments Section"
        columns={columns}
        data={data}
        editable={{
          onRowAdd:
            data.length > 2000
              ? null
              : (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      handleTextEditor([newData], "add");
                      resolve();
                    }, 1);
                  }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                handleTextEditor([newData], "update");
                resolve();
              }, 1);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data];
                handleTextEditor([oldData], "delete");
                resolve();
              }, 1);
            }),
        }}
      />
      {displayDate === 0 ? (
        <div className="commentsRefresh">
          {" "}
          Latest saved user comments can be downloaded now.
        </div>
      ) : (
        <div className="commentsRefresh">
          Latest saved user comments will reflect in PPT/PDF after{" "}
          {displayDate < 30 ? 30 - displayDate : 60 - displayDate} minutes.
        </div>
      )}
    </>
  );
};

export default CommentsEditable;
