import React from "react";
import WatchlistAttribute from "../watchlistAttribute/watchlistAttribute";

const WatchlistCard = ({ value }) => {
  return (
    <>
      <div className="whishlist-cnt">
        <div className="site_txt">
          <span>Customer</span>
          <br />
          {value.parentCustomer}
        </div>
        <div className="site_txt">
          <span>Site</span>
          <br />
          {value.site}
        </div>
        <div className="fleet_txt">
          <span>Model</span>
          <br />
          {value.baseModel}
        </div>
        <div className="serial_number">
          <span>Month - Year</span>
          <br />
          {value.monthYear ? value.monthYear : "NA"}
        </div>
      </div>
      <div className="whishlist-cnt trading_num">
        {value.jsonMetrics.map((name, index) => {
          return <WatchlistAttribute key={index} name={name} />;
        })}
      </div>
    </>
  );
};

export default WatchlistCard;
