import React from "react";
import { useHistory } from "react-router-dom";

const Navigate = ({ dashboardLoader }) => {
  const history = useHistory();

  const navigateToWatchlist = () => {
    history.push("/watchlist");
  };

  return (
    <div className="watchlist_empty_txt">
      {!dashboardLoader ? (
        <>
          {" "}
          <b>Watchlist</b>
          <a href onClick={navigateToWatchlist} className="navigate-watchlist">
            {" "}
            Click here
          </a>{" "}
          to add to your watchlist
        </>
      ) : null}
    </div>
  );
};

export default Navigate;
