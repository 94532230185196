import React from "react";
import InputSelect from "../../../../components/input/inputSelect/inputSelect";
import InputSelectMulti from "../../../../components/input/inputSelect/inputSelectMulti";

const BenchMarkReport = ({
  benchMarchValidation,
  submitted,
  reportid,
  handleBenchMarkAjax,
  benchMarkCustomer,
  SetBenchMarchValidation,
  selectedCustomer,
  SetSelectedCust,
}) => {
  return (
    <>
      <div className="filter_cnt">
        <label>
          Model<span className="mandatory-mark">*</span>
        </label>
        <InputSelect
          value={selectedCustomer.model}
          data={reportid.models}
          placeholder="Select here…"
          isLoading={
            reportid && reportid.models && reportid.models.length !== 0
              ? false
              : true
          }
          onChange={(r) => {
            handleBenchMarkAjax({ reportid: 4, model: [r && r.value] });
            SetBenchMarchValidation({
              parentId: [],
              model: [r && r.value],
            });
            SetSelectedCust({
              model: [{ value: r && r.value, label: r && r.label }],
              customer: [],
            });
          }}
        />
        {submitted && benchMarchValidation.model.length === 0 ? (
          <span className="error-message">Please Select Model</span>
        ) : null}
      </div>
      <div className="filter_cnt">
        <label>
          Customer<span className="mandatory-mark">*</span>
        </label>
        <InputSelectMulti
          value={selectedCustomer.customer}
          isCheckInput={true}
          data={benchMarkCustomer}
          placeholder={
            benchMarchValidation.parentId &&
            benchMarchValidation.parentId.length >= 1
              ? `${benchMarchValidation.parentId.length} selected`
              : "Select..."
          }
          isMulti
          onChange={(metrics) => {
            const metricsGraph = metrics && metrics.map((ele) => ele.value);
            const seletedCustomer =
              metrics &&
              metrics.map((ele) => ({
                name: ele.label,
                value: ele.value,
              }));
            SetSelectedCust({
              customer: seletedCustomer,
              model: selectedCustomer.model,
            });
            SetBenchMarchValidation({
              parentId: metricsGraph,
              model: benchMarchValidation.model,
            });
          }}
        />
        {submitted && benchMarchValidation.parentId.length === 0 ? (
          <span className="error-message">Please Select Customer</span>
        ) : null}
      </div>
    </>
  );
};

export default BenchMarkReport;
