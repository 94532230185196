import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const InputButton = ({
  onClick,
  size,
  disabled,
  buttonText,
  variant,
  className,
}) => {
  return (
    <div>
      <Button
        onClick={onClick}
        size={size}
        disabled={disabled}
        variant={variant}
        className={className}
      >
        {buttonText}
      </Button>
    </div>
  );
};

InputButton.propTypes = {
  variant: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["large","medium","small"]),
  isDisabled: PropTypes.bool,
};

export default InputButton;
