import React from "react";
import "../watchlist.scss";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const EditOrDeleteWatchList = ({
  handleClickuser,
  anchorEl,
  handleClose,
  handleClickEditUser,
  editWatchlistDataSetter,
  SetEditWatchListValidation,
  filterId,
  deleteWatchList,
  deleteWatchlistCard,
}) => {
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event) => {
          handleClickuser(event, editWatchlistDataSetter, filterId);
          SetEditWatchListValidation({
            parameters: editWatchlistDataSetter.parameters,
          });
        }}
        className="user-btn"
      >
        ...
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl ? true : false}
        onClose={() => handleClose()}
      >
        {" "}
        <IconButton
          aria-label="close"
          className="close_icon"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <MenuItem
          onClick={() => {
            deleteWatchlistCard(deleteWatchList);
            handleClose();
          }}
        >
          Delete Watchlist
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditOrDeleteWatchList;
