import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import * as allImages from "./../../images";
import PopoverComponent from "../PopoverComponent/popover";
import PopoverReliabilityComponent from "../PopoverComponent/popoverReliability";
import PopoverProductivityComponent from "../PopoverComponent/popoverProductivity";
import "./leftnavbar.scss";

const LeftNavbar = ({
  data,
  handleSetFilters,
  handleReliabilityFilters,
  selectedFilterForPopover,
  selectedMenu,
  reportType,
  handleProductivityFilters,
  setProductivity,
  setReliability,
  machineMetricEnable,
  reliabilityEnable,
  productivityEnable,
  leftSideFilterMenu,
}) => {
  const [selected, setSelected] = useState([]);
  const [buttonEvent, setButtonEvent] = useState(null);
  const [typography, setTypography] = useState("");
  const [menu, setMenu] = useState("");

  const togglePopover = (event, menuName, headingName) => {
    if (headingName) {
      setTypography(headingName);
      setSelected(data && data[menuName]);
      setMenu(menuName);
      setButtonEvent(event && event.currentTarget);
    } else {
      setButtonEvent(null);
      setTimeout(() => {
        setSelected([]);
        setTypography("");
      }, 200);
    }
  };

  const onAttribSelection = (selectedAttribs, value) => {
    handleSetFilters(menu, selectedAttribs, value);
  };

  const renderReliabilityReport = (selectedAttribs) => {
    handleReliabilityFilters(selectedAttribs);
  };

  const renderProductivityReport = (selectedAttribs) => {
    handleProductivityFilters(selectedAttribs);
  };

  return (
    <div className="left_nav">
      <div className="left_nav-cnt">
        {selected && menu === "machineMetrics" ? (
          <PopoverComponent
            popoverData={selected}
            typographyLabel={typography}
            handleCloseButton={togglePopover}
            buttonEvent={buttonEvent}
            handleSelection={onAttribSelection}
            reportType={reportType}
            selectedMenu={menu}
            selectedFilterForPopover={selectedFilterForPopover}
            machineMetricEnable={machineMetricEnable}
          />
        ) : null}

        {selected && menu === "productivity" && (
          <PopoverProductivityComponent
            popoverData={selected}
            typographyLabel={typography}
            handleCloseButton={togglePopover}
            buttonEvent={buttonEvent}
            handleSelection={renderProductivityReport}
            selectedFilterForPopover={selectedFilterForPopover}
            selectedMenu={menu}
            reportType={reportType}
            setProductivity={setProductivity}
            productivityEnable={productivityEnable}
          />
        )}

        {selected && menu === "reliability" && (
          <PopoverReliabilityComponent
            popoverData={selected}
            typographyLabel={typography}
            handleCloseButton={togglePopover}
            buttonEvent={buttonEvent}
            handleSelection={renderReliabilityReport}
            selectedFilterForPopover={selectedFilterForPopover}
            selectedMenu={menu}
            reportType={reportType}
            setReliability={setReliability}
            reliabilityEnable={reliabilityEnable}
          />
        )}

        <MenuList className="MuiMenu-list">
          {leftSideFilterMenu && leftSideFilterMenu.map((menulistside, index) => {
            const { menuName, icon, headingName } = menulistside;
            let menuSelectedCount = 0;
            let reliabilitySlectedCount = 0;
            if (
              selectedFilterForPopover[menuName] &&
              selectedFilterForPopover[menuName].length > 0
            ) {
              menuSelectedCount = selectedFilterForPopover[menuName].length;
            }
            if (
              selectedFilterForPopover["Downtime Analysis"] ||
              selectedFilterForPopover["Faults"] ||
              selectedFilterForPopover["Faults"]
            ) {
              reliabilitySlectedCount = 1;
            }

            return (
              <MenuItem
                className={
                  headingName === typography
                    ? `active_popover ${headingName}`
                    : ""
                }
                onClick={(event) => togglePopover(event, menuName, headingName)}
              >
                <img src={allImages[icon]} alt="iconmetrics" />
              </MenuItem>
            );
          })}
        </MenuList>
      </div>
    </div>
  );
};

LeftNavbar.propTypes = {
  data: PropTypes.array,
};

export default LeftNavbar;
