import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";

import emptygraphicon from "./../../images/icon_emptygraph.svg";
import SideBar from "../../components/sidebar";
import LeftNavbar from "../../components/LeftNavbar";
import Config from "../../config";
import PowerBi from "../../components/PowerBI";
import Utils from "../../utils";
import { DashboardService } from "../../services";
import DialogModel from "../../components/model/dialogModel";
import InputButton from "../../components/input/inputButton/inputButton";
import { availableReportViews, defaultReportView } from "../../config/config";
import "./customGraph.scss";


const CustomGraph = ({
  savedGraphs,
  leftSideMenuData,
  rightSideFilterData,
  setdropDownGraphs,
  switchToFocused,
  savedGraphData,
  selectedProductivity,
  seletedReliability,
  parentCustomers,
  getLeftMachineMetricForKPARCustomer,
  rightSideFilterKparData,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [allFilters, setAllFilters] = useState(rightSideFilterData);
  const [accessToken, setAccessToken] = useState("");
  const [open, setOpen] = useState(false);
  let [fieldData, setFieldtData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reportView, setReportView] = useState(defaultReportView);
  const [isLoading, setIsLoading] = useState(false);
  const ReliabilityMenus = ["Downtime Analysis", "Faults", "Alarms"];
  const ProductivityMenus = ["Productivity Metrics"];
  const [filterLoading, setFilterLoading] = useState(false);
  const userdetails = useSelector((state) => state.userdetails);
  const [productivityFilters, SetProductivityFilters] = useState(
    selectedProductivity
  );
  const [reliabilityFilters, SetReliabilityFilters] = useState(
    seletedReliability
  );
  const [machineMetricEnable] = useState(true);
  const [reliabilityEnable] = useState(true);
  const [productivityEnable] = useState(true);
  const [rightSideFilterSelected, SetSideFilterSelected] = useState(false);
  const [resetFlag] = useState(true);
  const [leftSideFilterMenu, setleftSideFilterMenu] = useState([
    {
      menuName: "machineMetrics",
      icon: "iconmetrics",
      headingName: "Machine Metrics",
    },
    {
      menuName: "reliability",
      icon: "iconShield",
      headingName: "Reliability",
    },
  ]);

  const EmailID = userdetails.idTokenClaims.preferred_username;
  const [kparLoading, setKparLoading] = useState(true);
  const [kparButtonEnable, SetkparButtonEnable] = useState(false);

  const inspectorRole =
    userdetails.usercustomerData &&
    userdetails.usercustomerData.customers &&
    userdetails.usercustomerData.customers.length > 0
      ? false
      : true;

  useEffect(() => {
    if (inspectorRole) {
      setAllFilters(rightSideFilterKparData);
      SetkparButtonEnable(true);
      setleftSideFilterMenu([
        {
          menuName: "reliability",
          icon: "iconShield",
          headingName: "Reliability",
        },
        {
          menuName: "productivity",
          icon: "iconProductivity",
          headingName: "Productivity",
        },
      ]);
    } else {
      setAllFilters(rightSideFilterData);
    }

    if (typeof savedGraphData.id !== "string") {
      const { reportType, filters } = savedGraphData;
      fetchNSetToken(reportType);
      setSelectedFilters(JSON.parse(filters));
      setSelectedMenu(reportType);
      rightSideFilterSelect(JSON.parse(filters));

      const existingFilters = JSON.parse(filters);

      const newApiFilters = {};
      for (const iterator in existingFilters) {
        newApiFilters[iterator] =
          existingFilters &&
          existingFilters[iterator] &&
          existingFilters[iterator].map((ele) => ele.value);
      }
      setFilterLoading(true);
      if (inspectorRole) {
        DashboardService.postRightSideFilterKparData({
          loggedInUser: EmailID,
          ...newApiFilters,
        }).then((filteredData) => {
          setFilterLoading(false);
          setAllFilters(filteredData.data);
        });
      } else {
        DashboardService.postRightSideFilterData({
          loggedInUser: EmailID,
          ...newApiFilters,
        }).then((filteredData) => {
          setFilterLoading(false);
          setAllFilters(filteredData.data);
        });
      }
    }
  }, [rightSideFilterData, rightSideFilterKparData]);

  const rightSideFilterSelect = (rightsideEnable) => {
    if (
      (rightsideEnable &&
        rightsideEnable.customerIds &&
        rightsideEnable.customerIds.length) ||
      (rightsideEnable &&
        rightsideEnable.customerSite &&
        rightsideEnable.customerSite.length) ||
      (rightsideEnable &&
        rightsideEnable.komatsuRegion &&
        rightsideEnable.komatsuRegion.length) ||
      (rightsideEnable &&
        rightsideEnable.parentCustomers &&
        rightsideEnable.parentCustomers.length) ||
      (rightsideEnable &&
        rightsideEnable.payload &&
        rightsideEnable.payload.length) ||
      (rightsideEnable &&
        rightsideEnable.productModel &&
        rightsideEnable.productModel.length) ||
      (rightsideEnable &&
        rightsideEnable.productType &&
        rightsideEnable.productType.length) ||
      (rightsideEnable &&
        rightsideEnable.serialNumber &&
        rightsideEnable.serialNumber.length) ||
      (rightsideEnable &&
        rightsideEnable.commodity &&
        rightsideEnable.commodity.length)
    ) {
      SetSideFilterSelected(true);
    } else {
      SetSideFilterSelected(false);
    }
  };

  const checkFilterEmpty =
    selectedMenu === null &&
    (!selectedFilters[selectedMenu] ||
      selectedFilters[selectedMenu].length === 0);

  const handleSubmit = () => {
    const savedFilter = allFilters;
    const pushAllCustomers = {
      parentCustomers: savedFilter && savedFilter.parentCustomers,
    };

    const whenNocustomerSelectedSavedPrefrence = {
      ...selectedFilters,
      ...pushAllCustomers,
    };
    const whencustomerSelectedSavedPreference = {
      ...pushAllCustomers,
      ...selectedFilters,
    };

    const inputToSavedPreference =
      selectedFilters &&
      selectedFilters.parentCustomers &&
      selectedFilters.parentCustomers.length === 0
        ? whenNocustomerSelectedSavedPrefrence
        : whencustomerSelectedSavedPreference;

    if (validate(fieldData)) {
      let graphData = {
        name: fieldData,
        reportType: selectedMenu,
        filters: JSON.stringify(inputToSavedPreference),
        email: userdetails.idTokenClaims.preferred_username,
        lastSavedPreference: true,
        IsKPARCustomer: kparButtonEnable,
      };
      setIsLoading(true);
      DashboardService.addNewGraph({ ...graphData })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          switchToFocused(data);
          setIsLoading(false);
        });
    }
  };

  const validate = (values) => {
    const temp = fieldData.trim();
    let hasData = savedGraphs.some((data) => data.name === temp);

    if (hasData) {
      setErrorMessage("Dashboard name already exist.");
      setOpen(true);
      return false;
    } else if (values.trim() !== "") {
      setErrorMessage("");
      setOpen(false);
      setFieldtData("");
      return true;
    } else {
      setErrorMessage("Please enter a name for the created dashboard.");
      return false;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldtData("");
    setErrorMessage("");
  };

  const fetchNSetToken = async (
    selectedMenuData,
    newReportView = reportView
  ) => {
    const { reportId, datasetId } = getReportDetails(
      selectedMenuData,
      newReportView
    );
    DashboardService.getPowerBiToken(datasetId, reportId).then((res) => {
      setAccessToken(res.data.token);
    });
  };

  const handleReliabilityFilters = async (value) => {
    let allReportTypes = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
    ];
    SetReliabilityFilters(value);
    SetProductivityFilters({});
    let appliedFilters = { ...selectedFilters };
    allReportTypes.forEach((report) => {
      if (report !== value.filtername) {
        delete appliedFilters[report];
      }
    });

    setAccessToken("");
    await fetchNSetToken(value.filtername);

    setSelectedFilters({ ...appliedFilters, [value.filtername]: [value] });
    setSelectedMenu(value.filtername);
  };

  const handleProductivityFilters = async (value) => {
    let allReportTypes = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
    ];
    SetProductivityFilters(value);
    SetReliabilityFilters({});
    let appliedFilters = { ...selectedFilters };
    allReportTypes.forEach((report) => {
      if (report !== value.filtername) {
        delete appliedFilters[report];
      }
    });

    setAccessToken("");
    await fetchNSetToken(value.filtername);
    setSelectedFilters({ ...appliedFilters, [value.filtername]: [value] });
    setSelectedMenu(value.filtername);
  };

  const setFilters = async (menuName, filterData) => {
    let allReportTypes = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
    ];

    let appliedFilters = { ...selectedFilters };
    SetReliabilityFilters({});
    SetProductivityFilters({});

    allReportTypes.forEach((report) => {
      if (report !== menuName) {
        delete appliedFilters[report];
      }
    });

    let stopMachineMetricRerender = [
      "machineMetrics",
      "Productivity Metrics",
      "reliability",
      "Downtime Analysis",
      "Faults",
      "Alarms",
      "commodity",
      "customerIds",
      "customerSite",
      "komatsuRegion",
      "parentCustomers",
      "payload",
      "productModel",
      "productType",
      "serialNumber",
    ];

    let deleteRerender = { ...selectedFilters };

    stopMachineMetricRerender.forEach((report) => {
      if (report !== menuName) {
        delete deleteRerender[report];
      }
    });
    if (Object.keys(deleteRerender).length === 0) {
      setAccessToken("");
    }
    await fetchNSetToken(menuName);

    let existingFilters = {
      ...appliedFilters,
      ...{ [menuName]: filterData },
    };

    setSelectedFilters(existingFilters);
    setSelectedMenu(menuName);
  };

  const ResetRightSideFilters = () => {
    let removeRightSideFilters = [
      "commodity",
      "customerIds",
      "customerSite",
      "komatsuRegion",
      "parentCustomers",
      "payload",
      "productModel",
      "productType",
      "serialNumber",
    ];
    let appliedFilters = { ...selectedFilters };
    removeRightSideFilters.forEach((report) => {
      delete appliedFilters[report];
    });
    setSelectedFilters(appliedFilters);
    rightSideFilterSelect(appliedFilters);
    setFilterLoading(true);

    if (kparButtonEnable) {
      DashboardService.postRightSideFilterKparData({
        parentCustomers: [""],
        loggedInUser: EmailID,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
    } else {
      DashboardService.postRightSideFilterData({
        parentCustomers: [""],
        loggedInUser: EmailID,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
    }
  };

  const getKparCustomers = (kparvalue) => {
    const removeRightSideFilters = [
      "commodity",
      "customerIds",
      "customerSite",
      "komatsuRegion",
      "parentCustomers",
      "payload",
      "productModel",
      "productType",
      "serialNumber",
    ];
    const appliedFilters = { ...selectedFilters };
    removeRightSideFilters.forEach((report) => {
      delete appliedFilters[report];
    });
    setSelectedFilters(appliedFilters);
    rightSideFilterSelect(appliedFilters);
    setKparLoading(false);
    setFilterLoading(true);
    if (kparvalue) {
      SetkparButtonEnable(true);
      DashboardService.postRightSideFilterKparData({
        parentCustomers: [""],
        loggedInUser: EmailID,
      })
        .then((filteredData) => {
          setKparLoading(true);
          setFilterLoading(false);
          setAllFilters(filteredData.data);
          SetProductivityFilters({});
          SetReliabilityFilters({});
          setSelectedFilters({});
          setSelectedMenu(null);
          setleftSideFilterMenu([
            {
              menuName: "reliability",
              icon: "iconShield",
              headingName: "Reliability",
            },
            {
              menuName: "productivity",
              icon: "iconProductivity",
              headingName: "Productivity",
            },
          ]);
        })
        .catch(() => {
          SetkparButtonEnable(false);
          setKparLoading(false);
        });
    } else {
      SetkparButtonEnable(false);
      DashboardService.postRightSideFilterData({
        parentCustomers: [""],
        loggedInUser: EmailID,
      })
        .then((filteredData) => {
          setKparLoading(true);
          setFilterLoading(false);
          setAllFilters(filteredData.data);
          SetProductivityFilters({});
          SetReliabilityFilters({});
          setSelectedFilters({});
          setSelectedMenu(null);
          setleftSideFilterMenu([
            {
              menuName: "machineMetrics",
              icon: "iconmetrics",
              headingName: "Machine Metrics",
            },
            {
              menuName: "reliability",
              icon: "iconShield",
              headingName: "Reliability",
            },
          ]);
        })
        .catch(() => {
          SetkparButtonEnable(false);
          setKparLoading(false);
        });
    }
  };

  const getReportDetails = (
    selectedMenuDetails,
    newReportView = reportView
  ) => {
    const { reportDetails } = Config;
    return reportDetails[selectedMenuDetails][newReportView];
  };
  const [alignment, setAlignment] = useState("left");

  const handleAlignment = async (event, newAlignment) => {
    let newView =
      reportView === availableReportViews.HORIZONTAL
        ? availableReportViews.VERTICAL
        : availableReportViews.HORIZONTAL;
    setAlignment(newAlignment);
    setAccessToken("");
    await fetchNSetToken(selectedMenu, newView);
    setReportView(newView);
  };
  const renderMasterReport = () => {
    const { reportId, groupId, embedId, datasetId } = getReportDetails(
      selectedMenu
    );

    const pushAllCustomers = { parentCustomers: parentCustomers };

    const whenNocustomerSelected = {
      ...selectedFilters,
      ...pushAllCustomers,
    };
    const whencustomerSelected = {
      ...pushAllCustomers,
      ...selectedFilters,
    };

    const finalPowerBiInput =
      selectedFilters &&
      selectedFilters.parentCustomers &&
      selectedFilters.parentCustomers.length === 0
        ? whenNocustomerSelected
        : whencustomerSelected;

    const lastPowerBiInput =
      (selectedFilters &&
        selectedFilters.Faults &&
        selectedFilters.Faults.length > 0) ||
      (selectedFilters &&
        selectedFilters.Alarms &&
        selectedFilters.Alarms.length > 0) ||
      (selectedFilters["Productivity Metrics"] &&
        selectedFilters["Productivity Metrics"].length > 0)
        ? selectedFilters
        : finalPowerBiInput;

    const filters = Utils.createReportFilters(lastPowerBiInput);
    return (
      <PowerBi
        reportId={reportId}
        groupId={groupId}
        embedId={embedId}
        selectedFilters={filters}
        datasetId={datasetId}
        tokenType="Embed"
        accessToken={accessToken}
        reportBaseURL="https://app.powerbi.com/reportEmbed"
      />
    );
  };
  const handleMenuSelection = (selectedData, filterType) => {
    let existingFilters = {
      ...selectedFilters,
      ...{ [filterType]: selectedData },
    };
    setSelectedFilters(existingFilters);
    rightSideFilterSelect(existingFilters);

    const newApiFilters = {};
    for (const iterator in existingFilters) {
      newApiFilters[iterator] =
        existingFilters &&
        existingFilters[iterator] &&
        existingFilters[iterator].map((ele) => ele.value);
    }
    if (kparButtonEnable) {
      DashboardService.postRightSideFilterKparData({
        loggedInUser: EmailID,
        ...newApiFilters,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
      setFilterLoading(true);
    } else {
      DashboardService.postRightSideFilterData({
        loggedInUser: EmailID,
        ...newApiFilters,
      }).then((filteredData) => {
        setFilterLoading(false);
        setAllFilters(filteredData.data);
      });
      setFilterLoading(true);
    }
  };

  const generateCustomGraphReport = () => {
    if (
      !checkFilterEmpty &&
      selectedMenu !== null &&
      rightSideFilterSelected === true &&
      ((selectedFilters[selectedMenu] &&
        selectedFilters[selectedMenu].length > 0) ||
        ReliabilityMenus.indexOf(selectedMenu) >= 0 ||
        ProductivityMenus.indexOf(selectedMenu) >= 0)
    ) {
      if (accessToken) {
        return renderMasterReport();
      } else {
        return <CircularProgress disableShrink />;
      }
    } else {
      return (
        <div className="custom-report">
          <img src={emptygraphicon} alt="Empty Graph Icon" width="600" />
          <h3>
            There is nothing in your custom area dashboard for . <br />
            Start building a graph to add it to your focussed area.
          </h3>
        </div>
      );
    }
  };

  let uniqueFilters = allFilters;
  let leftBarUniqueData = Utils.removeLeftSidebarDuplicate(leftSideMenuData);

  return (
    <>
      <div className="custom_graph_cnt">
        {kparLoading ? (
          <LeftNavbar
            leftSideFilterMenu={leftSideFilterMenu}
            machineMetricEnable={machineMetricEnable}
            reliabilityEnable={reliabilityEnable}
            productivityEnable={productivityEnable}
            setProductivity={productivityFilters}
            setReliability={reliabilityFilters}
            data={
              kparButtonEnable
                ? getLeftMachineMetricForKPARCustomer
                : leftBarUniqueData
            }
            handleSetFilters={setFilters}
            selectedMenu={selectedMenu}
            reportType={selectedMenu}
            selectedFilters={selectedFilters}
            handleReliabilityFilters={handleReliabilityFilters}
            handleProductivityFilters={handleProductivityFilters}
            selectedFilterForPopover={selectedFilters}
          />
        ) : (
          <div className="left_nav"></div>
        )}

        <div className="content">
          {isLoading && <CircularProgress disableShrink />}
          {!kparLoading && <CircularProgress disableShrink />}
          <div className="focus_area_btn">
            {inspectorRole ? null : (
              <>
                {" "}
                <span className="swicthText">Get Customer Data</span>
                <Switch
                  checked={kparButtonEnable}
                  onClick={() => {
                    getKparCustomers(!kparButtonEnable);
                  }}
                  name="checkedB"
                  color="primary"
                  disabled={!kparLoading}
                />
                <span className="swicthText rightspace">Get KPAR Data</span>
              </>
            )}

            <InputButton
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              buttonText="Add to Focus Area"
              disabled={
                checkFilterEmpty ||
                rightSideFilterSelected === false ||
                filterLoading
                  ? true
                  : false
              }
            />
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              disabled={
                checkFilterEmpty ||
                rightSideFilterSelected === false ||
                selectedMenu === "reliability"
                  ? true
                  : false
              }
            >
              <ToggleButton
                value="left"
                aria-label="left aligned"
                disabled={
                  checkFilterEmpty ||
                  rightSideFilterSelected === false ||
                  selectedMenu === "Downtime Analysis" ||
                  selectedMenu === "Faults" ||
                  selectedMenu === "Alarms"
                    ? true
                    : false
                }
              >
                Toggle View
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {open ? (
            <DialogModel
              dialogOpen={open}
              handleClose={handleClose}
              buttonText="Submit"
              heading="Add to Focus Area"
              handleSubmit={handleSubmit}
            >
              <form>
                <label className="label-text">Name:</label>
                <TextField
                  autoFocus
                  required
                  key="field"
                  placeholder="Enter Here.."
                  onChange={(e) => (fieldData = e && e.target.value)}
                />
                {errorMessage && (
                  <div>
                    <span className="error-message">{errorMessage}</span>
                  </div>
                )}
              </form>
            </DialogModel>
          ) : null}
          {generateCustomGraphReport()}
        </div>
        <SideBar
          ResetRightSideFilters={ResetRightSideFilters}
          selectedFilters={selectedFilters}
          handleMenuSelection={handleMenuSelection}
          filterOptions={uniqueFilters}
          selectedFilterForPopover={selectedFilters}
          isLoading={filterLoading}
          resetFlag={resetFlag}
        />
      </div>
    </>
  );
};
export default CustomGraph;
