const userRoleList = [
  {
    id: 1,
    value: "Admin user",
  },
  {
    id: 2,
    value: "General user",
  },
  {
    id: 3,
    value: "",
  },
];

export default userRoleList;
