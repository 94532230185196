import React from "react";
import { connect } from "react-redux";

import TabComponent from "./../../components/tabs/tabComponent";
import "./dashboard.scss";
import Config from "./../../config";
import FocusedArea from "./../../containers/FocusedArea";
import CustomGraph from "./../../containers/CustomGraph/CustomGraph.jsx";
import { DashboardService, AccessManagementService } from "../../services";

const dashboardTabData = {
  name: "Dashboard",
  value: "dashboard",
  selectedTabIndex: 0,
  isTab: true,
  tabs: [
    {
      name: "Focused Area",
      value: "focusedArea",
      component: FocusedArea,
    },
    {
      name: "Custom Graphs",
      value: "customGraphs",
      component: CustomGraph,
    },
  ],
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportdetails: Config.reportDetails.machineMetrics,
      selectedMenu: null,
      filters: [],
      savedGraphs: [],
      rightSideFilterData: {},
      rightSideFilterKparData: {},
      leftSideMenuData: {},
      tabData: dashboardTabData,
      selectedTabIndex: 0,
      savedGraphData: {
        id: "",
        name: "Select...",
        filters: "{}",
        reportType: "",
      },
      dropDownGraphs: [],
      selectedProductivity: {},
      seletedReliability: {},
      parentCustomers: [],
      getLeftMachineMetricForKPARCustomer: {},
    };
  }

  componentDidMount() {
    const EmailID = this.props.userdetails.idTokenClaims.preferred_username;
    DashboardService.getRightSideFilterData({
      parentCustomers: [""],
      loggedInUser: EmailID,
    }).then((res) => {
      this.setState({ rightSideFilterData: res.data });
    });

    DashboardService.postRightSideFilterKparData({
      parentCustomers: [""],
      loggedInUser: EmailID,
    }).then((res) => {
      this.setState({ rightSideFilterKparData: res.data });
    });

    AccessManagementService.getCustomer(EmailID).then((res) => {
      this.setState({ parentCustomers: res.data });
    });

    DashboardService.getLeftSideMenu().then((res) => {
      this.setState({ leftSideMenuData: res.data });
    });

    DashboardService.getLeftMachineMetricForKPARCustomer().then((res) => {
      this.setState({ getLeftMachineMetricForKPARCustomer: res.data });
    });
  }

  switchToCustom = (graphData, productivityFilters, ReliabilityFilters) => {
    this.setState({
      selectedTabIndex: 1,
      savedGraphData: graphData,
      selectedProductivity: productivityFilters,
      seletedReliability: ReliabilityFilters,
    });
  };

  switchToFocused = (graphData) => {
    this.setState({ selectedTabIndex: 0, savedGraphData: graphData });
  };

  handleTabChange = (tabIndex) => {
    this.setState({
      selectedTabIndex: tabIndex,
      selectedProductivity: {},
      seletedReliability: {},
      savedGraphData: {
        id: "",
        name: "Select...",
        filters: "{}",
        reportType: "",
      },
    });
  };

  render() {
    const {
      tabData,
      selectedTabIndex,
      savedGraphData,
      savedGraphs,
      leftSideMenuData,
      rightSideFilterData,
      selectedProductivity,
      seletedReliability,
      // parentCustomers,
      getLeftMachineMetricForKPARCustomer,
      rightSideFilterKparData,
    } = this.state;

    return (
      <>
        <TabComponent
          data={tabData}
          tabIndex={selectedTabIndex}
          handleTabChange={this.handleTabChange}
          componentData={{
            savedGraphs: savedGraphs,
            leftSideMenuData: leftSideMenuData,
            savedGraphData: savedGraphData,
            getLeftMachineMetricForKPARCustomer: getLeftMachineMetricForKPARCustomer,
            rightSideFilterKparData: rightSideFilterKparData,
            selectedProductivity: selectedProductivity,
            seletedReliability: seletedReliability,
            switchToFocused: this.switchToFocused,
            switchToCustom: this.switchToCustom,
            setSavedGraphs: (value) => {
              this.setState({ savedGraphs: value });
            },
            dropDownGraphs: this.state.dropDownGraphs,
            setdropDownGraphs: (value) => {
              this.setState({ dropDownGraphs: value });
            },
            parentCustomers: this.state.parentCustomers,
          }}
          rightSideFilterData={rightSideFilterData}
          handleNextFeatureSelection
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userdetails: state.userdetails,
});

export default connect(mapStateToProps)(Dashboard);
