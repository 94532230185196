import Config from "./../config";

export default class Utils {
  /**
   * Takes the sorted array and shifts all the hiphenated(-) values
   *  to the end.
   *
   */
  static createReportFilters(selectedFilters) {
    let filterObj = {};
    let filters = [];
    if (selectedFilters !== null) {
      Object.keys(selectedFilters).forEach((filterType) => {
        if (
          filterType !== "productivity" &&
          filterType !== "reliability" &&
          selectedFilters[filterType].length > 0
        ) {
          filterObj = this.createFilterObj(filterType, selectedFilters);

          filterObj && filters.push(filterObj);
        }
      });
    }

    return filters;
  }
  static createInspectionReportFilter = (selectedFilters) => {
    let filters = [];
    const { reportDetails } = Config;
    if (selectedFilters && selectedFilters.parentId) {
      let { filterObj } = reportDetails.parentCustomers;
      filterObj.values = selectedFilters.parentId.map(
        (ele) => ele && ele.toString()
      );
      filters.push(filterObj);
    }

    if (selectedFilters && selectedFilters.site) {
      let { filterObj } = reportDetails.inspectionCustomerSite;
      // filterObj.values = [...selectedFilters.site];
      filterObj.values = selectedFilters.site.map((ele) => +ele);
      filters.push(filterObj);
    }

    if (selectedFilters && selectedFilters.model) {
      let { filterObj } = reportDetails.inspectionProductModel;
      filterObj.values = [...selectedFilters.model];
      filters.push(filterObj);
    }

    if (selectedFilters && selectedFilters.serialNumber) {
      let { filterObj } = reportDetails.serialNumber;
      filterObj.values = [...selectedFilters.serialNumber];
      filters.push(filterObj);
    }
    if (selectedFilters && selectedFilters.inspectionDate) {
      let { filterObj } = reportDetails.inspectionDate;
      filterObj.values = selectedFilters.inspectionDate.map((ele) => +ele);
      filters.push(filterObj);
    }

    return filters;
  };
  static createMonthlyReportFilter = (selectedFilters) => {
    let filters = [];

    const { reportDetails } = Config;
    if (selectedFilters.parentId) {
      let { filterObj } = reportDetails.parentId;
      filterObj.values = [...selectedFilters.parentId];
      filters.push(filterObj);
    }

    if (selectedFilters.yearmonth) {
      let { filterObj } = reportDetails.months;
      filterObj.values = [Number(selectedFilters.yearmonth.toString())];
      filters.push(filterObj);
    }

    if (selectedFilters.model && selectedFilters.model.length > 0) {
      let { filterObj } = reportDetails.productModels;
      filterObj.values = selectedFilters.model;
      filters.push(filterObj);
    }

    if (selectedFilters.site) {
      let { filterObj } = reportDetails.site;
      filterObj.values = [selectedFilters.site.value];
      filters.push(filterObj);
    }
    return filters;
  };

  static createBenchMarkReportFilter = (selectedFilters) => {
    let filters = [];

    const { reportDetails } = Config;

    if (selectedFilters.parentId) {
      let { filterObj } = reportDetails.parentId;
      filterObj.values = [...selectedFilters.parentId];
      filters.push(filterObj);
    }

    if (selectedFilters.model && selectedFilters.model.length > 0) {
      let { filterObj } = reportDetails.productModels;
      filterObj.values = selectedFilters.model;
      filters.push(filterObj);
    }

    return filters;
  };

  static createRMcareReportFilter = (selectedFilters) => {
    let filters = [];

    const { reportDetails } = Config;
    if (selectedFilters.customerId) {
      let { filterObj } = reportDetails.contractParentId;
      filterObj.values = [...selectedFilters.customerId];
      filters.push(filterObj);
    }

    if (selectedFilters.serialNumber) {
      let { filterObj } = reportDetails.contractSerialNumber;
      filterObj.values = [...selectedFilters.serialNumber];
      filters.push(filterObj);
    }

    if (true) {
      let { filterObj } = reportDetails.contractDateFrom;
      filterObj.values = [selectedFilters.customDate];
      filters.push(filterObj);
    }

    if (selectedFilters.modelId) {
      let { filterObj } = reportDetails.contractModel;
      filterObj.values = [...selectedFilters.modelId];
      filters.push(filterObj);
    }

    if (selectedFilters.fleetId) {
      let { filterObj } = reportDetails.contractFleetId;
      filterObj.values = selectedFilters.fleetId.map((ele) => +ele);
      filters.push(filterObj);
    }

    return filters;
  };
  static createFilterObj(filterType, selectedFilters) {
    let filters = selectedFilters[filterType];
    const { reportDetails } = Config;
    let { filterObj } = reportDetails[filterType];
    if (filterObj !== undefined) {
      filterObj.values = filters.map((obj) => obj.filtername || obj.value);
      if (filterType === "customerSite") {
        filterObj.values = filters.map((obj) => obj.label);
      }
      if (filterType === "payload") {
        filterObj.values = filters.map((obj) =>
          Number(obj.filtername || obj.value)
        );
      }
    }

    return filterObj;
  }

  static isArray(o) {
    return Object.prototype.toString.call(o) === "[object Array]";
  }

  static filterSidebarData(
    siteIds,
    filteredData,
    customerSites,
    filterType,
    lenCusName
  ) {
    filteredData = {
      ...filteredData,

      productType: filteredData.productType.filter(
        (product) => siteIds.indexOf(product.siteId) >= 0
      ),
      productModel: filteredData.productModel.filter(
        (model) => siteIds.indexOf(model.siteId) >= 0
      ),
      serialNumber: filteredData.serialNumber.filter(
        (number) => siteIds.indexOf(number.siteId) >= 0
      ),
      customerIds: filteredData.customerIds.filter(
        (id) => siteIds.indexOf(id.siteId) >= 0
      ),
      komatsuRegion: filteredData.komatsuRegion.filter(
        (region) => siteIds.indexOf(region.siteId) >= 0
      ),
      commodity: filteredData.commodity.filter(
        (com) => siteIds.indexOf(com.siteId) >= 0
      ),
      payload: filteredData.payload.filter(
        (paload) => siteIds.indexOf(paload.siteId) >= 0
      ),
    };

    if (customerSites) {
      return (filteredData = {
        ...filteredData,
        customerSite:
          filterType === "customerSite" && lenCusName <= 0
            ? filteredData.customerSite
            : customerSites,
      });
    } else {
      return filteredData;
    }
  }

  static removeDuplicateObjects = (dataObj) => {
    //filtering duplicate from filteredData
    Object.keys(dataObj).forEach((key) => {
      if (key) {
        const sidebarData = dataObj[key];
        const uniqueData = sidebarData.filter(
          (name, ind) =>
            ind ===
            sidebarData.findIndex(
              (item) => item.value === name.value && item.label === name.label
            )
        );

        dataObj = {
          ...dataObj,
          [key]: uniqueData,
        };
      }
    });

    return dataObj;
  };

  static removeLeftSidebarDuplicate = (dataObj) => {
    //filtering duplicate from filteredData
    Object.keys(dataObj).forEach((key) => {
      if (key) {
        const leftSidebarData = dataObj[key];
        const uniqueData = leftSidebarData.filter(
          (name, ind) =>
            ind === leftSidebarData.findIndex((item) => item.id === name.id)
        );

        dataObj = {
          ...dataObj,
          [key]: uniqueData,
        };
      }
    });

    return dataObj;
  };
}
