import React from "react";

import DialogModel from "../dialogModel/dialogModel";

const NavigateModel = ({
  state,
  handleClose,
  handleConfirm,
  heading,
  message,
}) => {
  return (
    <div>
      <DialogModel
        dialogOpen={state}
        buttonText="Ok"
        handleClose={handleClose}
        heading={heading}
        showCancel={false}
        handleSubmit={() => handleConfirm()}
      >
        <form>
          <p>{message}</p>
        </form>
      </DialogModel>
    </div>
  );
};

export default NavigateModel;
