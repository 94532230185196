import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";

import { AccessManagementService } from "../../../../services";
import InputSelect from "../../../../components/input/inputSelect/inputSelect";
import InputSelectMulti from "../../../../components/input/inputSelect/inputSelectMulti";
import "../../accessmanagemnt.scss";
import NavigateModel from "../../../../components/model/navigateModel/navigateModel";
import ErrorSnackbar from "../../../../components/errorSnackbar/snackbar";

const apiDefinedRolesMap = [
  { value: 1, label: "Super Admin User" },
  { value: 2, label: "Admin User" },
  { value: 3, label: "General User" },
];

const CreateUserTab = ({
  emailValid,
  setEmailValid,
  currentName,
  setCurrentName,
  currentID,
  setUsersList,
  currentRole,
  selectedTabIndex,
  formReset,
  setTotalUser,
  existingCustomer,
  setExistingCustomer,
  AllRoles,
  getCustomerdata,
  userIDEdit,
  setUserIDEdit,
}) => {
  const [userID, setUserID] = useState("");
  const [isValidUser, setIsValidUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userRoles, setUserRole] = useState(0);
  const [navigate, setNavigate] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [duplicateUser, setDuplicateUser] = useState(false);
  const [emailValidMessage, setEmailValidMessage] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [checkEmail, setCheckEmail] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [newRoleCreateUser, setNewRoleCreateUser] = useState([]);

  const userdetails = useSelector((state) => state.userdetails);

  useEffect(() => {
    return () => {
      formReset();
      setIsValidUser("");
      setEmailValid("");
      setCheckEmail(true);
      setExistingCustomer([]);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (currentRole > 0) {
      setUserRole(apiDefinedRolesMap[currentRole - 1].value);
    } else {
      setUserRole(AllRoles && AllRoles[0] && AllRoles[0].value);
    }
    AccessManagementService.getDetailsForCreateUser(
      userdetails && userdetails.account && userdetails.account.username
    ).then((res) => {
      setNewRoleCreateUser(res.data.userRoles);
    });
  }, []);

  useEffect(() => {
    const selectedCustomersEdit =
      existingCustomer &&
      existingCustomer.map((ele) => {
        return ele.value;
      });
    setSelectedCustomer(selectedCustomersEdit);
  }, []);

  const createNewUser = () => {
    const param = {
      PageNumber: 1,
      PageSize: 5,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
    };
    const currentUserData = {
      email: currentID,
      role: userRoles,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
      customers: selectedCustomer,
      userId: userIDEdit,
    };
    const userData = {
      fullName: currentName ? currentName : null,
      email: userID,
      role: userRoles,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
      customers: selectedCustomer,
    };
    if (emailValid && !currentID) {
      setIsLoading(true);
      AccessManagementService.postUser({ ...userData })
        .then((response) => {
          if (response.data === `User ${currentName} is already exists`) {
            setDuplicateUser(true);
            return response.data;
          } else {
            setDuplicateUser(false);

            AccessManagementService.getUsersList(
              param.PageNumber,
              param.PageSize,
              param.loggedInUser
            )
              .then((res) => {
                setUsersList(res.data.users);
                setTotalUser(res.data.totalUser);
                setIsLoading(false);
                setAddUser(true);
                return response.data;
              })
              .catch(() => {
                setIsLoading(false);
                setServerError(true);
              });
          }
        })
        .catch(() => {
          setIsLoading(false);
          setServerError(true);
        });
    } else if (currentRole && currentID) {
      setIsLoading(true);
      AccessManagementService.putUser({ ...currentUserData })
        .then(() => {
          AccessManagementService.getUsersList(
            param.PageNumber,
            param.PageSize,
            param.loggedInUser
          )
            .then((res) => {
              setUsersList(res.data.users);
              setTotalUser(res.data.totalUser);
            })
            .catch(() => {
              setIsLoading(false);
              setServerError(true);
            });
          setIsLoading(false);
          setNavigate(true);
          setAddUser(false);
        })
        .catch(() => {
          setIsLoading(false);
          setServerError(true);
        });
    }
  };
  const checkValidUser = (userInfo) => {
    if (userInfo !== "") {
      setIsValidUser("");
      setIsLoading(true);
      setCheckEmail(true);
      AccessManagementService.getValidUserInfo(userInfo)
        .then((res) => {
          if (res.data === "EmailId is not valid") {
            setCheckEmail(false);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setCurrentName(res.data.fullName);
            setCheckEmail(true);
            setEmailValid(true);
            setEmailValidMessage(false);
          }
        })
        .catch((err) => {
          setIsValidUser("Not a valid user");
          setEmailValid(false);
          setEmailValidMessage(true);
          setIsLoading(false);
        });
    }
  };

  const handleConfirm = () => {
    selectedTabIndex(0);
  };

  const closeModel = () => {
    setDuplicateUser(false);
    setIsLoading(false);
  };

  const closeSnackerbar = () => {
    setServerError(false);
  };

  return (
    <>
      {isLoading && (
        <div className="create-user-loader">
          <CircularProgress disableShrink />
        </div>
      )}
      <div className="alluser_details">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            User Id <span className="mandatory-mark">*</span>
            <Input
              onBlur={(r) => {
                setUserID(r && r.currentTarget.value);
                checkValidUser(r && r.currentTarget.value);
              }}
              onChange={() => {
                setEmailValid(false);
                setEmailValidMessage(false);
                setCurrentName("");
                setCheckEmail(true);
              }}
              placeholder="Enter here…"
              defaultValue={currentID ? currentID : ""}
              disabled={currentID ? true : false}
            />
            {emailValidMessage ? (
              <span className="mandatory-mark">User Id does not exist.</span>
            ) : (
              ""
            )}
            {userID && checkEmail === false ? (
              <span className="mandatory-mark">User Id is not correct.</span>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={3}>
            Name
            <Input
              disabled
              placeholder="Enter here…"
              value={currentName ? currentName : ""}
              defaultValue={currentName ? currentName : ""}
            />
          </Grid>
          <Grid item xs={2}>
            Role<span className="mandatory-mark">*</span>
            <InputSelect
              data={newRoleCreateUser}
              placeholder="Select..."
              isDisabled={currentRole ? true : false}
              defaultValue={
                currentRole ? apiDefinedRolesMap[currentRole - 1] : ""
              }
              isSearchable
              onChange={(r) => {
                if (r && r.value === 1) {
                  setExistingCustomer(getCustomerdata);
                  const superadmincustomers =
                    getCustomerdata &&
                    getCustomerdata.map((ele) => {
                      return ele.value;
                    });
                  setSelectedCustomer(superadmincustomers);
                } else {
                  setExistingCustomer([]);
                  setSelectedCustomer([]);
                }
                setUserRole(r && r.value);
              }}
            />
          </Grid>
          <Grid
            className={
              userRoles === 1 ? "customerAlign disableCust" : "customerAlign"
            }
            item
            xs={2}
          >
            Customer
            {userRoles !== 3 ? <span className="mandatory-mark">*</span> : null}
            <InputSelectMulti
              data={getCustomerdata}
              onMenuOpen={true}
              isMulti
              data-test-id="Customers"
              isCheckInput={true}
              isSearchable
              value={existingCustomer}
              isLoading={getCustomerdata ? getCustomerdata : []}
              placeholder={
                selectedCustomer && selectedCustomer.length
                  ? `${selectedCustomer.length} Selected`
                  : existingCustomer && existingCustomer.length
                  ? `${existingCustomer.length} Selected`
                  : "Select..."
              }
              onChange={(metrics) => {
                const metricsGraph =
                  metrics &&
                  metrics.map((ele) => ({
                    value: ele.value,
                    label: ele.label,
                  }));

                setExistingCustomer(metricsGraph);
                const customervalue =
                  metrics &&
                  metrics.map((ele) => {
                    return ele.value;
                  });
                setSelectedCustomer(customervalue);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              className={`user_btn ${
                (emailValid === true &&
                  isValidUser === "" &&
                  ((userRoles === 3 && selectedCustomer.length === 0) ||
                    (userRoles === 3 && existingCustomer.length > 0))) ||
                (selectedCustomer && selectedCustomer.length > 0) ||
                existingCustomer.length > 0
                  ? ""
                  : "Mui-disabled addColorDisable"
              }`}
              onClick={createNewUser}
            >
              {currentID && currentName ? "Update User" : "Create User"}
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className="report_details">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6">Permission</Typography>
            <MenuItem>Create Watchlist </MenuItem>
            <MenuItem>Access Manuals</MenuItem>
            <MenuItem>Download Report </MenuItem>
            <MenuItem className={`${userRoles === 3 ? "crossmark" : ""}`}>
              Access management
            </MenuItem>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Access to Reports</Typography>
            <MenuItem>Monthly Operation Report</MenuItem>
            <MenuItem> Aftermarket Inspection Report</MenuItem>
            <MenuItem> Equipment Reliability Performance</MenuItem>
          </Grid>
        </Grid>

        {navigate ? (
          <NavigateModel
            state={navigate}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="User Details Updated Successfully."
          />
        ) : null}
        {addUser ? (
          <NavigateModel
            state={addUser}
            handleConfirm={handleConfirm}
            heading="User Detail"
            message="User Details added Successfully."
          />
        ) : null}

        {duplicateUser ? (
          <NavigateModel
            state={duplicateUser}
            handleConfirm={closeModel}
            heading="User Detail"
            message="User Detail Already Exist."
          />
        ) : null}

        {serverError ? (
          <ErrorSnackbar
            openSnackbar={serverError}
            errorType="error"
            closeSnackerbar={closeSnackerbar}
            message="Server Side Error"
          />
        ) : null}
      </div>
    </>
  );
};

export default CreateUserTab;
