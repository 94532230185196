import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "./watchlist.scss";
import AddWatchList from "./addWatchList/addWatchList";
import EditWatchList from "./editWatchlList/editWatchList";
import EditOrDeleteWatchList from "./editOrDeleteWatchList/editOrDeleteWatchList";
import { WatchListService, DashboardService } from "../../services";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "../../components/errorSnackbar/snackbar";

const Watchlist = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEluser, setAnchorEluser] = useState(null);
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [machineMetricData, setmachineMetricData] = useState([]);
  const [selectedLocalData, SetSelectedLocalData] = useState({});
  const [serverResponse, SetServerResponse] = useState({});
  const [watchListData, SetWatchListData] = useState([]);
  const [watchlistEditData, SetwatchlistEditData] = useState([]);
  const [editWatchListValidation, SetEditWatchListValidation] = useState({
    parameters: [],
  });
  const [loader, setLoader] = useState(false);
  const [cascadingLoader, setCascadingLoader] = useState(false);
  const [serverError, setServerError] = useState(false);
  const userdetails = useSelector((state) => state.userdetails);

  const refreshForm = {
    userid:
      userdetails &&
      userdetails.idTokenClaims &&
      userdetails.idTokenClaims.preferred_username,
    customer: "",
    site: "",
    model: "",
    parameters: [],
  };

  const [watchListValidtion, setWatchListvalidation] = useState({
    userid:
      userdetails &&
      userdetails.idTokenClaims &&
      userdetails.idTokenClaims.preferred_username,
    customer: "",
    site: "",
    model: "",
    parameters: [],
  });
  const [filterId, SetFilterId] = useState(null);

  const defaultValueSelect = {
    customer: [],
    site: [],
    model: [],
    serialnumber: [],
    parameters: [],
  };

  const [defaultValueReset, SetDefaultValueRest] = useState(defaultValueSelect);

  const getCustomers = () => {
    const fetchCustomer = {
      reportid: 1,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
    };
    WatchListService.getWatchListDropDown(fetchCustomer).then((res) => {
      setCustomerData(res.data);
      SetServerResponse(res.data);
    });
  };

  useEffect(() => {
    setLoader(true);
    setServerError(false);
    getCustomers();
    WatchListService.getWatchList({
      email:
        userdetails &&
        userdetails.idTokenClaims &&
        userdetails.idTokenClaims.preferred_username,
    })
      .then((res) => {
        SetWatchListData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setServerError(true);
      });

    DashboardService.getLeftSideMenu().then((res) => {
      const machineMetrice = [];
      Object.keys(res.data.machineMetrics).forEach((key) =>
        machineMetrice.push({
          value: res.data.machineMetrics[key].id.toString(),
          label: res.data.machineMetrics[key].label.toUpperCase(),
        })
      );
      setmachineMetricData(machineMetrice);
    });
  }, []);

  const updateSelectedFeild = (name, value) => {
    setWatchListvalidation({ ...watchListValidtion, [name]: value });
  };

  const addWatchList = () => {
    setLoader(true);
    setServerError(false);
    WatchListService.addWatchListCard(watchListValidtion)
      .then((res) => {
        WatchListService.getWatchList({
          email:
            userdetails &&
            userdetails.idTokenClaims &&
            userdetails.idTokenClaims.preferred_username,
        })
          .then((response) => {
            SetWatchListData(response.data);
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            setServerError(true);
          });
      })
      .catch((err) => {
        setLoader(false);
        setServerError(true);
      });
  };

  const handleSubmit = () => {
    if (
      defaultValueReset.customer.length &&
      defaultValueReset.site.length &&
      defaultValueReset.model.length &&
      watchListValidtion.parameters.length &&
      watchListValidtion.parameters.length <= 3
    ) {
      addWatchList();
      setAnchorEl(null);
      setAnchorEluser(null);
      setWatchListvalidation(refreshForm);
      SetServerResponse({});
      SetSelectedLocalData({});
      SetDefaultValueRest(defaultValueSelect);
    }
    setSubmitted(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEluser(null);
    setAnchorElEdit(null);
    setSubmitted(false);
    setWatchListvalidation(refreshForm);
    SetSelectedLocalData({});
    SetDefaultValueRest(defaultValueSelect);
    SetServerResponse({});
  };

  const handleClick = (event) => {
    setAnchorEl(event && event.currentTarget);
    setSubmitted(false);
  };

  const handleClickuser = (event, data, filterIdValue) => {
    setAnchorEluser(event && event.currentTarget);
    SetwatchlistEditData(data);
    SetFilterId(filterIdValue);
  };

  const handleClickEditUser = (event) => {
    setAnchorElEdit(event && event.currentTarget);
  };

  const selectListAjaxcall = (value) => {
    SetServerResponse({
      sites: [],
      models: [],
    });
    SetSelectedLocalData(value);
    setCascadingLoader(true);

    WatchListService.getWatchListDropDown({
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
      ...value,
    })
      .then((res) => {
        SetServerResponse(res.data);
        setCascadingLoader(false);
      })
      .catch((err) => {
        setCascadingLoader(false);
        setServerError(true);
      });
  };

  const deleteWatchlistCard = (id) => {
    setLoader(true);
    setServerError(false);
    WatchListService.deleteWatchList(id)
      .then(() => {
        WatchListService.getWatchList({
          email:
            userdetails &&
            userdetails.idTokenClaims &&
            userdetails.idTokenClaims.preferred_username,
        })
          .then((response) => {
            SetWatchListData(response.data);
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            setServerError(true);
          });
      })
      .catch((err) => {
        setLoader(false);
        setServerError(true);
      });
  };

  const closeSnackerbar = () => {
    setServerError(false);
  };

  return (
    <>
      <div className="content watchlist_cnt">
        {loader ? (
          <div className="progressBaralignment">
            <CircularProgress />
          </div>
        ) : null}

        {serverError ? (
          <Snackbar
            openSnackbar={serverError}
            errorType="error"
            closeSnackerbar={closeSnackerbar}
            message="Server Side Error"
          />
        ) : null}

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <AddWatchList
              defaultValueReset={defaultValueReset}
              SetDefaultValueRest={SetDefaultValueRest}
              selectedLocalData={selectedLocalData}
              SetSelectedLocalData={SetSelectedLocalData}
              customerData={customerData}
              machineMetricData={machineMetricData}
              selectListAjaxcall={selectListAjaxcall}
              handleClose={handleClose}
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleSubmit={handleSubmit}
              updateSelectedFeild={updateSelectedFeild}
              submitted={submitted}
              watchListValidtion={watchListValidtion}
              serverResponse={serverResponse}
              cascadingLoader={cascadingLoader}
            />
          </Grid>
          <EditWatchList
            SetEditWatchListValidation={SetEditWatchListValidation}
            handleClose={handleClose}
            handleClickEditUser={handleClickEditUser}
            anchorEl={anchorElEdit}
            handleSubmit={handleSubmit}
            updateSelectedFeild={updateSelectedFeild}
            submitted={submitted}
            watchListValidtion={watchListValidtion}
            watchlistEditData={watchlistEditData}
            machineMetricData={machineMetricData}
            editWatchListValidation={editWatchListValidation}
          />
          {watchListData &&
            watchListData.map((watchList, index) => {
              return (
                <Grid item xs={3}>
                  <div className="watchlist-cnt">
                    <div className="site_txt">
                      <span>Customer / Site name</span>
                      {`${watchList.parentCustomer}/${watchList.site}`}
                      <span>Product Model </span>
                      {watchList && watchList.baseModel}
                      <span>Month - Year</span>{" "}
                      {watchList.monthYear ? watchList.monthYear : "NA"}
                    </div>
                    <EditOrDeleteWatchList
                      deleteWatchlistCard={deleteWatchlistCard}
                      deleteWatchList={filterId}
                      filterId={watchList.filterId}
                      SetEditWatchListValidation={SetEditWatchListValidation}
                      editWatchlistDataSetter={watchListData[index]}
                      handleClickuser={handleClickuser}
                      anchorEl={anchorEluser}
                      handleClose={handleClose}
                      handleClick={handleClose}
                      handleClickEditUser={handleClickEditUser}
                      SetwatchlistEditData={SetwatchlistEditData}
                    />
                  </div>
                  <div className="watchlist-cnt trading_num">
                    {watchList &&
                      watchList.jsonMetrics.map((card) => {
                        return (
                          <div className="percent_txt">
                            <ul>
                              <li>{card.value}</li>
                              <li>
                                {card.label === "EFFECTIVEUTILIZATION"
                                  ? "EFFECTIVE UTILIZATION"
                                  : card.label}
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </>
  );
};

export default Watchlist;
