var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_SSO_MSAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_SSO_TENANT_ID}`,
        redirectUri: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port + '/' : ''),
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: isIE11 ? true : false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
const tokenRequest = {
    scopes: ["User.Read", "Mail.Read"]
};