const MonthlyFilters = (
  reportid,
  getSingleSelect,
  getMultiSelect,
  siteNameForModel = ""
) => {
  return [
    {
      labelName: "Customer",
      isRequired: true,
      defalutValueData: reportid.monthlycustomers,
      reportValue: "parentId",
      selectType: "single",
    },
    {
      labelName: "Site",
      isRequired: true,
      defalutValueData: reportid.sites,
      reportValue: "site",
      selectType: "multi",
    },
    {
      labelName: "Month",
      isRequired: true,
      defalutValueData: reportid.months,
      reportValue: "yearmonth",
      selectType: "single",
    },
    {
      labelName: siteNameForModel ? `Model for ${siteNameForModel}` : "Model",
      isRequired: true,
      defalutValueData: reportid.model,
      reportValue: "model",
      selectType: "multi",
    },
  ].map((res) => {
    if (res.selectType === "single") {
      return getSingleSelect(res);
    } else if (res.selectType === "multi") {
      return getMultiSelect(res);
    }
    return false;
  });
};
export default MonthlyFilters;
