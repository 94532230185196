import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../components/Layout/Layout";
import userRoleList from "../utils/redirectionconfig/config";
import { useContainerContext } from "../utils/contextapi/ContainerContext";
import NoaccessPage from "../pages/wildCardPage/noAccess/NoAccessPage";

export const PrivateRoutes = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  const context = useContainerContext();
  const { userRole } = context.state;
  const { admin } = context.actions;

  useEffect(() => {
    admin();
  }, []);

  if (!rest.userdetails.accessToken) {
    return (
      <div style={{ position: "absolute", left: "50%", top: "50%" }}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      component={(props) =>
        userRoleList.find((e) => e.value === userRole) ? (
          <>
            <Layout>
              <Component {...props} />
            </Layout>
          </>
        ) : (
          <>
            <Redirect
              to={{ pathname: "/noaccess" }}
              component={<NoaccessPage />}
            />
            )
          </>
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid,
  userdetails: state.userdetails,
});

export default connect(mapStateToProps)(PrivateRoutes);
