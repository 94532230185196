import Services from "./services";
import Axios from "./axios";

/**
 * reportService
 */
export default class WatchListService extends Services {
  axiosInstance = new Axios(`${this.apiDomain}${this.endpointPrefix}/`);

  /**
   * Get report filter
   *
   * @returns {*}
   */
  getWatchListDropDown = (watchListSelectInputs) => {
    return this.axiosInstance.post(
      "/GetWatchList/GetWatchListFilters",
      watchListSelectInputs,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  getWatchList = (filterInput) => {
    return this.axiosInstance.post("/GetWatchList/GetWatchList", filterInput, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  deleteWatchList = (id) => {
    return this.axiosInstance.delete(
      `/GetWatchList/DeleteWatchList?watchListId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  addWatchListCard = (filterInput) => {
    return this.axiosInstance.post("/GetWatchList/AddWatchList", filterInput, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };
}
