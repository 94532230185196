import React, { useEffect, useState } from "react";

import { AccessManagementService } from "../../services";
import "./accessmanagemnt.scss";
import TabComponent from "../../components/tabs/tabComponent";
import CreateUserTab from "./tabs/createUserTab/createUserTab";
import AllUserTab from "./tabs/allUserTab/allUserTab";
import { useSelector } from "react-redux";

const accessManagementTabData = {
  name: "AccessManagement",
  value: "accessManagement",
  selectedTabIndex: 0,
  isTab: true,
  tabs: [
    {
      name: "All Users",
      value: "allUsers",
      component: AllUserTab,
    },
    {
      name: "Create User",
      value: "customGraphs",
      component: CreateUserTab,
    },
  ],
};

export default function SimpleTabs() {
  const [emailValid, setEmailValid] = useState();
  const [currentName, setCurrentName] = useState("");
  const [currentID, setCurrentId] = useState();
  const [usersList, setUsersList] = useState([]);
  const [currentRole, setCurrentRole] = useState(0);
  const [totalUser, setTotalUser] = useState(5);
  const [page, setPage] = useState(1);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [existingCustomer, setExistingCustomer] = useState([]);

  const [AllRoles, setSelectedRoles] = useState([]);
  const [getCustomerdata, setCustomerData] = useState([]);
  const [userIDEdit, setUserIDEdit] = useState(0);

  const tabData = accessManagementTabData;
  const userdetails = useSelector((state) => state.userdetails);

  const formReset = () => {
    setCurrentName("");
    setCurrentId("");
    setCurrentRole("");
    setEmailValid("");
  };
  useEffect(() => {
    let param = {
      PageNumber: 1,
      PageSize: 5,
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
    };
    AccessManagementService.getUsersList(
      param.PageNumber,
      param.PageSize,
      param.loggedInUser
    ).then((res) => {
      setUsersList(res.data.users);
      setUserIDEdit(res.data.users);
      setTotalUser(res.data.totalUser);
    });

    AccessManagementService.getRoles(
      userdetails && userdetails.account && userdetails.account.username
    ).then((res) => {
      setSelectedRoles(res.data);
    });
    AccessManagementService.getCustomer(
      userdetails && userdetails.account && userdetails.account.username
    ).then((res) => {
      setCustomerData(res.data);
    });
  }, []);

  const handleTabChange = (tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };
  return (
    <>
      <div className="access_managemnet">
        <TabComponent
          data={tabData}
          tabIndex={selectedTabIndex}
          handleTabChange={handleTabChange}
          componentData={{
            formReset,
            selectedTabIndex: setSelectedTabIndex,
            totalUser: totalUser,
            setTotalUser: (value) => {
              setTotalUser(value);
            },
            emailValid: emailValid,
            setEmailValid: (value) => {
              setEmailValid(value);
            },
            currentName: currentName,
            setCurrentName: (value) => {
              setCurrentName(value);
            },
            currentID: currentID,
            setCurrentId: (value) => {
              setCurrentId(value);
            },
            page,
            setPage,
            usersList: usersList,
            setUsersList: (value) => {
              setUsersList(value);
            },
            currentRole: currentRole,
            setCurrentRole: (value) => {
              setCurrentRole(value);
            },
            existingCustomer,
            setExistingCustomer,
            AllRoles,
            setSelectedRoles,
            getCustomerdata,
            setCustomerData,
            userIDEdit,
            setUserIDEdit,
          }}
        />
      </div>
    </>
  );
}
