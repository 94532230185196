import React from "react";
import InputSelectMulti from "../../../components/input/inputSelect/inputSelectMulti";
let triggerOnBlur = false;

const GetMultiSelect = ({
  reportData,
  sitesDetails,
  dropDownApiInput,
  dropDownApiData,
  setSitesDetails,
  handleReportChange,
  setErrorMessage,
  validateError,
}) => {
  return (
    <div
      className="filter_cnt"
      style={reportData.reportValue === "fleetId" ? { width: "25%" } : {}}
    >
      <label>
        {reportData.labelName}
        {reportData.isRequired && <span className="mandatory-mark">*</span>}
      </label>
      <InputSelectMulti
        defaultValue={reportData.defalutValueData || []}
        data={reportData.defalutValueData || []}
        isMulti
        value={
          sitesDetails && sitesDetails[reportData.reportValue]
            ? sitesDetails[reportData.reportValue]
            : null
        }
        sitesDetails={sitesDetails}
        reportValue={reportData.reportValue}
        isCheckInput={true}
        selectAllOptions={reportData && reportData.selectAllOptions}
        isLoading={
          reportData &&
          reportData.defalutValueData &&
          reportData.defalutValueData.length !== 0
            ? false
            : true
        }
        placeholder={
          sitesDetails &&
          sitesDetails[reportData.reportValue] &&
          sitesDetails[reportData.reportValue].length
            ? `${sitesDetails[reportData.reportValue].length} selected`
            : "Select..."
        }
        isSearchable
        onBlur={() => {
          if (triggerOnBlur) {
            dropDownApiData(reportData.reportValue, dropDownApiInput);
            triggerOnBlur = false;
          }
        }}
        onChange={(sites) => {
          triggerOnBlur = true;
          const sitesData =
            sites &&
            sites.map((ele) => {
              return ele.value;
            });
          const siteGraph =
            sites &&
            sites.map((ele) => ({
              label: ele.label,
              value: ele.value,
            }));
          setSitesDetails({
            ...sitesDetails,
            [reportData.reportValue]: siteGraph,
          });
          handleReportChange(sitesData, reportData.reportValue, true);
          setErrorMessage((prev) => ({
            ...prev,
            [reportData.reportValue]: sites,
          }));
        }}
      />
      {validateError(
        reportData.isRequired,
        reportData.selectType,
        reportData.reportValue
      ) && (
        <span className="error-message">
          Please select any {reportData.labelName}
        </span>
      )}
    </div>
  );
};
export default GetMultiSelect;
