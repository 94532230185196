import React from "react";
import InputSelect from "../../../components/input/inputSelect/inputSelect";

const GetSingleSelect = ({
  reportData,
  errorMessage,
  sitesDetails,
  setSitesDetails,
  handleReportChange,
  setErrorMessage,
  validateError,
  DateRestFuntion,
}) => {
  return (
    <div className="filter_cnt">
      <label>
        {reportData.labelName}
        {reportData.isRequired && <span className="mandatory-mark">*</span>}
      </label>
      <InputSelect
        defaultValue={reportData.defalutValueData || []}
        data={reportData.defalutValueData || []}
        isSearchable
        value={
          sitesDetails && sitesDetails[reportData.reportValue]
            ? sitesDetails[reportData.reportValue]
            : null
        }
        isLoading={
          reportData &&
          reportData.defalutValueData &&
          reportData.defalutValueData.length !== 0
            ? false
            : true
        }
        placeholder="Select..."
        onChange={(r) => {
          setSitesDetails({ ...sitesDetails, [reportData.reportValue]: r });
          handleReportChange(r && [r.value], reportData.reportValue);
          setErrorMessage((prev) => ({
            ...prev,
            [reportData.reportValue]: r && r.label,
          }));
          DateRestFuntion(false);
        }}
      />
      {validateError(
        reportData.isRequired,
        reportData.selectType,
        reportData.reportValue
      ) && (
        <span className="error-message">
          Please select any {reportData.labelName}
        </span>
      )}
    </div>
  );
};
export default GetSingleSelect;
