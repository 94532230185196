import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { Button, Typography } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";

import "../popover/popoverComponent.scss";

const PopoverReliabilityComponent = ({
  popoverData,
  typographyLabel,
  buttonEvent,
  handleCloseButton,
  handleSelection,
  selectedFilterForPopover,
  selectedMenu,
  reportType,
  setReliability,
  reliabilityEnable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMcnMetrics, setSelectedMcMetrics] = useState(reportType);
  useEffect(() => {
    setAnchorEl(buttonEvent);
  }, [buttonEvent, selectedFilterForPopover, reportType, selectedMenu]);

  const handleClose = () => {
    setAnchorEl(null);
    handleCloseButton();
  };
  const handleChangeMultiple = (value) => {
    setSelectedMcMetrics(value);
    handleSelection(value);
  };

  const open = Boolean(anchorEl);
  const id = open ? typographyLabel : undefined;

  function getStyles(name, selectedMcnMetricsstyles, theme) {
    if (selectedMcnMetricsstyles === name) {
      return theme;
    } else {
      return "";
    }
  }

  return (
    <div>
      {reliabilityEnable ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Typography className="machine_metrics">{typographyLabel}</Typography>
          <Scrollbars
            className="custom_scroll removeMinus"
            autoHeight
            autoHeightMin={0}
            autoHeightMax={500}
          >
            {popoverData.map((name) => {
              return (
                <Button
                  onClick={(e) => handleChangeMultiple(name)}
                  key={name.id}
                  className={
                    setReliability && setReliability.id === name.id
                      ? "current"
                      : getStyles(name, selectedMcnMetrics, "current")
                  }
                >
                  {name.label}{" "}
                </Button>
              );
            })}
          </Scrollbars>
        </Popover>
      ) : null}
    </div>
  );
};

PopoverReliabilityComponent.prototype = {
  popoverData: PropTypes.array,
  typographyLabel: PropTypes.string,
  buttonImage: PropTypes.string,
};

PopoverReliabilityComponent.defaultProps = {
  popoverData: [],
  typographyLabel: "",
};

export default PopoverReliabilityComponent;
