import React from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

import ErrorPage from "../../../images/Error page.svg";
import KomatsuLogo from "./../../../images/KomatsuLogo.svg";

import "./404.scss";

const NotFoundPage = () => {
  const history = useHistory();

  const navigateToHomepage = () => {
    history.push("/dashboard");
  };
  return (
    <>
      <div className="wildCard">
        <div className="main-logo">
          <img src={KomatsuLogo} alt="logo" width="300" />
        </div>
        <img src={ErrorPage} alt="error page" width="750" />
        <div className="error-text">
          <Typography variant="h4">Page Not Found.</Typography>
        </div>
        <p className="font-text">
          The page you want to view is unavailable right now.
        </p>
        <p className="font-text">
          <a  href onClick={navigateToHomepage} className="navigate-homepage">
            Click here{" "}
          </a>
          to go to HomePage
        </p>
      </div>
    </>
  );
};

export { NotFoundPage as default };
