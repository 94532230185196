import React, { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";

import PowerBI from "../../../components/PowerBI";
import { DashboardService } from "../../../services";
import Config from "../../../config";
import "../report.scss";
import Utils from "../../../utils";

const InspectionReport = ({ savedGraphData, handlePdfPPt, children }) => {
  const {
    reportDetails: {
      inspectionReport: { reportId, groupId, embedId, datasetId, pageDetails },
    },
  } = Config;

  const [state] = useState({
    reportId: reportId,
    groupId: groupId,
    embedId: embedId,
    datasetId: datasetId,
    pageDetails: pageDetails,
  });

  const [imagesReport] = useState({
    reportIdImages: process.env.REACT_APP_REPORTS_INSPECTION_PAGE_IMAGES,
  });

  const [accessToken, setAccessToken] = useState(null);
  const [accessTokenImages, setAccessTokenImages] = useState(null);
  const [customeExpand, setcustomeExpand] = useState("conditionOverview");

  useEffect(() => {
    const fetchNSetToken = async () => {
      const { datasetId, reportId } = state;
      const { reportIdImages } = imagesReport;
      DashboardService.getPowerBiToken(datasetId, reportId).then((res) => {
        setAccessToken(res.data.token);
      });
      DashboardService.getPowerBiToken(datasetId, reportIdImages).then(
        (res) => {
          setAccessTokenImages(res.data.token);
        }
      );
    };
    fetchNSetToken();
  }, [state]);

  const accordianDetails = {
    conditionOverview: "Condition Overview",
    tracks: "Tracks",
    sprockets: "Sprockets (Oversized)",
    rollers: "Rollers",
    componentLife: "Component Life",
    tracksImages: "Tracks Images",
    rollersImages: "Rollers Images",
    sprocketsImages: "Sprockets Images",
  };

  const renderReport = (selectedReport) => {
    let filters = Utils.createInspectionReportFilter(savedGraphData);
    return accessToken !== null ? (
      <PowerBI
        reportId={state.reportId}
        groupId={state.groupId}
        embedId={state.reportId}
        datasetId={state.datasetId}
        selectedFilters={filters}
        tokenType="Embed"
        accessToken={accessToken}
        pageName={state.pageDetails[selectedReport]}
      />
    ) : (
      <div className="report-graph-loader">
        <CircularProgress disableShrink />
      </div>
    );
  };

  const renderReportImages = (selectedReport) => {
    const filters = Utils.createInspectionReportFilter(savedGraphData);
    return accessTokenImages !== null ? (
      <PowerBI
        reportId={imagesReport.reportIdImages}
        groupId={state.groupId}
        embedId={imagesReport.reportIdImages}
        datasetId={state.datasetId}
        selectedFilters={filters}
        tokenType="Embed"
        accessToken={accessTokenImages}
        pageName={state.pageDetails[selectedReport]}
      />
    ) : (
      <div className="report-graph-loader">
        <CircularProgress disableShrink />
      </div>
    );
  };

  return (
    <div className="inspection_report_cnt">
      <div className="inspection_report_heading">
        <Typography variant="h6">Undercarriage Inspection Report</Typography>
        {children}
      </div>
      <div className="inspection_accordion">
        <div>
          {Object.keys(accordianDetails).map((item, index) => {
            return (
              <span
                className={
                  customeExpand === item
                    ? "inspection_Tabs"
                    : "inspection_TabsNotSeleted"
                }
                onClick={() => {
                  setcustomeExpand(item);
                }}
              >
                {accordianDetails[item]}
              </span>
            );
          })}
        </div>

        <div>
          {Object.keys(accordianDetails).map((item, index) => {
            return (
              <div
                className={
                  customeExpand === item ? "displayItem" : "displayItemNone"
                }
              >
                {item === "conditionOverview" ||
                item === "tracks" ||
                item === "sprockets" ||
                item === "rollers" ||
                item === "componentLife" ? (
                  <div>{renderReport(item)}</div>
                ) : null}

                {item === "tracksImages" ||
                item === "rollersImages" ||
                item === "sprocketsImages" ? (
                  <div>{renderReportImages(item)}</div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(InspectionReport);
