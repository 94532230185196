import React from "react";

const initialState = { userRole: "Admin user", selectedMcnMatrix: [] };

const reducer = (state, action) => {
  switch (action.type) {
    case "Admin user":
      return { selectedMcnMatrix: [...state.selectedMcnMatrix], userRole: 'Admin user' };
    case "General user":
      return { selectedMcnMatrix: [...state.selectedMcnMatrix], userRole: 'General user' };
    case "Staff":
      return { selectedMcnMatrix: [...state.selectedMcnMatrix], userRole: 'Staff' };
    case "UpdateMcnmatrix":
      return { selectedMcnMatrix: [action.payload], userRole: state.userRole };
    default:
      throw new Error();
  }
};

const general = () => ({ type: "General user" });
const admin = () => ({ type: "Admin user" });
const staff = () => ({ type: "Staff" });
const updateMcnMatrix = (payload) => ({ type: "UpdateMcnmatrix", payload: payload });

const actions = dispatch => ({
  general: () => dispatch(general()),
  admin: () => dispatch(admin()),
  staff: () => dispatch(staff()),
  updateMcnMatrix: (payload) => dispatch(updateMcnMatrix(payload))
});

const ContainerContext = React.createContext(initialState);

export const ContainerContextProvider = props => {
  const [reducerState, dispatch] = React.useReducer(reducer, initialState);
  const reducerActions = actions(dispatch);
  const context = {
    state: { ...reducerState },
    actions: { ...reducerActions }
  };

  return (
    <ContainerContext.Provider value={context}>
      {props.children}
    </ContainerContext.Provider>
  );
};

export const useContainerContext = () => React.useContext(ContainerContext);
