import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./inputDatePicker.scss";

const InputDatePicker = ({
  handleDatePicker,
  reportValue,
  errorMessage,
  setErrorMessage,
  reportid,
}) => {
  const [startDate, setStartDate] = useState();

  const handleDateChange = (date) => {
    const dateValue =
      date &&
      date.getFullYear() +
        "" +
        ("0" + (date && date.getMonth() + 1)).slice(-2) +
        "" +
        ("0" + date.getDate()).slice(-2);
    handleDatePicker(+dateValue, reportValue);
    setErrorMessage({ ...errorMessage, [reportValue]: [+dateValue] });
    setStartDate(date);
  };
  return (
    <DatePicker
      placeholderText="Select..."
      selected={startDate}
      onChange={(date) => handleDateChange(date)}
      maxDate={new Date()}
    />
  );
};

export default InputDatePicker;
