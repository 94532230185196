import Config from "../config";
import Dashboardservice from "./dashboardService";
import Reportservice from "./reportService";
import AccessmanagementService from "./accessManagementService";
import WatchListservice from "./watchListService";

export const DashboardService = new Dashboardservice(
  Config.API_DOMAIN,
  Config.ENDPOINT_PREFIX_DASHBOARD
);
export const ReportService = new Reportservice(
  Config.API_DOMAIN,
  Config.ENDPOINT_PREFIX_DASHBOARD
);
export const AccessManagementService = new AccessmanagementService(
  Config.API_DOMAIN,
  Config.ENDPOINT_PREFIX_DASHBOARD
);
export const WatchListService = new WatchListservice(
  Config.API_DOMAIN,
  Config.ENDPOINT_PREFIX_DASHBOARD
);
