import React from "react";
import "../watchlist.scss";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputSelectMulti from "../../../components/input/inputSelect/inputSelectMulti";

const EditWatchList = ({
  handleClose,
  anchorEl,
  handleSubmit,
  updateSelectedFeild,
  watchlistEditData,
  machineMetricData,
  editWatchListValidation,
  SetEditWatchListValidation,
}) => {
  return (
    <>
      <Menu
        id="wishlist-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl ? true : false}
        onClose={() => handleClose()}
      >
        <div className="watchlist_select">
          <div className="select_txt">Customer</div>
          <label>{watchlistEditData && watchlistEditData.customer}</label>
        </div>

        <div className="watchlist_select">
          <div className="select_txt"> Site</div>
          <label>{watchlistEditData && watchlistEditData.site}</label>
        </div>

        <div className="watchlist_select">
          <div className="select_txt">Fleet / Machine type</div>
          <label>{watchlistEditData && watchlistEditData.fleet}</label>
        </div>

        <div className="watchlist_select">
          <div className="select_txt">Machine ID</div>
          <label>{watchlistEditData && watchlistEditData.machineId}</label>
        </div>

        <div className="watchlist_select">
          <div className="select_txt">Parameters</div>
          <InputSelectMulti
            value={
              editWatchListValidation && editWatchListValidation.parameters
            }
            isCheckInput={true}
            data={machineMetricData}
            placeholder="Search or Select here…"
            isSearchable
            isMulti
            onChange={(metrics) => {
              updateSelectedFeild("parameters", metrics);
              const metricsGraph =
                metrics &&
                metrics.map((ele) => ({
                  value: +ele.value,
                  label: ele.label,
                }));
              SetEditWatchListValidation({
                parameters: metricsGraph,
              });
            }}
          />
        </div>
        <MenuItem value="20" >
          <button className="apply_btn" onClick={() => handleSubmit()}>
            Add
          </button>
          <button className="cancel_btn" onClick={() => handleClose()}>
            Cancel
          </button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditWatchList;
