import React, { useState, useEffect } from "react";
import { MenuItem, MenuList, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import menuList from "../../utils/navbar/constants";
import "./Navbar.scss";

const Navbar = () => {
  const userdetails = useSelector((state) => state.userdetails);

  const [navbarData, setNavbarData] = useState(false);

  useEffect(() => {
    const customerLength =
      userdetails &&
      userdetails.usercustomerData &&
      userdetails.usercustomerData.customers &&
      userdetails.usercustomerData.customers.length;

    if (customerLength > 0) {
      setNavbarData(true);
    } else {
      setNavbarData(false);
    }
  }, []);

  const getNavbarData = (name) => {
    const isAllowedRole =
      name.allowedRole.indexOf(
        userdetails &&
          userdetails.secondLevelAuth &&
          userdetails.secondLevelAuth.roleId
      ) > -1;
    const dataLabel = () => {
      if (navbarData) {
        return isAllowedRole ? name.display : "";
      } else if (isAllowedRole) {
        return name.display === "Watchlist" ? null : name.display;
      }
      return "";
    };
    return (
      <NavLink to={name.url} className="nav_link">
        {dataLabel()}
      </NavLink>
    );
  };

  const generateMenubar = () => {
    return (
      <div className="navigation">
        {menuList.map((name) => (
          <MenuList key={name.id}>
            <MenuItem key={name.id}>
              <Typography variant="inherit" noWrap>
                {getNavbarData(name)}
              </Typography>
            </MenuItem>
          </MenuList>
        ))}
      </div>
    );
  };

  return <div className="App">{generateMenubar()}</div>;
};

export default Navbar;
