import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputSelect from "../../../components/input/inputSelect/inputSelect";
import InputSelectMulti from "../../../components/input/inputSelect/inputSelectMulti";
import "../watchlist.scss";

const AddWatchList = ({
  selectListAjaxcall,
  handleClose,
  handleClick,
  anchorEl,
  handleSubmit,
  updateSelectedFeild,
  submitted,
  watchListValidtion,
  customerData,
  machineMetricData,
  SetSelectedLocalData,
  serverResponse,
  selectedLocalData,
  SetDefaultValueRest,
  defaultValueReset,
  cascadingLoader,
}) => {
  const [clickPara, SetClickPara] = useState(false);
  return (
    <>
      <Button
        aria-controls="wishlist-menu"
        aria-haspopup="true"
        onClick={(event) => handleClick(event)}
        className="add_watchlist_btn"
      >
        <span>+</span>
        Add to Watchlist
      </Button>
      {anchorEl ? (
        <Menu
          id="wishlist-menu"
          anchorEl={anchorEl}
          keepMounted
          open={anchorEl ? true : false}
          onClose={() => handleClose()}
        >
          <div
            className="watchlist_select"
            onClick={() => {
              SetClickPara(false);
            }}
          >
            {cascadingLoader ? (
              <div className="progressBaralignment">
                <CircularProgress />
              </div>
            ) : null}
            <div className="select_txt">
              Customer <span className="mandatory-mark">*</span>
            </div>
            <InputSelect
              data={customerData.customers}
              isLoading={
                customerData &&
                customerData.customers &&
                customerData.customers.length !== 0
                  ? false
                  : true
              }
              placeholder="Select here…"
              onChange={(r) => {
                const serverInput = { reportid: 1, parentId: [r && r.value] };
                const defaultVaRest = {
                  customer: [{ value: r && r.value, label: r && r.label }],
                  site: [],
                  model: [],
                  serialnumber: [],
                  parameters: [],
                };
                SetDefaultValueRest(defaultVaRest);
                updateSelectedFeild("customer", r && r.label);
                selectListAjaxcall(serverInput);
              }}
            />
            {submitted &&
              defaultValueReset &&
              defaultValueReset.customer.length === 0 && (
                <span className="error-message">
                  Please Select Any Customer
                </span>
              )}
          </div>

          <div
            className="watchlist_select"
            onClick={() => {
              SetClickPara(false);
            }}
          >
            <div className="select_txt">
              {" "}
              Customer Site <span className="mandatory-mark">*</span>
            </div>
            <div className="watchlist-customer">
              <InputSelect
                defaultValue={serverResponse.sites}
                data={
                  serverResponse && serverResponse.sites === null
                    ? []
                    : serverResponse.sites
                }
                value={serverResponse.sites ? defaultValueReset.site : null}
                isLoading={
                  serverResponse &&
                  serverResponse.sites &&
                  serverResponse.sites.length !== 0
                    ? false
                    : true
                }
                placeholder="Select here…"
                onChange={(r) => {
                  const serverInput = {
                    reportid: 1,
                    parentId: selectedLocalData.parentId,
                    site: [r && r.label],
                  };
                  const defaultVaRest = {
                    customer: defaultValueReset && defaultValueReset.customer,
                    site: [{ value: r && r.value, label: r && r.label }],
                    model: [],
                    serialnumber: [],
                    parameters: [],
                  };

                  SetDefaultValueRest(defaultVaRest);
                  updateSelectedFeild("site", r && r.label);
                  selectListAjaxcall(serverInput);
                }}
              />
            </div>

            {submitted &&
              defaultValueReset &&
              defaultValueReset.site.length === 0 && (
                <span className="error-message">Please Select Any Site </span>
              )}
          </div>

          <div
            className="watchlist_select"
            onClick={() => {
              SetClickPara(false);
            }}
          >
            <div className="select_txt">Product Model</div>
            <div className="watchlist-Product-Model">
              <InputSelect
                defaultValue={serverResponse.models}
                data={
                  serverResponse && serverResponse.models === null
                    ? []
                    : serverResponse.models
                }
                value={serverResponse.models ? defaultValueReset.model : null}
                isLoading={
                  serverResponse &&
                  serverResponse.models &&
                  serverResponse.models.length !== 0
                    ? false
                    : true
                }
                placeholder="Select here…"
                onChange={(r) => {
                  const defaultVaRest = {
                    customer: defaultValueReset && defaultValueReset.customer,
                    site: defaultValueReset && defaultValueReset.site,
                    model: [{ value: r && r.value, label: r && r.label }],
                    serialnumber: [],
                    parameters: [],
                  };
                  SetDefaultValueRest(defaultVaRest);
                  updateSelectedFeild("model", r && r.value);
                }}
              />
            </div>

            {submitted &&
              defaultValueReset &&
              defaultValueReset.model.length === 0 && (
                <span className="error-message">
                  Please Select Any Product Model
                </span>
              )}
          </div>

          <div className="watchlist_select">
            <div className="select_txt">
              Parameters<span className="mandatory-mark">*</span>
            </div>
            <InputSelectMulti
              menuIsOpen={clickPara}
              onMenuOpen={() => {
                SetClickPara(!clickPara);
              }}
              onMenuClose={() => {
                setTimeout(() => {
                  SetClickPara(false);
                }, 500);
              }}
              isCheckInput={true}
              data={machineMetricData}
              placeholder={
                watchListValidtion.parameters &&
                watchListValidtion.parameters.length >= 1
                  ? `${watchListValidtion.parameters.length} selected`
                  : "Select..."
              }
              isMulti
              onChange={(metrics) => {
                updateSelectedFeild("parameters", metrics);
                const metricsGraph =
                  metrics &&
                  metrics.map((ele) => ({
                    name: ele.label,
                    value: +ele.value,
                  }));
                SetSelectedLocalData({
                  ...selectedLocalData,
                  parametrs: metricsGraph,
                });
              }}
            />
            {submitted && watchListValidtion.parameters.length === 0 ? (
              <span className="error-message">
                Please, Select Atleast One Check Box
              </span>
            ) : null}
            {submitted && watchListValidtion.parameters.length > 3 ? (
              <span className="error-message">
                Please, Do Not check More than 3 checkbox
              </span>
            ) : null}
          </div>
          <MenuItem value="20">
            <button
              className="apply_btn"
              onClick={() => {
                SetClickPara(false);
                handleSubmit();
              }}
            >
              Add
            </button>
            <button
              className="cancel_btn"
              onClick={() => {
                SetClickPara(false);
                handleClose();
              }}
            >
              Cancel
            </button>
          </MenuItem>
        </Menu>
      ) : null}
    </>
  );
};

export default AddWatchList;
