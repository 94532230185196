import React from "react";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";

import ErrorPage from "../../../images/Error page.svg";
import KomatsuLogo from "./../../../images/KomatsuLogo.svg";
import { msalConfig } from "../../../utils/msalconfig/msalconfig";
import "../404/404.scss";

const myMSALObj = new PublicClientApplication(msalConfig);

const NoAccessPage = () => {
  const userdetails = useSelector((state) => state.userdetails);
  const EmailID =
    userdetails &&
    userdetails.idTokenClaims &&
    userdetails.idTokenClaims.preferred_username;

  const handlelogout = () => {
    myMSALObj.logout();
  };
  return (
    <>
      <div className="wildCard">
        <div className="main-logo">
          <img src={KomatsuLogo} alt="logo" width="300" />
        </div>
        <img src={ErrorPage} alt="error page" width="750" />
        <div className="error-text">
          <Typography variant="h4">Page Not Found.</Typography>
        </div>
        <p className="font-text">
          You don't have access to this page for account ({EmailID}).
        </p>
        <p className="login-another" onClick={handlelogout}>
          Login with another account
        </p>
        <p className="font-text">
          Please contact your adminstrator for more details
        </p>
      </div>
    </>
  );
};

export { NoAccessPage as default };
