import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import reportDropdown from "../../data/reportPageDropDown.json";
import inspectorRoleData from "../../data/inspectorRoleData.json";
import InputSelect from "../../components/input/inputSelect/inputSelect";
import filter_icon from "./../../images/icon_filter_search.png";
import TabNewComponent from "../../components/tabs/tabNewComponent";
import ReportGraph from "./ReportGraph/reportGraph";
import {
  DashboardService,
  ReportService,
  AccessManagementService,
} from "../../services";
import RmCareReport from "./ContractReport/rmCareReport";
import InspectionReport from "./InspectionReport/inspectionReport";
import InputDatePicker from "../../components/input/inputDatePicker/inputDatePicker";
import GetSingleSelect from "./GetSingleSelect/GetSingleSelect";
import GetMultiSelect from "./GetMultiSelect/GetMultiSelect";
import MonthlyFilters from "./MonthlyReport/MonthlyFilters";
import ContractFilters from "./ContractReport/ContractFilters";
import InspectionFilters from "./InspectionReport/InspectionFilters";
import BenchMarkReport from "./BenchmarkReport/BenchmarkFilter/BennchmarkFilters";
import BenchMarkGraph from "./BenchmarkReport/BenchMarkGraph/BenchMarkGraph";
import CommentsEditable from "./CommentsTable/ComentsTableEditor";

import "./report.scss";

const dashboardTabData = {
  name: "Dashboard",
  value: "dashboard",
  selectedTabIndex: 0,
  isTab: true,
  tabs: [],
};

const Report = () => {
  const appInsights = useAppInsightsContext();
  appInsights.trackMetric("Component 'Report' is in use");

  const [dropDownApiInput, setDropDownApiInput] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [showImage, setShowImage] = useState(true);
  const [showReport, setShowReport] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    parentId: {},
    customerId: {},
    fleetId: [],
    site: [],
    months: {},
    yearmonth: {},
    model: [],
    modelId: [],
    machineIds: {},
    customDate: [],
    serialNumber: [],
    inspectionDate: [],
  });
  const [dropDownApiGraph, setDropDownApiGraph] = useState([]);
  const [tabData, setTabData] = useState(dashboardTabData);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [sitesDetails, setSitesDetails] = useState([]);
  const [, setFilterCommentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const [previousComment, setPreviousComment] = useState([]);
  const [reportid, setReportType] = useState({
    reportid: [],
    monthlycustomers: [],
    contractcustomers: [],
    sites: [],
    fleets: [],
    model: [],
    models: [],
    machineIds: [],
    serialNumber: [],
    months: [],
    inspectionDate: [],
  });
  const [selectValue, setSelectValue] = useState([]);
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const userdetails = useSelector((state) => state.userdetails);
  const [dateReset, SetResetDate] = useState(true);
  const [anchorElrdrop, setAnchorEldrop] = useState(null);
  const [siteIdData, setSiteIdData] = useState("");
  const [, setUserCommentName] = useState("");
  const [powerBiAccessToken, setPowerBiAccessToken] = useState("");
  const [formDataReset, SetFormDataReset] = useState(true);
  const [benchMarchValidation, SetBenchMarchValidation] = useState({
    parentId: [],
    model: [],
  });
  const [submitted, setSubmitted] = useState(false);
  const [benchMarkCustomer, SetBenchMarkCustomers] = useState([]);
  const [selectedCustomer, SetSelectedCust] = useState({
    customer: [],
    model: [],
  });
  const [benchMarkMemoise, SetbenchMarkMemoise] = useState({});
  const [anchorElrdropimages, setAnchorEldropimages] = useState(null);
  const [historicalData, setHistoricalData] = useState("");

  const handleBenchMarkAjax = (params) => {
    SetBenchMarkCustomers([]);
    setDisbaleLoader(true);
    ReportService.getReportFilter({
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
      ...params,
    })
      .then((res) => {
        SetBenchMarkCustomers(res.data && res.data.customer);
        setDisbaleLoader(false);
      })
      .catch((err) => {
        setDisbaleLoader(false);
      });
  };

  const handleClickBenchMark = () => {
    if (
      benchMarchValidation.parentId.length &&
      benchMarchValidation.model.length
    ) {
      setShowReport(true);
      SetbenchMarkMemoise(benchMarchValidation);
      setSubmitted(false);
    } else {
      setSubmitted(true);
    }
  };
  function formResetFuntion() {
    SetFormDataReset(false);
    setTimeout(() => {
      SetFormDataReset(true);
    }, 1);
  }

  const handleClose = () => {
    setAnchorEldrop(null);
    setAnchorEldropimages(null);
  };

  function DateRestFuntion(value) {
    SetResetDate(value);
    setTimeout(() => {
      SetResetDate(true);
    }, 1);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    ReportService.getPowerBiAccessToken().then(({ data }) => {
      setPowerBiAccessToken(data);
    });
  }, []);

  const handleClickdrop = (event) => {
    setAnchorEldrop(event && event.currentTarget);
  };
  const handleClickdropimages = (event) => {
    setAnchorEldropimages(event && event.currentTarget);
  };
  const getMultipleFilterFormat = (name) => {
    let stringValue = "'";
    let index = 0;
    dropDownApiGraph[name] &&
      dropDownApiGraph[name].forEach((ele) => {
        if (dropDownApiGraph[name].length - 1 > index) {
          stringValue = stringValue + ele + "', '";
        } else {
          stringValue = stringValue + ele + "'";
        }
        index++;
      });
    return stringValue;
  };
  const [disbaleLoader, setDisbaleLoader] = useState(false);
  const checkEmpty = () => {
    return (
      <div className="load_filters">
        <h4>Select to load filters...</h4>
      </div>
    );
  };

  const inspectionReportPdfTitle ="Inspection-Report"

  const generateReport = () => {
    if (
      dropDownApiInput &&
      dropDownApiInput.reportid === 2 &&
      showReport === true
    ) {
      return (
        <RmCareReport
          className="report"
          tabIndex={selectedTabIndex}
          savedGraphData={dropDownApiGraph}
          selectMenu="RMcare"
        >
          <div className="download_btn">
            {isLoadingExport && (
              <div className="exportLoader">
                <div className="loaderPopUp">
                  <CircularProgress />
                  <div className="exportTextWrapper">
                    <p>Export to PDF/Power point in Progress</p>
                    <p>
                      Your Report is being exported to a PDF/Power Point File.
                      This might take some minutes
                    </p>
                  </div>
                </div>
              </div>
            )}
            <Button
              autoFocus
              className="download-btn"
              color="primary"
              onClick={(e) => handleClickdrop(e)}
              disabled={isLoadingExport}
            >
              Export
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElrdrop}
              keepMounted
              open={Boolean(anchorElrdrop)}
              onClose={handleClose}
              className="downloadpadf"
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  let serialNumberValue = "'";
                  let index = 0;
                  sitesDetails.serialNumber &&
                    sitesDetails.serialNumber.forEach((ele) => {
                      if (sitesDetails.serialNumber.length - 1 > index) {
                        serialNumberValue = `${serialNumberValue}${ele.value}', '`;
                      } else {
                        serialNumberValue = `${serialNumberValue}${ele.value}'`;
                      }
                      index++;
                    });
                  const filterValues = `DimCustomerContract/CUSTOMERID eq ${dropDownApiGraph.customerId[0]} and DimFleetContract/FLEETID eq ${dropDownApiGraph.fleetId[0]} and DimMachineContract/MODELID eq ${dropDownApiGraph.modelId[0]} and DimEquipmentContract/SERIALNUMBER in (${serialNumberValue}) and DimSelectionDate/DATEID eq ${dropDownApiGraph.customDate}`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pptx",
                    process.env.REACT_APP_REPORTS_CONTRACT_REPORT_REPORTID,
                    powerBIReportConfiguration,
                    "Weekly-Equipment-Report"
                  );
                }}
              >
                Power Point
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  let serialNumberValue = "'";
                  let index = 0;
                  sitesDetails.serialNumber &&
                    sitesDetails.serialNumber.forEach((element) => {
                      if (sitesDetails.serialNumber.length - 1 > index) {
                        serialNumberValue = `${serialNumberValue}${element.value}', '`;
                      } else {
                        serialNumberValue = `${serialNumberValue}${element.value}'`;
                      }
                      index++;
                    });
                  const filterValues = `DimCustomerContract/CUSTOMERID eq ${dropDownApiGraph.customerId[0]} and DimFleetContract/FLEETID eq ${dropDownApiGraph.fleetId[0]} and DimMachineContract/MODELID eq ${dropDownApiGraph.modelId[0]} and DimEquipmentContract/SERIALNUMBER in (${serialNumberValue}) and DimSelectionDate/DATEID eq ${dropDownApiGraph.customDate}`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pdf",
                    process.env.REACT_APP_REPORTS_CONTRACT_REPORT_REPORTID,
                    powerBIReportConfiguration,
                    "Weekly-Equipment-Report"
                  );
                }}
              >
                PDF
              </MenuItem>
            </Menu>
          </div>
        </RmCareReport>
      );
    } else if (
      dropDownApiInput &&
      dropDownApiInput.reportid === 3 &&
      showReport === true
    ) {
      return (
        <InspectionReport
          savedGraphData={dropDownApiGraph}
          handlePdfPPt={handlePdfPPt}
        >
          <div className="download_btn">
            {isLoadingExport && (
              <div className="exportLoader">
                <div className="loaderPopUp">
                  <CircularProgress />
                  <div className="exportTextWrapper">
                    <p>Export to PDF/Power point in Progress</p>
                    <p>
                      Your Report is being exported to a PDF/Power Point File.
                      This might take some minutes
                    </p>
                  </div>
                </div>
              </div>
            )}
            <Button
              autoFocus
              className="download-btn exportImages"
              color="primary"
              onClick={(e) => handleClickdrop(e)}
              disabled={isLoadingExport}
            >
              Export
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElrdrop}
              keepMounted
              open={Boolean(anchorElrdrop)}
              onClose={handleClose}
              className="downloadpadf"
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  const siteValue = getMultipleFilterFormat("site");
                  const modelValue = getMultipleFilterFormat("model");
                  const serialNumberValue = getMultipleFilterFormat(
                    "serialNumber"
                  );
                  const filterValues = `DimCustomer/PARENTID eq '${
                    dropDownApiGraph.parentId[0]
                  }' and DimSite/SITE eq ${dropDownApiGraph && +dropDownApiGraph.site} and DimMachine/ICTMODEL eq ${modelValue} and DimMachine/SERIALNUMBER eq ${serialNumberValue} and DimDate/DATE_PKEY eq ${+dropDownApiGraph
                    .inspectionDate[0]}`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pptx",
                    process.env
                      .REACT_APP_REPORTS_INSPECTION_CONDOVERVIEW_REPORTID,
                    powerBIReportConfiguration,
                    inspectionReportPdfTitle
                  );
                }}
              >
                Power Point
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  const siteValue = getMultipleFilterFormat("site");
                  const modelValue = getMultipleFilterFormat("model");
                  const serialNumberValue = getMultipleFilterFormat(
                    "serialNumber"
                  );
                  const filterValues = `DimCustomer/PARENTID eq '${
                    dropDownApiGraph.parentId[0]
                  }' and DimSite/SITE eq ${dropDownApiGraph && +dropDownApiGraph.site} and DimMachine/ICTMODEL eq ${modelValue} and DimMachine/SERIALNUMBER eq ${serialNumberValue} and DimDate/DATE_PKEY eq ${+dropDownApiGraph
                    .inspectionDate[0]}`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pdf",
                    process.env
                      .REACT_APP_REPORTS_INSPECTION_CONDOVERVIEW_REPORTID,
                    powerBIReportConfiguration,
                    inspectionReportPdfTitle
                  );
                }}
              >
                PDF
              </MenuItem>
            </Menu>
          </div>
          <div className="download_btn">
            {isLoadingExport && (
              <div className="exportLoader">
                <div className="loaderPopUp">
                  <CircularProgress />
                  <div className="exportTextWrapper">
                    <p>Export to PDF/Power point in Progress</p>
                    <p>
                      Your Report is being exported to a PDF/Power Point File.
                      This might take some minutes
                    </p>
                  </div>
                </div>
              </div>
            )}
            <Button
              autoFocus
              className="download-btn"
              color="primary"
              onClick={(e) => handleClickdropimages(e)}
              disabled={isLoadingExport}
            >
              Export Images
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElrdropimages}
              keepMounted
              open={Boolean(anchorElrdropimages)}
              onClose={handleClose}
              className="downloadpadf"
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  const siteValue = getMultipleFilterFormat("site");
                  const modelValue = getMultipleFilterFormat("model");
                  const serialNumberValue = getMultipleFilterFormat(
                    "serialNumber"
                  );
                  const filterValues = `DimCustomer/PARENTID eq '${
                    dropDownApiGraph.parentId[0]
                  }' and DimSite/SITE eq ${siteValue} and DimMachine/ICTMODEL eq ${modelValue} and DimMachine/SERIALNUMBER eq ${serialNumberValue} and DimDate/DATE_PKEY eq ${+dropDownApiGraph
                    .inspectionDate[0]}`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pptx",
                    process.env.REACT_APP_REPORTS_INSPECTION_PAGE_IMAGES,
                    powerBIReportConfiguration,
                    inspectionReportPdfTitle
                  );
                }}
              >
                Power Point Images
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  const siteValue = getMultipleFilterFormat("site");
                  const modelValue = getMultipleFilterFormat("model");
                  const serialNumberValue = getMultipleFilterFormat(
                    "serialNumber"
                  );
                  const filterValues = `DimCustomer/PARENTID eq '${
                    dropDownApiGraph.parentId[0]
                  }' and DimSite/SITE eq ${siteValue} and DimMachine/ICTMODEL eq ${modelValue} and DimMachine/SERIALNUMBER eq ${serialNumberValue} and DimDate/DATE_PKEY eq ${+dropDownApiGraph
                    .inspectionDate[0]}`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pdf",
                    process.env.REACT_APP_REPORTS_INSPECTION_PAGE_IMAGES,
                    powerBIReportConfiguration,
                    inspectionReportPdfTitle
                  );
                }}
              >
                PDF Images
              </MenuItem>
            </Menu>
          </div>
        </InspectionReport>
      );
    } else if (
      dropDownApiInput &&
      dropDownApiInput.reportid === 4 &&
      showReport === true
    ) {
      return (
        <BenchMarkGraph
          savedGraphData={benchMarkMemoise}
          selectMenu="benchmarkid"
        >
          <div className="download_btn">
            {isLoadingExport && (
              <div className="exportLoader">
                <div className="loaderPopUp">
                  <CircularProgress />
                  <div className="exportTextWrapper">
                    <p>Export to PDF/Power point in Progress</p>
                    <p>
                      Your Report is being exported to a PDF/Power Point File.
                      This might take some minutes
                    </p>
                  </div>
                </div>
              </div>
            )}
            <Button
              autoFocus
              className="download-btn"
              color="primary"
              onClick={(e) => handleClickdrop(e)}
              disabled={isLoadingExport}
            >
              Export
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElrdrop}
              keepMounted
              open={Boolean(anchorElrdrop)}
              onClose={handleClose}
              className="downloadpadf"
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  let parentIdValue = "'";
                  let index = 0;
                  benchMarkMemoise.parentId &&
                    benchMarkMemoise.parentId.forEach((ele) => {
                      if (benchMarkMemoise.parentId.length - 1 > index) {
                        parentIdValue = `${parentIdValue}${ele}', '`;
                      } else {
                        parentIdValue = `${parentIdValue}${ele}'`;
                      }
                      index++;
                    });
                  const filterValues = `DimMachine/BASEMODEL eq '${benchMarkMemoise.model[0]}' and DimCustomer/PARENTID in (${parentIdValue})`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pptx",
                    process.env.REACT_APP_REPORTS_BENCHMARK_PAGE_REPORTID,
                    powerBIReportConfiguration,
                    "Benchmark-Report"
                  );
                }}
              >
                Power Point
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  let parentIdValue = "'";
                  let index = 0;
                  benchMarkMemoise.parentId &&
                    benchMarkMemoise.parentId.forEach((element) => {
                      if (benchMarkMemoise.parentId.length - 1 > index) {
                        parentIdValue = `${parentIdValue}${element}', '`;
                      } else {
                        parentIdValue = `${parentIdValue}${element}'`;
                      }
                      index++;
                    });
                  const filterValues = `DimMachine/BASEMODEL eq '${benchMarkMemoise.model[0]}' and DimCustomer/PARENTID in (${parentIdValue})`;
                  const powerBIReportConfiguration = {
                    reportLevelFilters: [
                      {
                        filter: filterValues,
                      },
                    ],
                  };
                  handlePdfPPt(
                    "pdf",
                    process.env.REACT_APP_REPORTS_BENCHMARK_PAGE_REPORTID,
                    powerBIReportConfiguration,
                    "Benchmark-Report"
                  );
                }}
              >
                PDF
              </MenuItem>
            </Menu>
          </div>
        </BenchMarkGraph>
      );
    } else {
      return (
        <div className="empty_filter_cnt">
          <p>
            Select filter attributes
            <br /> and click on “Apply” to load report here…
          </p>
          <img src={filter_icon} alt="Empty Graph Icon" width="375" />
        </div>
      );
    }
  };
  const validate = () => {
    SetShowErrorMessage(true);
    if (dropDownApiInput.reportid === 1) {
      let temp = false;
      if (Object.values(errorMessage.parentId).length === 0) {
        temp = true;
      }
      if (!errorMessage.site.length) {
        temp = true;
      }
      if (!errorMessage.model.length) {
        temp = true;
      }
      if (
        Object.values(errorMessage.yearmonth).length === 0 &&
        dropDownApiInput.reportid !== 3
      ) {
        temp = true;
      }

      if (temp) {
        return false;
      }
      return true;
    } else if (dropDownApiInput.reportid === 2) {
      let temp = false;
      if (Object.values(errorMessage.customerId).length === 0) {
        temp = true;
      }
      if (!errorMessage.fleetId.length) {
        temp = true;
      }
      if (!errorMessage.modelId.length) {
        temp = true;
      }

      if (Object.values(errorMessage.customDate).length === 0) {
        temp = true;
      }
      if (temp) {
        return false;
      }
      return true;
    } else if (dropDownApiInput.reportid === 3) {
      let temp = false;
      if (Object.values(errorMessage.parentId).length === 0) {
        temp = true;
      }
      if (Object.values(errorMessage.site).length === 0) {
        temp = true;
      }
      if (!errorMessage.model.length) {
        temp = true;
      }
      if (!errorMessage.serialNumber.length) {
        temp = true;
      }
      if (Object.values(errorMessage.inspectionDate).length === 0) {
        temp = true;
      }
      if (temp) {
        return false;
      }
      return true;
    }
    return true;
  };
  const handleClick = () => {
    if (validate()) {
      if (dropDownApiInput.reportid === 2 || dropDownApiInput.reportid === 3) {
        setShowImage(true);
        setShowReport(true);
        setDropDownApiGraph({
          ...dropDownApiGraph,
          ...dropDownApiInput,
        });
      } else {
        const tabDetails = sitesDetails["site"].map((ele) => ({
          name: ele.label,
          value: ele.value,
          component: ReportGraph,
        }));
        setDropDownApiGraph({
          ...dropDownApiGraph,
          ...dropDownApiInput,
        });
        setTabData({ tabs: tabDetails });
        const customerIdData = dropDownApiInput.parentId;
        const siteIdData = dropDownApiInput.site[selectedTabIndex];
        const monthyearData =
          dropDownApiInput &&
          dropDownApiInput.yearmonth &&
          dropDownApiInput.yearmonth[0];
        setIsLoading(true);
        setHistoricalData("");
        setNoDataPresentError(false);
        setEnablePreviousComments(false);
        ReportService.getFilterCommentData(
          customerIdData,
          siteIdData,
          monthyearData
        )
          .then((res) => {
            setPreviousComment(res.data);
            setIsLoading(false);
            const userName =
              res.data &&
              res.data[res.data.length - 1] &&
              res.data[res.data.length - 1].CREATEDBY;
            if (userName) {
              AccessManagementService.getUserDetails(userName).then(
                (response) => {
                  setUserCommentName(response);
                }
              );
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
        setShowImage(false);
      }
      SetShowErrorMessage(false);
    }
  };
  const handleSelectValue = (event, name) => {
    setSelectValue({ ...selectValue, [name]: event });
  };
  const handleResetFilters = () => {
    setShowImage(true);
    setDropDownApiInput([]);
    setDropDownApiGraph([]);
    setSelectedTabIndex(0);
    SetShowErrorMessage(false);
    setReportType({
      reportid: [],
      monthlycustomers: [],
      contractcustomers: [],
      sites: [],
      fleets: [],
      model: [],
      models: [],
      machineIds: [],
      months: [],
      serialNumber: [],
      inspectionDate: [],
    });
    setShowReport(false);
    setErrorMessage({
      parentId: {},
      customerId: {},
      fleetId: [],
      site: [],
      months: {},
      yearmonth: {},
      model: [],
      serialNumber: [],
      inspectionDate: [],
      modelId: [],
      machineIds: {},
      customDate: [],
    });
    setSiteIdData("");
  };
  const handleReportChange = (event, name, isMulti = false) => {
    setSubmitted(false);
    let inputName = {};
    if (name === "reportid") {
      handleResetFilters();
      setSitesDetails([]);
      inputName = { [name]: event };
    } else if (name === "modelId") {
      inputName = {
        ...dropDownApiInput,
        [name]: [+event],
      };
    } else if (dropDownApiInput.reportid === 2 && name === "customerId") {
      inputName = {
        ...dropDownApiInput,
        [name]: [+event],
      };
    } else {
      inputName = { ...dropDownApiInput, [name]: event };
    }
    if (name === "parentId") {
      setSiteIdData("");
    }
    setDropDownApiInput(inputName);

    if (!isMulti) {
      dropDownApiData(name, inputName);
    }
  };
  const monthlyReportKeys = [
    "reportid",
    "parentId",
    "site",
    "yearmonth",
    "model",
  ];
  const contractReportKeys = [
    "customerId",
    "fleetId",
    "modelId",
    "serialNumber",
    "customDate",
  ];
  const inspectionReportKeys = [
    "parentId",
    "site",
    "model",
    "serialNumber",
    "inspectionDate",
  ];
  const resetConfig = {
    reportid: [],
    parentId: [
      "sites",
      "months",
      "model",
      "fleets",
      "models",
      "serialNumber",
      "customDate",
    ],
    site: ["months", "model"],
    yearmonth: ["model"],
    model: [],
    customerId: ["fleets", "models", "serialNumber", "customDate"],
    fleetId: ["models", "serialNumber", "customDate"],
    modelId: ["serialNumber", "customDate"],
    serialNumber: [],
  };
  const dropDownApiData = (
    reportValue,
    inputName = dropDownApiInput,
    modelReset = siteIdData
  ) => {
    if (
      inputName &&
      inputName.reportid === 2 &&
      reportValue === "serialNumber"
    ) {
      return false;
    }

    if (inputName && inputName.reportid === 1 && reportValue === "model") {
      return false;
    }
    const finalOutputDrop = { ...inputName };
    const finalOutputReportId = { ...reportid };

    if (dropDownApiInput[reportValue]) {
      if (reportValue === "site") {
        setSiteIdData(dropDownApiInput[reportValue][0]);
        setSelectedTabIndex(0);
      }
      let reportKeys;
      if (inputName.reportid === 1) {
        reportKeys = monthlyReportKeys;
      } else if (inputName.reportid === 2) {
        reportKeys = contractReportKeys;
      } else {
        reportKeys = inspectionReportKeys;
      }
      const removeData = reportKeys.indexOf(reportValue);
      reportKeys.forEach((report, index) => {
        if (index > removeData) {
          delete finalOutputDrop[report];
        }
      });

      const errorData = {
        parentId: {},
        customerId: {},
        fleetId: [],
        site: [],
        months: {},
        yearmonth: {},
        model: [],
        serialNumber: [],
        inspectionDate: [],
        modelId: [],
        machineIds: {},
        customDate: [],
      };
      resetConfig &&
        resetConfig[reportValue] &&
        resetConfig[reportValue].forEach((report, index) => {
          delete finalOutputReportId[report];
        });

      setSitesDetails((prevSites) => {
        let finalOutput = { ...prevSites };
        reportKeys.forEach((ele, index) => {
          if (index > removeData) {
            finalOutput = { ...finalOutput, [ele]: [] };
            errorMessage[ele] = errorData[ele];
          } else {
            finalOutput = { ...finalOutput, [ele]: prevSites[ele] };
          }
        });
        return finalOutput;
      });
      setDropDownApiInput(finalOutputDrop);
      setReportType(finalOutputReportId);
    }
    let newFinalOutput = { ...finalOutputDrop };
    if (
      modelReset &&
      finalOutputDrop.site &&
      finalOutputDrop.site.indexOf(modelReset) >= 0
    ) {
      newFinalOutput = { ...newFinalOutput, site: [modelReset] };
    }
    setDisbaleLoader(true);
    SetBenchMarchValidation({
      parentId: [],
      model: [],
    });
    SetBenchMarkCustomers([]);
    SetSelectedCust({
      customer: [],
      model: [],
    });

    ReportService.getReportFilter({
      loggedInUser:
        userdetails && userdetails.account && userdetails.account.username,
      ...newFinalOutput,
    })
      .then((res) => {
        setDisbaleLoader(false);
        setReportType({ ...res.data });
        if (
          inputName &&
          inputName.reportid === 2 &&
          res.data["serialNumber"] &&
          res.data["serialNumber"].length > 0
        ) {
          setSitesDetails((prev) => ({
            ...prev,
            serialNumber: res.data["serialNumber"],
          }));
        }
        if (
          reportValue === "yearmonth" &&
          res.data["model"] &&
          res.data["model"].length > 0
        ) {
          setSitesDetails((prev) => ({ ...prev, model: res.data["model"] }));
          setErrorMessage((prevError) => ({
            ...prevError,
            model: res.data["model"],
          }));
        }
      })
      .catch((err) => {
        setDisbaleLoader(false);
      });
  };
  const handleTabChange = (tabIndex) => {
    formResetFuntion();
    setPreviousComment([]);
    setFilterCommentData([]);
    setHistoricalData("");
    setNoDataPresentError(false);
    setEnablePreviousComments(false);
    setSelectedTabIndex(tabIndex);
    setDropDownApiGraph({ ...dropDownApiGraph, model: [] });
    const customerIdData = dropDownApiInput.parentId;
    const siteIdDataValue =
      dropDownApiInput &&
      dropDownApiInput.site &&
      dropDownApiInput.site[tabIndex];
    setSiteIdData(siteIdDataValue);
    dropDownApiData("yearmonth", dropDownApiInput, siteIdDataValue);
    const monthyearData =
      dropDownApiInput &&
      dropDownApiInput.yearmonth &&
      dropDownApiInput.yearmonth[0];
    setIsLoading(true);
    setHistoricalData("");
    setNoDataPresentError(false);
    ReportService.getFilterCommentData(
      customerIdData,
      siteIdDataValue,
      monthyearData
    )
      .then((resp) => {
        setPreviousComment(resp.data);
        setIsLoading(false);
        const userName =
          resp.data &&
          resp.data[resp.data.length - 1] &&
          resp.data[resp.data.length - 1].CREATEDBY;
        if (userName) {
          AccessManagementService.getUserDetails(userName).then((response) => {
            setUserCommentName(response);
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const [enablePreviousComments, setEnablePreviousComments] = useState(false);

  const [noDataPresentError, setNoDataPresentError] = useState(false);

  const previousComments = () => {
    setHistoricalData("");
    setNoDataPresentError(false);

    const customerIdData = dropDownApiInput.parentId;
    const siteIDData = dropDownApiInput.site[selectedTabIndex];
    const monthyearData =
      dropDownApiInput &&
      dropDownApiInput.yearmonth &&
      dropDownApiInput.yearmonth[0];

    const MonthsArray = reportid.months;

    const nextMonthArrayindex = MonthsArray.indexOf(
      MonthsArray.filter(function (item) {
        return item.value === monthyearData;
      })[0]
    );

    const nextMonthArrayindexValue =
      MonthsArray &&
      MonthsArray[nextMonthArrayindex + 1] &&
      MonthsArray[nextMonthArrayindex + 1].value;
    if (nextMonthArrayindexValue) {
      setIsLoading(true);
      ReportService.GetCreatedCommentsFromPreviousMonth(
        customerIdData,
        siteIDData,
        nextMonthArrayindexValue
      )
        .then((res) => {
          ReportService.getFilterCommentData(
            customerIdData,
            siteIDData,
            monthyearData
          )
            .then((res) => {
              setPreviousComment(res.data);
              setNoDataPresentError(
                res.data &&
                  res.data.userComments &&
                  res.data.userComments.length === 0
                  ? true
                  : false
              );
              setIsLoading(false);
              const userName =
                res.data &&
                res.data[res.data.length - 1] &&
                res.data[res.data.length - 1].CREATEDBY;
              if (userName) {
                AccessManagementService.getUserDetails(userName).then(
                  (response) => {
                    setUserCommentName(response);
                  }
                );
              }
            })
            .catch(() => {
              setIsLoading(false);
            });

          const userNameValue =
            res.data &&
            res.data[res.data.length - 1] &&
            res.data[res.data.length - 1].CREATEDBY;
          if (userNameValue) {
            AccessManagementService.getUserDetails(userNameValue).then(
              (response) => {
                setUserCommentName(response);
              }
            );
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setHistoricalData("Data not available");
      setNoDataPresentError(false);
    }
  };

  const handleTextEditor = (comments, crudOp) => {
    const normalisedresult = {
      comments: comments[0].comments || "",
      section: comments[0].section || "",
      subSection: comments[0].subSection || "",
      id: comments[0].id || 0,
      commentId: comments[0].commentId || previousComment.id,
    };

    const monthyearData =
      dropDownApiInput &&
      dropDownApiInput.yearmonth &&
      dropDownApiInput.yearmonth[0];

    const MonthsArray = reportid.months;

    const nextMonthArrayindex = MonthsArray.indexOf(
      MonthsArray.filter(function (item) {
        return item.value === monthyearData;
      })[0]
    );

    const nextMonthArrayindexValue =
      MonthsArray &&
      MonthsArray[nextMonthArrayindex + 1] &&
      MonthsArray[nextMonthArrayindex + 1].value;

    const reportData = {
      customerId: 0,
      siteId: 0,
      updatedDate: "2021-04-05T07:10:54.680Z",
      id: comments[0].commentId || previousComment.id,
      parentId:
        dropDownApiInput &&
        dropDownApiInput.parentId &&
        dropDownApiInput.parentId[0],
      site:
        dropDownApiInput &&
        dropDownApiInput.site &&
        dropDownApiInput.site[selectedTabIndex],
      yearmonth:
        dropDownApiInput &&
        dropDownApiInput.yearmonth &&
        dropDownApiInput.yearmonth[0],
      userComments: [normalisedresult],
      createdby:
        userdetails &&
        userdetails.account &&
        userdetails.account.username.toLowerCase(),
      updatedby:
        userdetails &&
        userdetails.account &&
        userdetails.account.username.toLowerCase(),
      loggedInUser:
        userdetails &&
        userdetails.account &&
        userdetails.account.username.toLowerCase(),
    };
    setIsLoading(true);
    setHistoricalData("");
    setNoDataPresentError(false);

    if (crudOp === "add") {
      DashboardService.getfilterComment(reportData)
        .then((res) => {
          setFilterCommentData(res.data);
          setIsLoading(false);
          setPreviousComment(res.data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    if (crudOp === "update") {
      DashboardService.getfilterCommentPut(reportData)
        .then((res) => {
          setFilterCommentData(res.data);
          setIsLoading(false);
          setPreviousComment(res.data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    if (crudOp === "delete") {
      DashboardService.getfilterCommentDelete(reportData)
        .then((resp) => {
          setFilterCommentData(resp.data);
          setIsLoading(false);
          setPreviousComment(resp.data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const validateError = (isRequired, selectType, reportValue) => {
    if (showErrorMessage && isRequired) {
      if (selectType === "multi") {
        return (
          errorMessage &&
          errorMessage[reportValue] &&
          !errorMessage[reportValue].length
        );
      } else if (errorMessage && errorMessage[reportValue]) {
        return Object.values(errorMessage[reportValue]).length === 0;
      }
    }
  };
  const getSingleSelect = (res) => (
    <GetSingleSelect
      reportData={res}
      sitesDetails={sitesDetails}
      setSitesDetails={setSitesDetails}
      errorMessage={errorMessage}
      handleReportChange={handleReportChange}
      setErrorMessage={setErrorMessage}
      validateError={validateError}
      DateRestFuntion={DateRestFuntion}
    />
  );
  const getMultiSelect = (res) => (
    <GetMultiSelect
      reportData={res}
      dropDownApiInput={dropDownApiInput}
      dropDownApiData={dropDownApiData}
      setSitesDetails={setSitesDetails}
      errorMessage={errorMessage}
      sitesDetails={sitesDetails}
      handleSelectValue={handleSelectValue}
      handleReportChange={handleReportChange}
      setErrorMessage={setErrorMessage}
      validateError={validateError}
    />
  );
  const getDatePicker = (res) => (
    <div className="filter_cnt date-reset">
      <label>
        {res.labelName}
        {res.isRequired && <span className="mandatory-mark">*</span>}
      </label>
      {dateReset ? (
        <InputDatePicker
          reportid={reportid}
          errorMessage={errorMessage}
          handleDatePicker={handleDatePicker}
          setErrorMessage={setErrorMessage}
          reportValue={res.reportValue}
          sitesDetails={sitesDetails}
        />
      ) : (
        <InputSelect isLoading={true} placeholder="Select..." />
      )}
      {validateError(res.isRequired, res.selectType, res.reportValue) && (
        <span className="error-message">Please select any {res.labelName}</span>
      )}
    </div>
  );
  const handleDatePicker = (selectedDate, reportValue) => {
    setDropDownApiInput({ ...dropDownApiInput, [reportValue]: selectedDate });
  };
  const handlePdfPPt = (
    fileFormat,
    powerBiReportId,
    powerBIReportConfiguration,
    reportName
  ) => {
    setIsLoadingExport(true);
    fetch(
      `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_POWERBI_GROUPID}/reports/${powerBiReportId}/ExportTo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${powerBiAccessToken}`,
        },
        body: JSON.stringify({
          format: fileFormat,
          powerBIReportConfiguration: powerBIReportConfiguration,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setTimeout(() => {
          fetch(
            `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_POWERBI_GROUPID}/reports/${powerBiReportId}/exports/${res.id}/file`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${powerBiAccessToken}`,
              },
            }
          )
            .then((response) => {
              return response.blob();
            })
            .then((blob) => {
              setIsLoadingExport(false);
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = `${reportName}.${fileFormat}`;
              document.body.appendChild(a);
              a.click();
              a.remove();
            })
            .catch((err) => {
              setIsLoadingExport(false);
            });
        }, 90000);
      });
  };

  const checkCustomerLength =
    userdetails &&
    userdetails.usercustomerData &&
    userdetails.usercustomerData.customers &&
    userdetails.usercustomerData.customers.length > 0;

  return (
    <>
      <div className="content report_cnt">
        <div
          className={
            dropDownApiInput.reportid === 4
              ? "report_filters bench_mark"
              : "report_filters"
          }
        >
          <div className={isEmpty ? "emptyclass" : "filter_cnt"}>
            <label>Report Type</label>
            {disbaleLoader ? (
              <div className="progressBaralignmentreport">
                <CircularProgress />
              </div>
            ) : null}

            <InputSelect
              defaultValue={reportid.reportid}
              data={
                checkCustomerLength
                  ? reportDropdown.reportid
                  : inspectorRoleData.reportid
              }
              isSearchable
              placeholder="Select..."
              onChange={(r) => {
                setIsEmpty(false);
                handleResetFilters();
                SetShowErrorMessage(false);
                handleReportChange(r && r.value, "reportid");
              }}
            />
          </div>
          {isEmpty ? (
            checkEmpty()
          ) : (
            <>
              {dropDownApiInput.reportid === 1 &&
                MonthlyFilters(
                  reportid,
                  getSingleSelect,
                  getMultiSelect,
                  siteIdData ||
                    (dropDownApiInput &&
                      dropDownApiInput.site &&
                      dropDownApiInput.site[selectedTabIndex])
                )}

              {dropDownApiInput.reportid === 2 &&
                ContractFilters(
                  reportid,
                  getSingleSelect,
                  getMultiSelect,
                  getDatePicker
                )}
              {dropDownApiInput.reportid === 3 &&
                InspectionFilters(
                  reportid,
                  getSingleSelect,
                  getMultiSelect,
                  getDatePicker
                )}

              {dropDownApiInput.reportid === 4 ? (
                <BenchMarkReport
                  benchMarchValidation={benchMarchValidation}
                  submitted={submitted}
                  reportid={reportid}
                  handleBenchMarkAjax={handleBenchMarkAjax}
                  benchMarkCustomer={benchMarkCustomer}
                  SetBenchMarchValidation={SetBenchMarchValidation}
                  selectedCustomer={selectedCustomer}
                  SetSelectedCust={SetSelectedCust}
                />
              ) : null}

              {dropDownApiInput.reportid === 4 ? (
                <Button
                  className="apply_btn"
                  onClick={() => {
                    handleClickBenchMark();
                  }}
                >
                  APPLY
                </Button>
              ) : (
                <Button className="apply_btn" onClick={handleClick}>
                  APPLY
                </Button>
              )}
            </>
          )}
        </div>
        {!showImage ? (
          <div className="tabs_section">
            <div className="horizontal_tabs">
              {dropDownApiGraph && (
                <TabNewComponent
                  data={tabData}
                  tabIndex={selectedTabIndex}
                  handleTabChange={handleTabChange}
                  savedGraphData={dropDownApiGraph}
                >
                  <ReportGraph
                    tabIndex={selectedTabIndex}
                    savedGraphData={dropDownApiGraph}
                    selectMenu="reportid"
                  />
                </TabNewComponent>
              )}
              <div className="download_btn">
                {isLoadingExport && (
                  <div className="exportLoader">
                    <div className="loaderPopUp">
                      <CircularProgress />
                      <div className="exportTextWrapper">
                        <p>Export to PDF/Power point in Progress</p>
                        <p>
                          Your Report is being exported to a PDF/Power Point
                          File. This might take some minutes
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <Button
                  autoFocus
                  className="download-btn"
                  color="primary"
                  onClick={(e) => handleClickdrop(e)}
                  disabled={isLoadingExport}
                >
                  Export
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElrdrop}
                  keepMounted
                  open={Boolean(anchorElrdrop)}
                  onClose={handleClose}
                  className="downloadpadf"
                >
                  <MenuItem
                    onClick={(e) => {
                      handleClose();
                      let modelValue = "'";
                      let index = 0;
                      sitesDetails.model &&
                        sitesDetails.model.forEach((ele) => {
                          if (sitesDetails.model.length - 1 > index) {
                            modelValue = modelValue + ele.value + "', '";
                          } else {
                            modelValue = modelValue + ele.value + "'";
                          }
                          index++;
                        });
                      const filterValues = `DimSite/SITE eq '${
                        dropDownApiInput &&
                        dropDownApiInput.site &&
                        dropDownApiInput.site[selectedTabIndex]
                      }' and DimDate/YEARMONTH_ID eq ${
                        dropDownApiInput &&
                        dropDownApiInput.yearmonth &&
                        +dropDownApiInput.yearmonth[0]
                      } and DimMachine/BASEMODEL in (${modelValue})`;
                      const powerBIReportConfiguration = {
                        reportLevelFilters: [
                          {
                            filter: filterValues,
                          },
                        ],
                        pages: [
                          {
                            pageName:
                              process.env.REACT_APP_REPORTS_MONTLY_PAGE_NAME,
                          },
                          {
                            pageName:
                              process.env
                                .REACT_APP_REPORTS_MONTLY_PAGE_NAME_TWO,
                          },
                        ],
                      };
                      handlePdfPPt(
                        "pptx",
                        process.env.REACT_APP_REPORTS_MONTHLY_REPORT_REPORTID,
                        powerBIReportConfiguration,
                        "Monthly-Operation-Report"
                      );
                    }}
                  >
                    Power Point
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleClose();
                      let modelValue = "'";
                      let index = 0;
                      sitesDetails.model &&
                        sitesDetails.model.forEach((element) => {
                          if (sitesDetails.model.length - 1 > index) {
                            modelValue = `${modelValue}${element.value}', '`;
                            
                          } else {
                            modelValue = `${modelValue}${element.value}'`;
                          }
                          index++;
                        });
                      const filterValues = `DimSite/SITE eq '${
                        dropDownApiInput.site[selectedTabIndex]
                      }' and DimDate/YEARMONTH_ID eq ${
                        dropDownApiInput &&
                        dropDownApiInput.yearmonth &&
                        +dropDownApiInput.yearmonth[0]
                      } and DimMachine/BASEMODEL in (${modelValue})`;
                      const powerBIReportConfiguration = {
                        reportLevelFilters: [
                          {
                            filter: filterValues,
                          },
                        ],
                        pages: [
                          {
                            pageName:
                              process.env.REACT_APP_REPORTS_MONTLY_PAGE_NAME,
                          },
                          {
                            pageName:
                              process.env
                                .REACT_APP_REPORTS_MONTLY_PAGE_NAME_TWO,
                          },
                        ],
                      };
                      handlePdfPPt(
                        "pdf",
                        process.env.REACT_APP_REPORTS_MONTHLY_REPORT_REPORTID,
                        powerBIReportConfiguration,
                        "Monthly-Operation-Report"
                      );
                    }}
                  >
                    PDF
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div
              className={
                isLoading ? "text_area_cnt stopClicking" : "text_area_cnt"
              }
            >
              {formDataReset ? (
                <CommentsEditable
                  noDataPresentError={noDataPresentError}
                  enablePreviousComments={enablePreviousComments}
                  historicalData={historicalData}
                  previousComments={previousComments}
                  handleTextEditor={handleTextEditor}
                  data={
                    previousComment.userComments
                      ? previousComment.userComments
                      : []
                  }
                />
              ) : null}
              {isLoading && <CircularProgress disableShrink />}
            </div>
          </div>
        ) : (
          <div className="tabs_section">
            <div className="horizontal_tabs">{generateReport()}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Report;
