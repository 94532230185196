const ContractFilters = (
  reportid,
  getSingleSelect,
  getMultiSelect,
  getDatePicker
) => {
  return [
    {
      labelName: "Customer",
      isRequired: true,
      defalutValueData: reportid.contractcustomers,
      reportValue: "customerId",
      selectType: "single",
    },
    {
      labelName: "Fleet",
      isRequired: true,
      defalutValueData: reportid.fleets,
      reportValue: "fleetId",
      selectType: "single",
    },
    {
      labelName: "Model",
      isRequired: true,
      defalutValueData: reportid.models,
      reportValue: "modelId",
      selectType: "single",
    },
    {
      labelName: "Serial Number",
      isRequired: false,
      defalutValueData: reportid.serialNumber,
      reportValue: "serialNumber",
      selectType: "multi",
    },
    {
      labelName: "Date From",
      isRequired: true,
      defalutValueData: reportid.customDate,
      reportValue: "customDate",
      selectType: "datepicker",
    },
  ].map((res) => {
    if (res.selectType === "single") {
      return getSingleSelect(res);
    } else if (res.selectType === "multi") {
      return getMultiSelect(res);
    } else if (res.selectType === "datepicker") {
      return getDatePicker(res);
    }
    return false;
  });
};
export default ContractFilters;
