import { CircularProgress } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import PowerBI from "../../../components/PowerBI";
import Config from "../../../config/config";
import { DashboardService } from "../../../services";
import Utils from "../../../utils";
import "../report.scss";
const getModifiedData = (data, tabIndex) => {
  return {
    ...data,
  };
};
const RmCareReport = ({
  savedGraphData,
  tabIndex = 0,
  selectMenu,
  children,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  useMemo(() => {
    const filterData = getModifiedData(savedGraphData, tabIndex);
    setSelectedFilters(filterData);
  }, [tabIndex, savedGraphData]);
  const getReportDetails = (selectedMenu, newReportView) => {
    const { reportDetails } = Config;
    return reportDetails[selectedMenu][newReportView];
  };

  const { reportId, groupId, embedId, datasetId } = getReportDetails(
    selectMenu,
    "horizontal"
  );

  let filters = Utils.createRMcareReportFilter(selectedFilters);

  useMemo(() => {
    const fetchNSetToken = async (
      selectedMenu,
      newReportView = "horizontal"
    ) => {
      const { reportId, datasetId } = getReportDetails(
        selectedMenu,
        newReportView
      );
      DashboardService.getPowerBiToken(datasetId, reportId).then((res) => {
        setAccessToken(res.data.token);
      });
    };
    fetchNSetToken(selectMenu);
  }, [selectMenu]);
  return (
    <div className="rmcare-report">
      <div className="benchmarkReportExport"> {children}</div>
      {accessToken ? (
        <PowerBI
          reportId={reportId}
          groupId={groupId}
          embedId={embedId}
          selectedFilters={filters}
          datasetId={datasetId}
          tokenType="Embed"
          accessToken={accessToken}
        />
      ) : (
        <div className="report-graph-loader">
          <CircularProgress disableShrink />
        </div>
      )}
    </div>
  );
};
export default React.memo(RmCareReport);
