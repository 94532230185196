import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { Button, Typography } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";

import "./popoverComponent.scss";

const PopoverComponent = ({
  popoverData,
  typographyLabel,
  buttonEvent,
  handleCloseButton,
  handleSelection,
  selectedFilterForPopover,
  selectedMenu,
  reportType,
  machineMetricEnable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMcnMetrics, setSelectedMcMetrics] = useState([]);

  useEffect(() => {
    setAnchorEl(buttonEvent);
    updateFilters(selectedFilterForPopover);
  }, [buttonEvent, selectedFilterForPopover, selectedMenu, reportType]);

  const updateFilters = (value) => {
    if (reportType !== "machineMetrics") {
      setSelectedMcMetrics([]);
      return true;
    }
    if (value !== null && value !== undefined && value !== {}) {
      const filters =
        value && reportType && value[reportType] ? value[reportType] : [];
      setSelectedMcMetrics(filters);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleCloseButton();
  };
  const handleChangeMultiple = (value) => {
    const options = [...selectedMcnMetrics];

    let index = options.indexOf(value);
    index = options.findIndex((element) => {
      if (element.label === value.label) {
        return true;
      }
    });
    if (options.some((item) => item.label === value.label) === true) {
      options.splice(index, 1);
    } else {
      const arr = [];
      arr.push(value);
      options.push(value);
    }
    setSelectedMcMetrics(options);
    let temp = [];
    for (let i = 0; i < options.length; i++) {
      temp.push(options[i][0]);
    }

    handleSelection(options, value);
  };

  const open = Boolean(anchorEl);
  const id = open ? typographyLabel : undefined;

  function getStyles(name, selectedMcnMetrics = [], theme) {
    if (selectedMcnMetrics.some((item) => item.label === name.label) === true) {
      return theme;
    } else {
      return "";
    }
  }

  return (
    <div>
      {machineMetricEnable ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Typography className="machine_metrics">{typographyLabel}</Typography>
          <Scrollbars
            className="custom_scroll"
            autoHeight
            autoHeightMin={0}
            autoHeightMax={500}
          >
            {popoverData.map((name) => {
              return (
                <Button
                  onClick={(e) => handleChangeMultiple(name)}
                  key={name.id}
                  className={getStyles(name, selectedMcnMetrics, "current")}
                >
                  {name.label}{" "}
                </Button>
              );
            })}
          </Scrollbars>
        </Popover>
      ) : null}
    </div>
  );
};

PopoverComponent.prototype = {
  popoverData: PropTypes.array,
  typographyLabel: PropTypes.string,
  buttonImage: PropTypes.string,
};

PopoverComponent.defaultProps = {
  popoverData: [],
  typographyLabel: "",
};

export default PopoverComponent;
