import Services from "./services";
import Axios from "./axios";

/**
 * reportService
 */
export default class ReportService extends Services {
  axiosInstance = new Axios(`${this.apiDomain}${this.endpointPrefix}/`);

  /**
   * Get report filter
   *
   * @returns {*}
   */

  getReportFilter = (filterInput) => {
    return this.axiosInstance.post(
      "/GetReportFilter/GetReportFilter",
      filterInput,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  getFilterCommentData = (customerId, site, monthyear) => {
    return this.axiosInstance.get(
      `/GetReportFilter/GetFilterCommentData?parentId=${customerId}&site=${site}&yearmonth=${monthyear}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  getPowerBiAccessToken = () => {
    return this.axiosInstance.get(`/PowerBIReport/GetPowerBIToken`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };
  GetCreatedCommentsFromPreviousMonth = (customerId, site, monthyear) => {
    return this.axiosInstance.get(
      `/GetReportFilter/GetCreatedCommentsFromPreviousMonth?parentId=${customerId}&site=${site}&yearmonth=${monthyear}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
}
