import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import NotFoundPage from "../pages/wildCardPage/404/404";
import NoaccessPage from "../pages/wildCardPage/noAccess/NoAccessPage";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Dashboard from "../pages/Dashboard";
import Report from "../pages/Reports/report.jsx";
import Accessmanagement from "../pages/Access-management/accessmanagement";
import Watchlist from "../pages/watchlist/watchlist";
import { useSelector } from "react-redux";

const AppRouter = () => {
  const userdetails = useSelector((state) => state.userdetails);

  return (
    <>
      {!(userdetails && userdetails.secondLevelAuthAccess) ? (
        <BrowserRouter>
          <div>
            <Switch>
              <PublicRoutes path="/" component={Dashboard} exact={true} />
              <PrivateRoutes
                path="/dashboard"
                component={Dashboard}
                exact={true}
              />
              <PrivateRoutes path="/reports" component={Report} exact={true} />
              {userdetails &&
                userdetails.secondLevelAuth &&
                userdetails.secondLevelAuth.roleId !== 3 && (
                  <PrivateRoutes
                    path="/user-access-management"
                    component={Accessmanagement}
                    exact={true}
                  />
                )}
              {userdetails.usercustomerData &&
                userdetails.usercustomerData.customers &&
                userdetails.usercustomerData.customers.length > 0 && (
                  <PrivateRoutes
                    path="/watchlist"
                    component={Watchlist}
                    exact={true}
                  />
                )}
              <Route path="/noaccess" component={NoaccessPage} />
              <PrivateRoutes component={NotFoundPage} />
            </Switch>
          </div>
        </BrowserRouter>
      ) : (
        <NoaccessPage />
      )}
    </>
  );
};
export { AppRouter as default };
