import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./tabs.scss";
import { Typography, Box } from "@material-ui/core";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: "none",
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any,
  value: PropTypes.any,
};

const TabComponent = ({
  handleNextFeatureSelection,
  componentData,
  data,
  rightSideFilterData,
  tabIndex,
  handleTabChange,
  variant = "fullWidth",
}) => {
  const classes = useStyles();
  const [selectedTab, updateSelectedTab] = useState(tabIndex);

  useEffect(() => {
    updateSelectedTab(tabIndex);
  }, [data && data.selectedTabIndex, tabIndex]);

  const handleChange = (event, newValue) => {
    handleTabChange(newValue);
  };
  const generateTabs = () => {
    return (
      data &&
      data.tabs.map((item, index) => (
        <Tab
          key={`${item.name}${index}`}
          label={<>{item.name}</>}
          className={classNames(
            "tab",
            index === data.tabs.length - 1 ? "" : "tab-partition"
          )}
          {...a11yProps(index)}
        />
      ))
    );
  };

  const generateTabChildren = (tab, index, item) => {
    try {
      const FeatureComponent = item.component;
      return (
        <div className="">
          <FeatureComponent
            data={data}
            isTab={index !== data.tabs.length - 1}
            handleNextFeatureSelection={
              index === data.tabs.length - 1
                ? handleNextFeatureSelection
                : (event) => handleChange(event, index + 1)
            }
            {...componentData}
            rightSideFilterData={rightSideFilterData}
          />
        </div>
      );
    } catch (e) {}
  };
  const generateTabPanel = (selectedTabValue) => {
    return (
      data &&
      data.tabs.map((item, index) => (
        <TabPanel
          key={`${item.name}${index}`}
          value={selectedTabValue}
          index={index}
        >
          {generateTabChildren(item.value, index, item)}
        </TabPanel>
      ))
    );
  };

  return (
    <div className={classNames(classes.root, "tab-container")}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant={variant}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="simple tabs example"
        >
          {generateTabs()}
        </Tabs>
      </AppBar>
      {generateTabPanel(selectedTab)}
    </div>
  );
};

export default TabComponent;
