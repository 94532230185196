import Services from "./services";
import Axios from "./axios";

/**
 * accessManagementService
 */
export default class AccessManagementService extends Services {
  axiosInstance = new Axios(`${this.apiDomain}${this.endpointPrefix}/`);

  /**
   * Get report filter
   *
   * @returns {*}
   */

  getUsersList = (PageNumber, PageSize, LoggedInUser) => {
    return this.axiosInstance.get(
      `/Users?PageNumber=${PageNumber}&PageSize=${PageSize}&loggedInUser=${LoggedInUser}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  getValidUserInfo = (userid) => {
    return this.axiosInstance.get(
      `/Users/GetUserDetailsFromAzureAD?emailId=${userid}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  getUserDetails = (emailId) => {
    return this.axiosInstance.get(`/Users/GetUserDetails?emailId=${emailId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };
  postUser = (userData) => {
    return this.axiosInstance.post("/Users/PostUser", userData, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  deleteUser = (userID, loggedInUser) => {
    return this.axiosInstance.delete(
      `/Users/DeleteUser?emailId=${userID}&loggedInUser=${loggedInUser}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  putUser = (userDtls) => {
    return this.axiosInstance.put(`/Users/PutUser`, userDtls, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  filterUser = (params, pageNumber = 1, pageSize = 5) => {
    return this.axiosInstance.post(
      `/Users/GetFilterUsers?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  getCustomer = (emailId) => {
    return this.axiosInstance.get(`Users/GetCustomers?emailId=${emailId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  getRoles = (emailId) => {
    return this.axiosInstance.get(`Users/GetRoles?loggedInUser=${emailId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  getDetailsForCreateUser = (emailId) => {
    return this.axiosInstance.get(
      `Users/GetDetailsForCreateUser?loggedInUser=${emailId}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  lastLoggedIn = (emailId) => {
    return this.axiosInstance.get(`Users/LastLoggedIn?emailId=${emailId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    });
  };

  msalAuth = (data) => {
    return this.axiosInstance.post("/login", data);
  };
}
