import React from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

import "../inputSelect/inputSelect.scss";
import { Checkbox } from "@material-ui/core";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const InputSelectMulti = ({
  data,
  label,
  isMulti,
  isSearchable,
  onChange,
  isCheckInput,
  value,
  placeholder,
  selectAllOptions,
  menuIsOpen,
  onMenuOpen,
  onMenuClose,
  reportValue,
  sitesDetails,
  ...otherProps
}) => {
  const formatGroupLabel = (datainput) => (
    <div style={groupStyles}>
      <span>{datainput && datainput.label}</span>
      <span style={groupBadgeStyles}>{datainput && datainput.options && datainput.options.length}</span>
    </div>
  );

  const Option = (props) => {
    const {
      data: { label, value, isSelected },
    } = props;
    return (
      <components.Option {...props}>
        <div className={isSelected ? "selected-option" : "option"}>
          <Checkbox label="" value={value} checked={props.isSelected} />
          <span className="input-option-label">{label}</span>
        </div>
      </components.Option>
    );
  };

  const OptionSelectAll = (props) => {
    const {
      data: { label, value, isSelected },
    } = props;
    return (
      <components.Option {...props}>
        <div className={isSelected ? "selected-option" : "option"}>
          <Checkbox label="" value={value} checked={true} />
          <span className="input-option-label">{label}</span>
        </div>
      </components.Option>
    );
  };
  return (
    <>
      <Select
        isMulti
        components={selectAllOptions ? { Option: OptionSelectAll } : { Option }}
        options={data}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={onChange}
        controlShouldRenderValue={!isCheckInput}
        placeholder={placeholder}
        className={
          reportValue === "model" &&
          sitesDetails["yearmonth"] &&
          Object.keys(sitesDetails["yearmonth"]).length > 0
            ? "custom-select-container loading-indicator-model"
            : "custom-select-container"
        }
        classNamePrefix={isCheckInput ? "custom-select" : "input-select"}
        formatGroupLabel={formatGroupLabel}
        label={label}
        closeMenuOnSelect={!isMulti}
        isSearchable={isSearchable}
        value={value}
        menuIsOpen={menuIsOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...otherProps}
      />
    </>
  );
};

InputSelectMulti.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
};

InputSelectMulti.defaultProps = {
  placeholder: "",
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  option: [],
};

export default InputSelectMulti;
