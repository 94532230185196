const menuList = [
  {
    id: 1,
    allowedRole: [1, 2, 3],
    display: "Dashboards",
    url: "dashboard",
  },
  {
    id: 2,
    allowedRole: [1, 2, 3],
    display: "Reports",
    url: "reports",
  },
  {
    id: 3,
    allowedRole: [1, 2, 3],
    display: "Watchlist",
    url: "watchlist",
  },
  {
    id: 5,
    allowedRole: [1, 2],
    display: "Access Management",
    url: "user-access-management",
  },
];

export default menuList;
