import React, { useState, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({}));

const AccordionFilter = ({
  data,
  heading,
  onMenuSeletion,
  filterType,
  selectedFilterForPopover,
  isLoading,
}) => {
  const classes = useStyles();
  const [selectedData, setSelectedData] = useState([]);

  const handleClick = (value, filterTypeValue) => {
    const options = [...selectedData];
    let index = options.indexOf(value);
    index = options.findIndex((element) => {
      if (element.label === value.label) {
        return true;
      }
    });

    if (options.some((item) => item.label === value.label) === true) {
      options.splice(index, 1);
    } else {
      let arr = [];
      arr.push(value);
      options.push(value);
    }

    setSelectedData(options);

    let temp = [];
    for (let i = 0; i < options.length; i++) {
      temp.push(options[i][0]);
    }

    onMenuSeletion(options, filterTypeValue, value);
  };

  useEffect(() => {
    updateFilters(selectedFilterForPopover);
  }, [selectedFilterForPopover]);

  function updateFilters(value) {
    if (value !== null && value !== undefined && value !== {}) {
      let filters =
        value && filterType && value[filterType] ? value[filterType] : [];
      setSelectedData(filters);
    }
  }

  function getStyles(name, selectedData, theme) {
    if (selectedData.some((item) => item.label === name.label) === true) {
      return theme;
    } else {
      return "";
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Scrollbars
          className="custom_scroll"
          autoHeight
          autoHeightMin={0}
          autoHeightMax={200}
          thumbMinSize={30}
          universal={true}
        >
          {isLoading === true && <LinearProgress />}
          {data &&
            data.map((name, index) => {
              return (
                <Button
                  onClick={() => handleClick(name, filterType)}
                  key={`${index}-${name.customerId}-${name.siteId}-${name.value}`}
                  className={getStyles(name, selectedData, "current")}
                >
                  {name.label}
                </Button>
              );
            })}
        </Scrollbars>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionFilter;
