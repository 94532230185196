import React from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import AppRouter from "./routes/AppRouter";
import configureStore from "./redux/configureStore";
import { ContainerContextProvider } from "./utils/contextapi/ContainerContext";
import { msalConfig, loginRequest } from "./utils/msalconfig/msalconfig";
import { reactPlugin } from "./AppInsights";
import { AccessManagementService } from "./services";

const myMSALObj = new PublicClientApplication(msalConfig);

myMSALObj
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((err) => {
    console.error(err);
  });

const store = configureStore();

function handleResponse(resp) {
  if (resp !== null) {
    const authData = {
      email: resp.idTokenClaims.preferred_username,
      password: resp.idTokenClaims.preferred_username,
    };
    AccessManagementService.msalAuth(authData)
      .then((res) => {
        sessionStorage.setItem("accessToken", res.data.token.token);
        AccessManagementService.getUserDetails(
          resp.idTokenClaims.preferred_username
        )
          .then((response) => {
            if (response.data === "User_NotFound") {
              store.dispatch({
                type: "USERDETAIL",
                value: { ...resp, secondLevelAuthAccess: true },
              });
            } else {
              // lastLoggedIn status of user
              AccessManagementService.lastLoggedIn(
                resp.idTokenClaims.preferred_username
              ).then();
              store.dispatch({
                type: "USERDETAIL",
                value: {
                  ...resp,
                  usercustomerData: response.data,
                  secondLevelAuth: response.data,
                  secondLevelAuthAccess: false,
                },
              });
            }
          })
          .catch((err) => {
            store.dispatch({
              type: "USERDETAIL",
              value: { ...err, secondLevelAuthAccess: true },
            });
          });
      })
      .catch((error) => {
        store.dispatch({
          type: "USERDETAIL",
          value: { ...error, secondLevelAuthAccess: true },
        });
      });
  } else {
    myMSALObj.loginRedirect(loginRequest);
  }
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#932020",
      main: "#d32f2f",
      dark: "#db5858",
      contrastText: "#fff",
    },
    secondary: {
      light: "#121858",
      main: "#ef5350",
      dark: "#474f97",
      contrastText: "#000",
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  const handleOnIdle = () => {
    myMSALObj.logout();
  };
  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ContainerContextProvider>
            <AppRouter />
          </ContainerContextProvider>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
