import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { useSelector } from "react-redux";

import "./Header.scss";
import mainLogo from "./../../images/KOMATSU_INSITEMINING_LOGO.svg";
import Navbar from "../navbar/Navbar";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../utils/msalconfig/msalconfig";

const apiDefinedRolesMap = [
  { value: 1, label: "Super Admin User" },
  { value: 2, label: "Admin User" },
  { value: 3, label: "General User" },
];

const Header = () => {
  const history = useHistory();
  const myMSALObj = new PublicClientApplication(msalConfig);
  const userdetails = useSelector((state) => state.userdetails);

  const navigate2LoginPage = () => {
    history.push(`/`);
  };

  const [logoutbutton, Setlogoutbutton] = useState(false);
  const handleLogout = () => {
    myMSALObj.logout();
  };
  return (
    <div className="App">
      <div className="top-header">
        <header className="App-heade">
          <img
            src={mainLogo}
            alt="logo"
            width="300"
            onClick={navigate2LoginPage}
          />
        </header>

        <Navbar />
        <div className={logoutbutton ? "profile logoutButton" : "profile"}>
          <Button
            onClick={() => {
              Setlogoutbutton(!logoutbutton);
            }}
          >
            <AccountCircleOutlinedIcon />
            {userdetails ? userdetails.account.name : ""}
            <br />
            <span>
              {apiDefinedRolesMap &&
                apiDefinedRolesMap[
                  userdetails &&
                    userdetails.secondLevelAuth &&
                    userdetails.secondLevelAuth.roleId - 1
                ] &&
                apiDefinedRolesMap[
                  userdetails &&
                    userdetails.secondLevelAuth &&
                    userdetails.secondLevelAuth.roleId - 1
                ].label}
            </span>
            <i className="arrowtoggle"></i>
          </Button>
          <a href onClick={handleLogout}>
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
