const InspectionFilters = (
  reportid,
  getSingleSelect,
  getMultiSelect,
  getDatePicker
) => {
  return [
    {
      labelName: "Customer",
      isRequired: true,
      defalutValueData: reportid.inspectioncustomers,
      reportValue: "parentId",
      selectType: "single",
    },
    {
      labelName: "Site",
      isRequired: true,
      defalutValueData: reportid.sites,
      reportValue: "site",
      selectType: "single",
    },
    {
      labelName: "Model",
      isRequired: true,
      defalutValueData: reportid.models,
      reportValue: "model",
      selectType: "single",
    },
    {
      labelName: "Serial Number",
      isRequired: true,
      defalutValueData: reportid.serialNumber,
      reportValue: "serialNumber",
      selectType: "single",
    },
    {
      labelName: "INSPECTION DATE",
      isRequired: true,
      defalutValueData: reportid.inspectionDate,
      reportValue: "inspectionDate",
      selectType: "single",
    },
  ].map((res) => {
    if (res.selectType === "single") {
      return getSingleSelect(res);
    } else if (res.selectType === "multi") {
      return getMultiSelect(res);
    } else if (res.selectType === "datepicker") {
      return getDatePicker(res);
    }
    return false;
  });
};
export default InspectionFilters;
