import axios from "axios";

class Axios {
  constructor(
    baseUrl = "",
    accessToken = sessionStorage.getItem("accessToken"),
    _config = {}
  ) {
    if (typeof accessToken === "undefined") {
      console.warn("Access token is not available");
    }
    const { headers = {}, ...config } = _config;
    this.axios = axios.create({
      baseURL: baseUrl,
      headers: {
        ...(accessToken ? { Authorization: `${accessToken}` } : {}),
        ...headers,
      },
      ...config,
    });

    // add an interceptor for error handling
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return this.axios;
  }
}

export default Axios;
